import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  EventType } from "../../models/deviceEvents.model";
import { RootState } from "../../store/reducers";
import { getFilteredEventTypes } from "../../utilities/events/eventUtils";

export const useIsFilteredOnEventTypes = () => {
  const filterOn = useSelector(
    (state: RootState) => state.eventsReducer.filterIsOn
  );

  return filterOn;
};

export const useFilter = () => {

      const filter = useSelector(
        (state: RootState) => state.eventsReducer.filter
      );
      const maxEventsInFeed =  useSelector(
        (state: RootState) => state.eventsReducer.maxEventsInFeed
      );
    const filterOn = useIsFilteredOnEventTypes()

    const [filtered, setFiltered] = useState<EventType[]>([])

      useEffect(()=> {

        let newFiltered = getFilteredEventTypes({...filter} )as EventType[]
        
        setFiltered([...newFiltered])
    }, [filter])

return {filterOn,  filter: filtered, maxEventsInFeed}
    }

