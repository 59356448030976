import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { useGroups } from "../../hooks/api";
import { GroupRepresentation } from "../../models/users.model";

type onGroupsChosen = (groups: GroupRepresentation[]) => void;

type props = {
  onGroupsChosen: onGroupsChosen;
  error?: boolean;
  groups: GroupRepresentation[];
  width?: number | string;
};

const RolesComboBox = ({
  groups,
  onGroupsChosen,
  error,
  width = "inherit",
}: props) => {
  const apiData = useGroups();
  const [open, setOpen] = useState(false);

  const loading = useMemo(() => open && apiData.isLoading, [apiData, open]);
  const availableGroupIds = useMemo(
    () =>
      apiData.isLoading || apiData.error
        ? []
        : apiData.data.items,
    [apiData]
  );

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: GroupRepresentation[]
  ) => {
    onGroupsChosen(value);
  };


  return (
    <Autocomplete
      id="combo-box-roles"
      multiple
      filterSelectedOptions
      open={open}
      value={groups}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={availableGroupIds}
      getOptionLabel={(option) => option.name}
      sx={{ width: width }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="User Roles"
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default RolesComboBox;
