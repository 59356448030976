import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "../../models/users.model";
import { getUserById } from "../../utilities/axios/admin-api-calls/users-calls/usersCalls";
import { fetchStateType } from "../../models/types";

type useUserPathObjectType = () => {
  user: User | null;
  fetchstate: fetchStateType;
};

export const useUserPathObject: useUserPathObjectType = () => {
  const { userId } = useParams();

  
  const [user, setUser] = useState<User | null>(null);
  const [fetchstate, setFetchState] = useState<fetchStateType>("default")

  const fetchUser = useCallback(async () => {
    if (fetchstate !== "default" || !userId) return;
    
    setFetchState("loading");
    let user = await getUserById(userId);
    setUser(user);
    setFetchState("fetched")
  }, [fetchstate, userId]);

  useEffect(() => {
    if (fetchstate === "default" && userId && userId !== user?.id) {
      
      fetchUser();
    }
  }, [userId, fetchstate, user, fetchUser]);

  return {
    user,
    fetchstate
  };
};
