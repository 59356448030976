import { config } from "../config";
import { EventSpec } from "../config/config";

//#region interfaces
export interface DeviceEventCollection {
  deviceTransactions: TransactionEvent[];
  deviceEvents: BaseEventApiDto[];
  bluetoothEvents: BluetoothEvent[];
  summaryEvents: SummaryEvent[]
}

export interface ReceivedEvent {
  event: BaseMessage;
  eventType: BroadEventType;
  source: "broadcaster" | "database";
  color: string;
  name: string;
}
export interface BaseMessage {
  timestamp: string;
  deviceId: string;
  receivedOnServer: string;
  roomId: string;
  tenantIdentifier: string;
}

export interface BaseEvent extends BaseMessage {
  deviceTime: string;
  deviceTimeInMs: string;
}

export interface BaseEventApiDto extends BaseMessage {
  event: EventType | null;
  version: string;
  timestamp: string;
  deviceId: string;
  receivedOnServer: string;
  deviceTime: string;
  deviceTimeInMs: string;
}

export interface DeviceEvent extends BaseEvent {
  event: EventType;
}

export interface SummaryEvent extends DeviceEvent {
  revision: number;
  counterNumber: number;
  counterValue?: number;
  recordedTimestamp: Date;
}

export interface BluetoothEvent extends DeviceEvent {
  address: string;
  rssi: number;
}

export interface TransactionEvent extends DeviceEvent {
  userGroupId: string;
  shiftTypeId: string;
  sectionId: string;
}
//#endregion

//#region enums
export enum EventTypeEnum {
  BluetoothEvent = "Bluetooth",
  State = "State",
  HandSense = "HandSense",
  CapacitorVoltage = "CapacitorVoltage",
  PowerOn = "PowerOn",
  ChangeTime = "ChangeTime",
  MotorStuck = "MotorStuck",
  MotorRun = "MotorRun",
  MotorReturned = "MotorReturned",
  BatteryChanged = "BatteryChanged",
}
//#endregion

//#region types
export type EventType = string;
export type UnknownEvent = "UknownEvent";

export type bluetoothEventType = "Bluetooth";

export type transactionEventType = "Transaction";

export type summaryEventType = "Summary"

export type BroadEventType =
  | EventType
  | bluetoothEventType
  | transactionEventType
  | summaryEventType
  | UnknownEvent;

type generateDictType = (values: EventSpec[]) => { [key: string]: string };

//#endregion

//#region const
export const BluetoothEventType: bluetoothEventType = "Bluetooth";
export const TransactionEventType: transactionEventType = "Transaction";
export const SummaryEventType: summaryEventType = "Summary";
export const UNKNOWN_EVENT: UnknownEvent = "UknownEvent";
//#endregion

//#region helpers

const generateDict: generateDictType = (values: EventSpec[]) => {
  const enumObj: { [key: string]: string } = {};

  values.forEach((value) => {
    enumObj[value.name] = value.name;
  });

  return enumObj;
};

export const generateFilterableEventTypeEnum: generateDictType = (values) =>
  generateDict(values.filter((v) => v.filterable));

export const FilterableEventType = generateFilterableEventTypeEnum(
  config.eventLogSpecs.events
);

export const generateQueryableEventTypeEnum: generateDictType = (values) =>
  generateDict(values.filter((v) => v.filterable && v.baseEvent));

export const QueryableEventTypeNumbers = generateQueryableEventTypeEnum(
  config.eventLogSpecs.events
);

export const specifiedEventList: BroadEventType[] =
  config.eventLogSpecs.events.map((spec) => spec.name as BroadEventType);

export const filterableEventList: BroadEventType[] = specifiedEventList.filter(
  (ev) => ev in FilterableEventType
);

export const eventList: BroadEventType[] = [...specifiedEventList];
//#endregion
