import { useNavigate, useParams } from "react-router-dom";
import Title from "../../components/title/Title";
import {
  useCompleteInstallation,
  useDeviceInstallationsForDevice,
  useUpdateDeviceInstaller,
  useUpdateDeviceStatus,
} from "../../hooks/device-installations/deviceInstallationHooks";
import {
  CompleteInstallDto,
  DeviceInstallationStatus,
  isReadyOrInProgress,
} from "../../models";
import { useTenant } from "../../hooks/tenant";
import InstallationStepper from "../../components/installations/InstallationStepper";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { pathToAdminDevice, pathToTenantInstall } from "../../utilities/paths";
import Linkage from "../../components/link/Linkage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const InstallDeviceView = () => {
  const tenant = useTenant();
  const { serialNumber } = useParams();
  const navigate = useNavigate();
  const installPath = useMemo(
    () => pathToTenantInstall(tenant?.identifier ?? ""),
    [tenant?.identifier]
  );

  const { mostRecent } = useDeviceInstallationsForDevice(
    serialNumber ?? "unknown",
    [DeviceInstallationStatus.READY, DeviceInstallationStatus.IN_PROGRESS],
    [tenant?.identifier ?? "Unknown"]
  );

  const { update: updateDeviceInstallationStatus } =
    useUpdateDeviceStatus(mostRecent);
  const { update: updateDeviceInstaller } =
    useUpdateDeviceInstaller(mostRecent);
  const { saveStatus: completeInstallState, complete: completeInstall } =
    useCompleteInstallation(mostRecent);

  useEffect(() => {
    if (mostRecent?.installationStatus === DeviceInstallationStatus.READY) {
      updateDeviceInstallationStatus(DeviceInstallationStatus.IN_PROGRESS);
    }
  }, [mostRecent, updateDeviceInstallationStatus]);

  useEffect(() => {
    if (mostRecent) {
      if (isReadyOrInProgress(mostRecent.installationStatus)) {
        updateDeviceInstaller();
      }
    }
  }, [mostRecent, updateDeviceInstaller]);

  if (!serialNumber) return <>Error no serial number</>;

  const handleConfirm = (completeInstallDto: CompleteInstallDto) => {
    completeInstall(completeInstallDto);
  };

  const navigateToInstallations = () => navigate(installPath);

  return (
    <>
      <Linkage to={installPath}>
        <Typography>
          <ArrowBackIcon fontSize="small" /> Installations
        </Typography>
      </Linkage>
      <Title>Install {serialNumber}</Title>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {mostRecent && completeInstallState === "default" && (
          <InstallationStepper
            deviceInstallation={mostRecent}
            onConfirm={handleConfirm}
          />
        )}
      </Grid>

      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        spacing={3}
        marginTop={2}
      >
        {completeInstallState === "loading" && (
          <Grid item justifySelf={"center"}>
            <CircularProgress />
          </Grid>
        )}

        {completeInstallState === "success" && (
          <>
            <Grid item>
              <Alert severity="success">
                Successfully installed {serialNumber}
              </Alert>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={navigateToInstallations}>
                Back to installations
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => navigate(pathToAdminDevice(mostRecent?.serialNumber ?? ""))}>
                Go to device
              </Button>
            </Grid>
          </>
        )}

        {completeInstallState === "error" && (
          <Grid item justifySelf={"center"}>
            <Alert severity="error">Install failed {serialNumber}</Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InstallDeviceView;
