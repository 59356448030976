import {
  Box,
  Button,
  ButtonProps,
  Grid,
  List,
  ListItem,
  Paper,
  styled,
} from "@mui/material";
import { ProvisionResults } from "../../models";
import Linkage from "../../components/link/Linkage";
import { pathToAdminDevice } from "../../utilities/paths";
import { useCsvDownloading } from "../../hooks/csv-hooks/CsvHooks";
import { useEffect, useMemo } from "react";
import { useSessionStorageProvisionResult } from "../../hooks/admin-device/provisioningHook";
import { useIsMobile } from "../../hooks/media-hooks/mediaHooks";

const AdminDeviceProvisionResults = () => {
  const mobile = useIsMobile();
  const { getProvisionResult } = useSessionStorageProvisionResult();
  const provisionResult: ProvisionResults | null = useMemo(() => {
    let pr = getProvisionResult();

    return pr;
  }, [getProvisionResult]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ""; // Standard way to show confirmation dialog in most browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { handleDownload } = useCsvDownloading(
    {
      columnNames: ["serial_number"],
      rows: provisionResult
        ? provisionResult.serialNumbers.map((ser) => [ser])
        : [[]],
    },
    provisionResult ? getFileName(provisionResult) : ""
  );

  const onCopyClicked = () => {
    if (provisionResult)
      navigator.clipboard.writeText(provisionResult.serialNumbers.join(", "));
  };

  if (!provisionResult) {
    return <>No results found in session storage.</>;
  }

  const ProvisionResultsButton = styled(Button)<ButtonProps>(({ theme }) =>
    mobile
      ? {
          padding: "1rem",
          marginTop: "2rem",
          // marginBottom: "2rem"
        }
      : {
        padding: "1rem",
        marginTop: "2rem",
        marginRight: "2rem",
        width: 200
      }
  );

  return (
    <Grid container flexDirection={"column"}>
      <Grid item>
        <Paper sx={{ maxHeight: 400, overflow: "scroll" }}>
          <Box sx={{ textAlign: "center", paddingTop: 2 }}>
            <h3>
              Created{" "}
              <b>
                {provisionResult.successfullyProvisioned} /{" "}
                {provisionResult.devicesRequested}
              </b>
            </h3>
          </Box>
          <Box sx={{ overflow: "scroll", maxHeight: "90%" }}>
            <List>
              {provisionResult.serialNumbers.map((serialNumber) => (
                <ListItem key={serialNumber}>
                  <Linkage to={pathToAdminDevice(serialNumber)}>
                    {serialNumber}
                  </Linkage>
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
        {provisionResult.successfullyProvisioned > 0 && (
<Grid container flexDirection={mobile ? "column": "row"}>
            <ProvisionResultsButton variant={"outlined"}  onClick={onCopyClicked}>
              Copy to clipboard
            </ProvisionResultsButton>
            <ProvisionResultsButton variant={"outlined"} onClick={handleDownload}>
              Download as CSV
            </ProvisionResultsButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminDeviceProvisionResults;

function getFileName(_: ProvisionResults) {
  let date = new Date();
  let year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
  const day = date.getDate().toString().padStart(2, "0");

  return `provision_serial_numbers_${year}${month}${day}`;
}
