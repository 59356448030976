import { AnyAction, Reducer } from 'redux'
import { Tenant } from '../../models'
import { fetchStateType } from '../../models/types'
import { SET_TENANT, SET_TENANT_FETCH_STATE } from '../actions/actionTypes'


type eventStateType = {
    tenant: null | Tenant
    fetchState: fetchStateType
}

const initialState:eventStateType ={
    tenant: null,
    fetchState: "default"
}


const tenantReducer: Reducer<eventStateType, AnyAction> =  (state = initialState, action) => {
    switch(action.type){
        case SET_TENANT:
            return {
                ...state,
                tenant: action.payload,
                fetchState: "fetched"
            }
        case SET_TENANT_FETCH_STATE:
            return{
                ...state,
                fetchState: action.payload
            }
        default:
            return state;
        }
    }

export default tenantReducer