import { Tenant } from "../../models";


const TENANT_STORAGE_KEY = 'TENANT_STORAGE_KEY' 
export const setTenantInStorage = (tenant:Tenant) =>{
    let jsonTenant = JSON.stringify(tenant)
    sessionStorage.setItem(TENANT_STORAGE_KEY, jsonTenant)
} 


export const getTenantFromStorage = () => {
    let storedTenant = sessionStorage.getItem(TENANT_STORAGE_KEY)
    if(!storedTenant) return null

    return JSON.parse(storedTenant) as Tenant
}