import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useTenant } from "../../hooks/tenant";
import { Button } from "../buttons/Button";
import { saveStateType } from "../../models/types";
import { CreatePremise } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import { CreatePremiseDto } from "../../models/locations.model";

type props = { sectionId: string; onAdded: () => void };

const AddPremiseButton = ({ sectionId, onAdded }: props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [premiseName, setPremiseName] = useState("");
  const [saveState, setSaveState] = useState<saveStateType>("default");
  const tenant = useTenant();

  const toggleDialogState = useCallback(() => {
    setDialogOpen(!dialogOpen);
    setPremiseName("");
    setSaveState("default");
  }, [dialogOpen]);

  const addPremise = useCallback(async () => {
    if (saveState === "loading") return;

    setSaveState("loading");
    var success = await CreatePremise({
      name: premiseName,
      sectionId: sectionId,
    } as CreatePremiseDto);

    if (success) {
      setSaveState("success");
      onAdded();
      toggleDialogState();
    } else setSaveState("error");
  }, [saveState, sectionId, premiseName, onAdded, toggleDialogState]);

  const handleAddPremiseClicked = () => {
    if (saveState !== "loading") addPremise();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPremiseName(event.target.value);
  };

  return (
    <>
      <Button onClick={toggleDialogState}>Add premise</Button>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <Grid
            container
            flexDirection={"column"}
            width={400}
            height={400}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Grid item>
              <h3>Add  premises  to {tenant?.name}</h3>
            </Grid>
            <Grid item>
              <TextField
                id="outlined-name"
                label="Premise name"
                fullWidth
                variant="outlined"
                value={premiseName}
                onChange={handleNameChange}
              />
            </Grid>

            {saveState === "error" && (
              <Alert severity="error">Unable to create premise</Alert>
            )}

            {saveState === "success" && (
              <Alert severity="success">
                Premises with name <b>{premiseName}</b> created
              </Alert>
            )}
            <Grid item>
              {saveState === "loading" && <CircularProgress />}

              {(saveState === "default" || saveState === "error") && (
                <Button onClick={handleAddPremiseClicked}>Save</Button>
              )}

              {saveState === "success" && (
                <Button onClick={toggleDialogState}>Close</Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPremiseButton;
