import { Divider, Stepper, Step, StepLabel } from "@mui/material";
import React from "react";
import { steps } from "../../hooks/admin-device/assignAdminDeviceToTenantHook";
import { assignmentState } from "../../hooks/admin-device/assignmentStateTypes";


type formStepperProps = {
  assignmentState: assignmentState;
};

const FormStepper = ({ assignmentState }: formStepperProps) => {
  return (
    <>
      <Divider sx={{ marginTop: 5, marginBottom: 2 }} />
      <Stepper
        activeStep={assignmentState.step}
        sx={{ marginBottom: 2 }}
        color={"primary"}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={index} {...stepProps} color={'primary'}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

export default FormStepper;
