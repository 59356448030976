import { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { EventType, ReceivedEvent } from "../../models/deviceEvents.model";
import { RootState } from "../../store/reducers";
import { arraySizeMaintainer } from "../../utilities/events/api";
import { useSortedLatestEventsByDevice } from "../sortedApiData";
import { useFilter } from "./filterHooks";
import { signalRFunctions } from "../../config";
import useSignalR, { useTenantGroup } from "../signalR-hooks/signalRHook";
import { parseSignalREvent } from "../../utilities/events/eventUtils";

interface MostRecentDeviceEventsProps {
  deviceId: string;
}

export const useMostRecentDeviceEvents = ({
  deviceId,
}: MostRecentDeviceEventsProps) => {
  const { events: apiEvents, loading } =
    useSortedLatestEventsByDevice(deviceId);

  const { filterOn, filter } = useFilter();

  const reducerEvents = useSelector(
    (state: RootState) => state.eventsReducer.mostRecentEvents
  );

  const maxEventsInFeed = useSelector(
    (state: RootState) => state.eventsReducer.maxEventsInFeed
  );

  const eventsFetchState = useSelector(
    (state: RootState) => state.eventsReducer.eventFetchState
  );

  const [recentData, setRecentData] = useState<ReceivedEvent[]>([]);
  const [initiated, setInitiated] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());

  const mostRecentEvent: ReceivedEvent | null = useMemo(() => {
    if (!initiated || eventsFetchState === "loading") return null;

    let receivedEvent = reducerEvents.find(
      (receivedEvent) => receivedEvent.event.deviceId === deviceId
    );

    return receivedEvent ?? null;
  }, [reducerEvents, initiated, deviceId, eventsFetchState]);

  useEffect(() => {
    if (!loading) {
      let mostRecEvents = apiEvents;
      mostRecEvents = arraySizeMaintainer(mostRecEvents, maxEventsInFeed);
      setRecentData(mostRecEvents);
      setInitiated(true);
    }
  }, [apiEvents, loading, maxEventsInFeed, filter, filterOn]);

  useEffect(() => {
    if (
      mostRecentEvent &&
      mostRecentEvent?.event.deviceId === deviceId &&
      initiated &&
      recentData.every(
        (s) =>
          s.event.timestamp !== mostRecentEvent.event.timestamp ||
          s.eventType !== mostRecentEvent.eventType
      )
    ) {
      let tempMostRecent = recentData;
      tempMostRecent.unshift(mostRecentEvent);
      setRecentData(arraySizeMaintainer(tempMostRecent, maxEventsInFeed));
      setLastUpdated(new Date());
    }
  }, [
    mostRecentEvent,
    deviceId,
    recentData,
    initiated,
    maxEventsInFeed,
    filterOn,
    filter,
    apiEvents,
  ]);

  return {
    recentEvents: recentData,
    mostRecentEvent,
    lastUpdated,
    loading,
  };
};

export const useListenForEvent = (
  tenantIdentifier: string,
  serialnumber: string,
  eventType: EventType
) => {
  const [mostRecent, setmostRecent] = useState<ReceivedEvent | null>(null);
  const [count, setCount] = useState(0);
  const connection = useSignalR();

  const { forceCurrentGroup, currentGroup, undoForcedGroup } = useTenantGroup();
  const [listening, setListening] = useState(true);

  const cleanUp = useCallback(() => {
    setListening(false);
    undoForcedGroup()
  }, [undoForcedGroup]);

  useEffect(() => {
    if (listening)
    forceCurrentGroup(tenantIdentifier)
  }, [tenantIdentifier, listening, forceCurrentGroup]);

  useEffect(() => {
    if (connection && currentGroup) {
      
      connection.on(
        signalRFunctions.device.ReceiveDeviceEvent,
        (message: any) => {
          let receivedEvent = parseSignalREvent(
            signalRFunctions.device.ReceiveDeviceEvent,
            message
          );
          if (receivedEvent.event.deviceId === serialnumber && receivedEvent.eventType === eventType) {
            setmostRecent(receivedEvent);
            setCount((count) => count + 1);
          }
        }
      );
    }
  }, [connection, currentGroup, serialnumber, eventType]);

  return {
    mostRecent,
    count,
    cleanUp,
  };
};
