import { Button, Grid, Table, TableCell, TableRow } from "@mui/material";
import { DetailedDevice } from "../../models/detailedDevice.model";
import ChangePowerSource from "../power-source/ChangePowerSource";
import { useUpdateBattery } from "../../hooks/admin-device/batteryHook";
import { fluidOrBatteryLevelText } from "../../utilities/device";

type props = {
  device: DetailedDevice;

  reloadDetails: () => void;
};

const PowerSupplyStep = ({ device, reloadDetails }: props) => {
  const { onBatteryChangeClick } = useUpdateBattery(device, reloadDetails);

  return (
    <Grid container>
      <Table>
        <TableRow>
          <TableCell>Power source</TableCell>
          <TableCell>
            {device.status.runsOnBattery ? "Battery" : "Corded"}
          </TableCell>
        </TableRow>
        {device.status.runsOnBattery && (
          <TableRow>
            <TableCell>Battery left</TableCell>
            <TableCell>
              {device.status.battery?.value && (
                <>{fluidOrBatteryLevelText(device.status.battery.value ?? -1)}%</>
              )}
            </TableCell>
          </TableRow>
        )}
        {device.status.runsOnBattery && (
          <TableRow>
            <TableCell colSpan={2}>
                <Grid container justifyContent={"center"}>
              <Button variant="outlined" onClick={onBatteryChangeClick}>
                Update battery
              </Button>
              </Grid>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell colSpan={2}>
            <ChangePowerSource device={device} onChange={reloadDetails} />
          </TableCell>
        </TableRow>
      </Table>
    </Grid>
  );
};

export default PowerSupplyStep;
