import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIsServiceTenant, useTenant } from "../../hooks/tenant";
import { tenantRoutes, adminRoutes, RouteObj } from "../../routing/routes";
import CopyAccessTokenButton from "../buttons/CopyAccessTokenButton";
import { useCallback } from "react";

type props = { toggleDrawer: () => void };

const RoutesList = ({ toggleDrawer }: props) => {
  const tenant = useTenant();
  const isServiceTenant = useIsServiceTenant()
  const navigate = useNavigate();

  const routeNames = useCallback((tenantRoute: RouteObj) =>{
    if (tenantRoute.name !== "Installations" || !isServiceTenant.isServiceTenant) return tenantRoute.name

    return "Plan installations"
  } , [isServiceTenant])

  const handleLinkClicked = (path: string) => {
    navigate(path);
  };

  const handleTenantLinkClicked = (path: string) => {
    handleLinkClicked(`/${tenant?.identifier}${path}`);
  };

  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      {tenant && (
        <>
  
  <DrawerSubtitle>{tenant.name}</DrawerSubtitle>
          <List>
            {tenantRoutes.map((tenantRoute) => (
              <ListItem
                button
                key={tenantRoute.path}
                onClick={() => handleTenantLinkClicked(tenantRoute.path)}
              >
                <ListItemText primary={routeNames(tenantRoute)} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </>
      )}

      <List>
      <DrawerSubtitle>General</DrawerSubtitle>

        {adminRoutes.map((adminRoute) => (
          <ListItem
            button
            key={adminRoute.path}
            onClick={() => handleLinkClicked(adminRoute.path)}
          >
            <ListItemText primary={adminRoute.name} />
          </ListItem>
        ))}

        {process.env.NODE_ENV === "development" &&
        <ListItem>
          <CopyAccessTokenButton/>
          </ListItem>}
      </List>
    </Box>
  );
};
export default RoutesList;

type drawerSubtitleProps = { children: React.ReactNode };

const DrawerSubtitle = ({ children }: drawerSubtitleProps) => {
  return <Typography fontWeight={700} sx={{paddingLeft:"5px", marginTop:2}}>{children}</Typography>;
};
