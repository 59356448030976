import { Grid, Pagination } from "@mui/material";
import ItemList, { ItemComponentProps } from "./ItemList";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";

type props = {
  items: Item[];
  handleItemChosen: (item: any) => void;
  constructLabel: (item: Item) => string;
  selected: Item | null;
  handlePaginatedListSelection: (item: Item) => void;
  disabledItemId: string | null;
  showPaginationBar: boolean;
  currentPage: number;
  totalNumberOfPages: number;
  itemComponent?: ((props: ItemComponentProps) => JSX.Element) | null;
  headerComponent?: (() => JSX.Element) | null;

  handlePaginationChange: (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => void;

  useTable?: boolean

  isDisabled?: (item:Item) => boolean
  disableDivideLastItem?: boolean

};

const PaginatedList = ({
  items,
  handleItemChosen,
  constructLabel,
  selected,
  handlePaginatedListSelection,
  disabledItemId,
  showPaginationBar,
  currentPage,
  totalNumberOfPages,
  handlePaginationChange,
  itemComponent = null,
  headerComponent = null,
  useTable = false,
  disableDivideLastItem = false,
  isDisabled = (item:Item) => false
}: props) => {
  return (
    <>
      <Grid item maxWidth={"100%"} sx={{ flexGrow: 1 }}>
        <ItemList
          key="itemlist"
          onItemClicked={handleItemChosen}
          items={items.map((item) => ({
            ...item,
            label: constructLabel(item),
            id: item.id,
          }))}
          selected={selected}
          onItemSelected={handlePaginatedListSelection}
          disabledItemId={disabledItemId}
          itemComponent={itemComponent}
          headerComponent={headerComponent}
          isTableRow={useTable}
          isDisabled={isDisabled}
          disableDivideLastItem={disableDivideLastItem}
        />
      </Grid>
      <Grid
        item
        container
        minWidth={"100%"}
        justifyContent={"center"}
        sx={{ flexGrow: 0 }}
      >
        {showPaginationBar && (
          <Pagination
            variant="outlined"
            count={totalNumberOfPages}
            page={currentPage}
            onChange={handlePaginationChange}
          />
        )}
      </Grid>
    </>
  );
};

export default PaginatedList;
