import {  ButtonBase, Grid, IconButton } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { Logo } from "../logo/Logo";

type props = {
  onClick: () => void;
  color?: 'white' | 'black';
  Icon: SvgIconComponent;
  
};

const DrawerLogo = ({ onClick, color,  Icon }: props) => {
  return (
    <Grid container
    alignContent={"center"}
    sx={{
        width:250,
        height: 64,
        paddingLeft: "24px"
    }}
    >
        <Grid item alignSelf={"center"}>
        <ButtonBase onClick={onClick}>

      <Logo color={color} size="small" />
      </ButtonBase>
      </Grid>
      <Grid item>
      <IconButton onClick={onClick}>
        <Icon sx={{color: color}}/>
      </IconButton>
      </Grid>
    </Grid>
  );
};

export default DrawerLogo;
