import { Outlet } from "react-router-dom";
import { AppBar, Box, Grid, Tooltip, Typography } from "@mui/material";
import muiTheme from "../../styles/muiTheme";
import {
  useFindAndPersistTenant,
  useTenant,
  useTenantLinks,
} from "../../hooks/tenant";
import { useState } from "react";
import MenuDrawer from "../menu-drawer/MenuDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerLogo from "../menu-drawer/DrawerLogo";
import Linkage from "../link/Linkage";
import { useIsMobile } from "../../hooks/media-hooks/mediaHooks";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useMaintenance } from "../../hooks/maintenance-hooks/maintenanceHook";
interface Props {
  color: "primary" | "secondary";
}

export const Header = ({ color }: Props) => {
  const tenant = useTenant();
  const {fetchState} = useFindAndPersistTenant();
  const { currentDashboardLink } = useTenantLinks();
  const mobile = useIsMobile();
  const maintenanceState = useMaintenance();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  return (
    <>
      <Box flex={"grow"}>
        <AppBar
          position="static"
          sx={
            maintenanceState.maintenanceModeActive
              ? {
                  display: "flex",
                  flexDirection: mobile ? "column" : "row",
                  backgroundColor: muiTheme.palette.primary.dark,
                }
              : {
                  display: "flex",
                  flexDirection: mobile ? "column" : "row",
                }
          }
        >
          <Grid container>
            <Grid item>
              <DrawerLogo
                onClick={toggleDrawer}
                color={"white"}
                Icon={MenuIcon}
              />
            </Grid>
            {tenant && (
              <Grid item alignSelf={"center"}>
                <Grid container spacing={2}>
                  <Grid item alignSelf={"end"}>
                    <Tooltip
                      title={`Maintenance mode active at ${tenant.name}`}
                    >
                      <EngineeringIcon
                        fontSize="small"
                        sx={{
                          visibility:
                            maintenanceState.maintenanceActiveOnTenant
                              ? "inherit"
                              : "hidden",
                        }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Linkage to={currentDashboardLink}>
                      <Typography
                        sx={{
                          color: "primary.contrastText",
                          "&:hover": { fontWeight: 250 },
                        }}
                        variant="h1"
                      >
                        {tenant.name}
                      </Typography>
                    </Linkage>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AppBar>
      </Box>
      <MenuDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
      {fetchState !== "loading" && <Outlet />}
    </>
  );
};
