import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTenant } from "../../hooks/tenant";
import { Button } from "../buttons/Button";
import { saveStateType } from "../../models/types";
import { CreateFloor } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import { useIdsFromPath } from "../../hooks/location-hooks/locationHelperHooks";

type props = { onAdd: () => void };
const AddFloorButton = ({ onAdd }: props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [FloorName, setFloorName] = useState("");
  const [floorNumber, setFloorNumber] = useState(1);
  const [floorNumberRaw, setFloorNumberRaw] = useState("1");

  const floorNumberError = useMemo(() => {
    try {
      let parsed = parseInt(floorNumberRaw, 10);

      if (isNaN(parsed)) return true;

      setFloorNumber(parsed);
      return false;
    } catch {
      return true;
    }
  }, [floorNumberRaw]);

  const [saveState, setSaveState] = useState<saveStateType>("default");
  const tenant = useTenant();
  const { sectionId, premiseId } = useIdsFromPath();

  const toggleDialogState = useCallback(() => {
    setDialogOpen(!dialogOpen);
    setFloorName("");
    if (saveState === "success") onAdd();
    setSaveState("default");
  }, [saveState, dialogOpen, onAdd]);

  const addFloor = useCallback(async () => {
    if (saveState === "loading") return;

    setSaveState("loading");
    var success = await CreateFloor({
      name: FloorName,
      sectionId: sectionId ?? "",
      number: floorNumber,
      premiseId: premiseId ?? "",
    });

    if (success) {
      setSaveState("success");
      onAdd();
      toggleDialogState();
    } else setSaveState("error");
  }, [
    saveState,
    sectionId,
    premiseId,
    onAdd,
    FloorName,
    floorNumber,
    toggleDialogState,
  ]);

  const handleAddFloorClicked = () => {
    if (saveState !== "loading") addFloor();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFloorName(event.target.value);
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFloorNumberRaw(event.target.value);
  };

  return (
    <>
      <Button onClick={toggleDialogState}>Add Floor</Button>

      <Dialog open={dialogOpen} onClose={toggleDialogState}>
        <DialogContent>
          {floorNumberError.toString()}
          <Grid
            container
            flexDirection={"column"}
            width={400}
            height={400}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Grid item>
              <h3>Add a Floor to {tenant?.name}</h3>
            </Grid>
            <Grid item>
              <TextField
                id="outlined-name"
                label="Floor name"
                fullWidth
                variant="outlined"
                value={FloorName}
                onChange={handleNameChange}
              />
            </Grid>
            <Grid item>
              <TextField
                id="outlined-name"
                label="Floor number"
                fullWidth
                variant="outlined"
                error={floorNumberError}
                value={floorNumberRaw}
                onChange={handleNumberChange}
              />
            </Grid>

            {saveState === "error" && (
              <Alert severity="error">Unable to create Floor</Alert>
            )}

            {saveState === "success" && (
              <Alert severity="success">
                Floor with name <b>{FloorName}</b> created
              </Alert>
            )}
            <Grid item>
              {saveState === "loading" && <CircularProgress />}

              {(saveState === "default" || saveState === "error") && (
                <Button onClick={handleAddFloorClicked}>Save</Button>
              )}

              {saveState === "success" && (
                <Button onClick={toggleDialogState}>Close</Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddFloorButton;
