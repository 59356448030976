import { Grid, Typography } from "@mui/material";
import { RoomModel } from "../../models/locations.model";
import RoomsDisplay from "../rooms-display/RoomsDisplay";

type props = {
  tenantName: string;
  onRoomChosen: (room: RoomModel | null) => void;
};

const AddDeviceContent = ({
  tenantName,
  onRoomChosen,
}: props) => {
  return (
    <Grid
      container
      flexDirection={"column"}
      alignContent={"center"}
    >
      <Grid item marginBottom={2}>
          <Typography>Select a room in <b>{tenantName}</b> </Typography>
      </Grid>
      <Grid item container>
        <RoomsDisplay handleRoomChosen={onRoomChosen} />
      </Grid>
    </Grid>
  );
};

export default AddDeviceContent;
