import { ErrorOutline } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import Linkage from "../link/Linkage";
import Title from "../title/Title";

type props = {
  tenantId: string;
};

const TenantNotFound = ({ tenantId }: props) => {
  return (
    <>
      <Title>Tenant not found</Title>
      <Grid
        container
        justifyContent={"center"}
        textAlign="center"
        flexDirection="column"
        marginTop={5}
      >
        <Typography>
          <ErrorOutline />
        </Typography>
        <Typography textAlign={"center"}>
          Unable to find tenant with id <br />
          {tenantId}
        </Typography>
        <Grid item marginTop={5}>
          <Linkage to={`/lander`}>Go to list of all tenants</Linkage>
        </Grid>
      </Grid>
    </>
  );
};

export default TenantNotFound;
