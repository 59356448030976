import moment from "moment";

export function arraySetEquality(
  first: any[] | null,
  second: any[] | null | undefined,
  key?: string
) {
  if (first === second) return true;
  if (first == null || second == null) return false;
  if (first.length !== second.length) return false;
  if(key === undefined) return first.every((f) => second.includes(f));

  return first.every((f) => second.map(s => s[key]).includes(f[key]))
}

export const dateTimeToString = (date: Date | string) => {
  return (
    new Date(date).toLocaleTimeString("no-nb") +
    " " +
    new Date(date).toLocaleDateString("no-nb")
  );
};

export const friendlyDateTimeToString = (date: Date | string) => {
  if (new Date(date).getUTCFullYear() === new Date().getUTCFullYear()) {
    // current year

    return new Date(date).toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions);
  }
  let options = { year: "2-digit", month: "short", day: "numeric" };
  return new Date(date).toLocaleDateString(
    undefined,
    options as Intl.DateTimeFormatOptions
  );
};

export const detailedFriendlyDateTimeToString = (date: Date | string) => {
  let options = { year: "numeric", month: "long", day: "numeric" };
  let dateString = new Date(date).toLocaleDateString(
    undefined,
    options as Intl.DateTimeFormatOptions
  );

  return dateString + " at " + new Date(date).toLocaleTimeString(
    undefined,
    {
      timeStyle: "short"
    } as Intl.DateTimeFormatOptions
  );
};


export const isLessThanAMonthAway = (date: string | Date) => {
  let shiftedDate = new Date(date)
  shiftedDate.setMonth((new Date(date).getMonth() - 1) % 12)
  return shiftedDate.getTime() <= new Date().getTime();
}

export const isPast = (date: string | Date) => {
  
  return new Date(date).getTime() < new Date().getTime()
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);


  // call this function, passing-in your date
export const dateToFromNowDaily = ( myDate: Date ) => {

  // get from-now for this date
  var fromNow = moment( myDate ).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment( myDate ).calendar( null, {
      // when the date is closer, specify custom values
      lastWeek: '[last] dddd',
      lastDay:  '[yesterday]',
      sameDay:  '[today]',
      nextDay:  '[tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality             
      sameElse: function () {
          return "[" + fromNow + "]";
      }
  });
}



export function formatPercentage(percentage: number, decimalPlaces: number = 0): string {
  // Convert number to a percentage string with specified decimal places
  let percentageString: string = percentage.toFixed(decimalPlaces);

  // Calculate the total length of the formatted string (including decimal places and percent sign)
  const totalLength: number = percentageString.length + 1 + decimalPlaces;

  // Pad the string with spaces to ensure it's centered
  percentageString = percentageString.padStart(totalLength - percentageString.length + percentageString.length / 2);

  // Add the '%' sign back to the padded string
  return percentageString;
}



export function toGitShortHash(firmwareVersion: string){
  return parseInt(firmwareVersion, 10).toString(16).padStart(7, '0')
}