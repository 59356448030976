import {
  FloorModel,
  PremiseModel,
  SectionModel,
  RoomModel,
} from "../../models/locations.model";
import {
  SET_FLOOR_LOCATION_OBJECT,
  SET_PREMISE_LOCATION_OBJECT,
  SET_ROOM_LOCATION_OBJECT,
  SET_SECTION_LOCATION_OBJECT,
} from "./actionTypes";

export const setRoom = (roomLocationObj: RoomModel | null) => {
  return {
    type: SET_ROOM_LOCATION_OBJECT,
    payload: roomLocationObj,
  };
};

export const setPremise = (premiseLocationObj: PremiseModel | null) => {
  return {
    type: SET_PREMISE_LOCATION_OBJECT,
    payload: premiseLocationObj,
  };
};

export const setSection = (sectionLocationObj: SectionModel | null) => {
  return {
    type: SET_SECTION_LOCATION_OBJECT,
    payload: sectionLocationObj,
  };
};

export const setFloor = (floorLocationObj: FloorModel | null) => {
  return {
    type: SET_FLOOR_LOCATION_OBJECT,
    payload: floorLocationObj,
  };
};
