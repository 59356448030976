import Title from "../../components/title/Title";
import { Container, Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSessionStorageProvisionResult } from "../../hooks/admin-device/provisioningHook";
import Linkage from "../../components/link/Linkage";

const AdminDeviceProvisioning = () => {
  const navigate = useNavigate();
  const { getProvisionResult } = useSessionStorageProvisionResult();
  const pr = useMemo(() => getProvisionResult(), [getProvisionResult]);

  const location = useLocation();

  const atForm = useMemo(() => location.pathname.includes("form"), [location]);
  const atResults = useMemo(
    () => location.pathname.includes("results"),
    [location]
  );

  useEffect(() => {
    if (!atForm && !atResults) navigate("form", { replace: true });
  });

  return (
    <>
      <Title>Create new devices</Title>
      <Container>
        <Grid item marginBottom={2}>
          {!atForm && (
            <Linkage to={"/devices/provision/form"}>Go to form</Linkage>
          )}
        </Grid>
        <Grid item>
          {!atResults && pr && (
            <Linkage to={"/devices/provision/results"}>
              View previous result
            </Linkage>
          )}
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Outlet />
      </Container>
    </>
  );
};
export default AdminDeviceProvisioning;
