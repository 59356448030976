import { HTMLProps } from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

interface Props extends HTMLProps<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'warning';
  type?: 'button' | 'submit' | 'reset' | undefined;
  fullWidth?: boolean
}

export const Button: React.FC<Props> = ({ variant='primary', type, className, children, fullWidth, ...rest }) => {
  return (
    <button {...rest} className={cn(styles[variant], styles[fullWidth ? "fullWidth" : ""], className)} type={type ? type : 'button'}>
      {children}
    </button>
  );
};
