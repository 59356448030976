import axios  from "axios";
import { Tenant } from "../../models";

export const X_TENANT_IDENTIFIER = "x-tenant-identifier";
export const AUTHORIZATION = "AUTHORIZATION";

export const setAuthHeaderInAxios = (accessToken: string) => {
  axios.defaults.headers.common[AUTHORIZATION] =  `bearer ${accessToken}`
};


export const setTenantHeaderInAxios = (tenant: Tenant) => {
  axios.defaults.headers.common[X_TENANT_IDENTIFIER] = tenant.identifier;
};
