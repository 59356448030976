import {
  Alert,
  Button as Muibutton,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../../components/buttons/Button";
import { FloorPlanManager } from "../../hooks/floor-plan-hooks/floorPlanHookTypes";

type props = {
  floorPlanManager: FloorPlanManager;
};

const FloorPlanTextEditor = ({ floorPlanManager }: props) => {
  const [input, setInput] = useState("");
  const [inputError, setInputError] = useState<string | null>(null);
  const [initializedMap, setInitializedMap] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const helperText = React.useMemo(() => {
    if (inputError !== null) {
      return inputError;
    }

    return "Input needs to be a valid json array.";
  }, [inputError]);

  const parsedInput = useMemo(() => {
    if ((floorPlanManager.map?.toString() ?? "") === input) {
      return floorPlanManager?.map ?? null;
    }

    if(input.length === 0) return null
    
    try {
      setInputError(null);
      let parsedInput = JSON.parse(input);
      return parsedInput;
    } catch (error) {
      let errorString = (error as any).toString();
      setInputError(errorString ?? null);
    }
    return null;
  }, [floorPlanManager.map, input]);

  const shouldUpdateFloorplanMap = useMemo(
    () =>
      !inputError &&
      JSON.stringify(floorPlanManager.map) !== JSON.stringify(parsedInput),
    [floorPlanManager.map, parsedInput, inputError]
  );

  const validInput = useMemo(
    () => input.length === 0 || !inputError,
    [input, inputError]
  );

  useEffect(() => {
    if (!initializedMap && floorPlanManager.map) {
      setInput(JSON.stringify(floorPlanManager.map, null, 4));
      setInitializedMap(true);
    }
  }, [initializedMap, floorPlanManager.map]);

  useEffect(() => {
    if (validInput) {
      setInputError(null);
    }
  }, [validInput]);

  useEffect(() => {
    if (shouldUpdateFloorplanMap) {
      floorPlanManager.setMap(parsedInput);
    }
  }, [shouldUpdateFloorplanMap, parsedInput, floorPlanManager]);

  useEffect(() => {
    if (floorPlanManager.saveStatus !== "default") {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
      return () => clearTimeout(timeoutId);
    }
  }, [floorPlanManager.saveStatus]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleClearClicked = () => {
    setInput("");
  };

  const handleSaveClicked = () => {

    
    if (validInput) {
      floorPlanManager.saveFloorPlan();
    }
  };

  return (
    <Grid
      container
      sx={{ paddingRight: 5, paddingLeft: 5 }}
      flexDirection={"column"}
      spacing={2}
    >
      <Grid item marginTop={2}>
        <Alert
          sx={{
            visibility:
              showAlert &&
              (floorPlanManager.saveStatus === "success" ||
                floorPlanManager.saveStatus === "error")
                ? "unset"
                : "hidden",
          }}
          severity={
            floorPlanManager.saveStatus === "success" ? "success" : "error"
          }
        >
          {floorPlanManager.saveStatus === "success"
            ? "New floorplan saved"
            : "Unable to save floorplan"}
        </Alert>
      </Grid>
      <Grid item>
        <TextField
          variant="filled"
          multiline
          fullWidth
          value={input}
          label="Paste json here"
          rows={13}
          margin="dense"
          error={inputError !== null}
          helperText={inputError !== null && helperText}
          onChange={handleChange}
        />
      </Grid>

      <Grid item container spacing={5}>
        <Grid item marginLeft={1}>
          <Muibutton variant="outlined" onClick={handleClearClicked}>
            Clear input
          </Muibutton>
        </Grid>
        <Grid item>
          {floorPlanManager.saveStatus === "loading" ? (
            <CircularProgress />
          ) : (
            <>
              {input.length > 0 && (
                <Button
                  onClick={handleSaveClicked}
                  disabled={input.length === 0 || !validInput}
                >
                  Save
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FloorPlanTextEditor;
