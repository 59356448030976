import { useCallback, useEffect, useReducer, useState } from "react";
import { Device, Tenant } from "../../models";
import { setTenantHeaderInAxios } from "../../utilities/axios";
import { getTenantFromStorage } from "../../utilities/storage/tenantStorage";
import {
  AssignmentAction,
  assignmentState,
  AssignmentActionTypes,
  createInitialStateType,
} from "./assignmentStateTypes";
import { useAddTenantDeviceManager } from "../add-tenant-device/addTenantDeviceHooks";
import { getDeviceById } from "../../utilities/axios/admin-api-calls/tenant-calls";
import { RoomModel } from "../../models/locations.model";
import { saveStateType } from "../../models/types";
import { DetailedDevice } from "../../models/detailedDevice.model";

export const steps = ["Select tenant", "Select room"];

const createInitialState: createInitialStateType = (serialNumber: string) => {
  return {
    serialNumber: serialNumber,
    tenant: null,
    device: null,
    step: 0,
    formReady: false,
    canConfirm: false,
  };
};

const assignmentReducer = (
  state: assignmentState,
  action: AssignmentAction
) => {
  switch (action.type) {
    case AssignmentActionTypes.reset:
      return createInitialState(state.serialNumber);
    case AssignmentActionTypes.setTenant:
      return { ...state, tenant: action.payload };
    case AssignmentActionTypes.setStep:
      return { ...state, step: action.payload };
    case AssignmentActionTypes.setFormReady:
      return { ...state, formReady: action.payload };
    case AssignmentActionTypes.setDevice:
      return { ...state, device: action.payload };
    case AssignmentActionTypes.setCanConfirm:
      return { ...state, canConfirm: action.payload };
    default:
      return state;
  }
};

export const useAdminAssignmentReducer = (serialNumber: string) => {
  const initialState = createInitialState(serialNumber);
  const [state, dispatch] = useReducer(assignmentReducer, initialState);
  const globalTenant = getTenantFromStorage();

  const setTenant = (tenant: Tenant | null) => {
    //hacky solution to temporarily change axiose headers
    if (tenant) {
      setTenantHeaderInAxios(tenant);
    } else if (globalTenant) {
      setTenantHeaderInAxios(globalTenant);
    }

    dispatch({ type: AssignmentActionTypes.setTenant, payload: tenant });
  };

  const incrementStep = () => {
    dispatch({
      type: AssignmentActionTypes.setStep,
      payload: state.step === steps.length - 1 ? state.step : state.step + 1,
    });
  };

  const decrementStep = () => {
    dispatch({
      type: AssignmentActionTypes.setStep,
      payload: state.step > 0 ? state.step - 1 : 0,
    });
  };

  const resetState = () => {
    //hacky solution to temporarily change axios headers
    if (globalTenant) setTenantHeaderInAxios(globalTenant);

    dispatch({ type: AssignmentActionTypes.reset });
  };

  const setFormReady = (formReady: boolean) => {
    dispatch({ type: AssignmentActionTypes.setFormReady, payload: formReady });
  };

  const setDevice = (device: Device) => {
    dispatch({ type: AssignmentActionTypes.setDevice, payload: device });
  };

  const setCanConfirm = (canConfirm: boolean) => {
    dispatch({
      type: AssignmentActionTypes.setCanConfirm,
      payload: canConfirm,
    });
  };


  return {
    state,
    actions: {
      setTenant,
      setDevice,
      incrementStep,
      decrementStep,
      setFormReady,
      resetState,
      setCanConfirm,
    },
  };
};

type props = {
  onClose: () => void;
  onAssignment: (newTenantIdentifier: string) => void;
  device: DetailedDevice;
};

export const useAssignTenantToAdminDevice = ({
  onClose,
  device,
  onAssignment,
}: props) => {
  const { state, actions } = useAdminAssignmentReducer(device.serialNumber);

  const [saveState, setSaveState] = useState<saveStateType>("default");
  const [room, setRoom] = useState<RoomModel | null>(null);


  const handleClose = () => {
    onClose();
  };

  const handleTenantChosen = (tenant: Tenant) => {
    actions.setTenant(tenant);
    actions.incrementStep();
  };

  const handleBack = () => {
    actions.decrementStep();
  };

  const handleCancel = () => {
    actions.resetState();
    onClose();
  };

  const onSuccessfulSave = useCallback(() => {
    if (saveState !== "success") return;
    onAssignment(state.tenant?.identifier);
  }, [saveState, state.tenant,  onAssignment]);

  useEffect(() => {
    if (saveState === "success") onSuccessfulSave();
  }, [saveState, onSuccessfulSave]);

  const handleConfirm = () => {
    setSaveState("loading")
    createDevice();
  };


  const onDeviceAssigned = useCallback(
    async (deviceId: string) => {
      if (!state.tenant) return;
      setSaveState("loading");
      let device = await getDeviceById(state.tenant.identifier, deviceId);

      if (device) {
        setSaveState("success");
        return;
      }
      setSaveState("error");
    },
    [state.tenant]
  );


  const onReset = () => {
    setRoom(null);
  };


  const {
    createDevice,
  } = useAddTenantDeviceManager({
    serialNumber: state.serialNumber,
    onDeviceCreated: onDeviceAssigned,
    onReset,
    room,
    tenant: state.tenant,
  });

  useEffect(() => {
    let canConfirm: boolean =
      !!state.tenant &&
      state.serialNumber.length > 0 &&
      !!room &&
      saveState !== "loading";

    if(canConfirm !== state.canConfirm)
        actions.setCanConfirm(canConfirm);
      }, [
    state.serialNumber.length,
    state.formReady,
    state.tenant,
    state.device,
    state.canConfirm,
    room,
    actions,
    saveState,
  ]);

  return {
    assignmentState: state,
    handleClose,
    handleTenantChosen,
    handleBack,
    handleCancel,
    handleConfirm,
    setRoom,
    saveState,
  };
};
