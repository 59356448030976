import { Button } from "@mui/material";
import React, { useState } from "react";
import AdminDeviceAssignmentDialog from "./AdminDeviceAssignmentDialog";
import { DetailedDevice } from "../../models/detailedDevice.model";

type props = {
  device: DetailedDevice;
onAssignment: ()=> void
};

const AdminDeviceAssignmentModal = ({ device, onAssignment }: props) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {modalOpen && (
        <AdminDeviceAssignmentDialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onAssignment={onAssignment}
          device={device}
        />
      )}
      <Button variant="outlined" onClick={() => setModalOpen(true)}>
        {device.serialNumber ? "Switch assigned device" : "Assign device"}
      </Button>
    </>
  );
};

export default AdminDeviceAssignmentModal;
