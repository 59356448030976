import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { DeviceInstallation } from "../../models";
import { useListenForEvent } from "../../hooks/deviceEvent-hooks/mostRecentEventsHook";
import { useDispatch, useSelector } from "react-redux";
import { resetEventsFilter } from "../../store/actions/eventActions";
import { useCallback, useEffect, useState } from "react";
import { RootState } from "../../store/reducers";
import SignalRHeart from "../signalr-heart/SignalRHeart";
import { DetailedDevice } from "../../models/detailedDevice.model";
import DoneIcon from "@mui/icons-material/Done";
import { EventTypeEnum } from "../../models/deviceEvents.model";

const EVENT_NUMBER_GOAL = 5;

export type transactionState = "default" | "skipped" | "verified";
type props = {
  state: transactionState;
  deviceInstallation: DeviceInstallation;
  onVerified: () => void;
  onSkipped: () => void;
  device: DetailedDevice;
};

const TransactionsVerifyStep = ({
  deviceInstallation,
  onVerified,
  onSkipped,
  state,
  device,
}: props) => {
  const dispatch = useDispatch();

  const [deviceIsOn, setDeviceIsOn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const signalRConnectionFetchState = useSelector(
    (state: RootState) => state.signalrReducer.connectionIdFetchState
  );

  const { count: mrCount, cleanUp } = useListenForEvent(
    device.tenant?.identifier ?? "unknown",
    deviceInstallation.serialNumber,
    EventTypeEnum.MotorReturned
  );

  const internalVerify = useCallback(() => {
    onVerified();
    cleanUp();
  }, [onVerified, cleanUp]);

  useEffect(() => {
    if (device) {
      setDeviceIsOn(device.status.isOn);
    }
  }, [device]);

  useEffect(() => {
    if (mrCount > 0) {
      setDeviceIsOn(true);
    }

    if (mrCount >= EVENT_NUMBER_GOAL) internalVerify();
  }, [mrCount, internalVerify]);

  useEffect(() => {
    if (signalRConnectionFetchState === "error") internalVerify();
    else {
      dispatch(resetEventsFilter());
    }
  }, [signalRConnectionFetchState, internalVerify, cleanUp, dispatch]);

  useEffect(()=>{
    return cleanUp
  }, [cleanUp])

  const handleDialogClose = () => setOpenDialog(false);

  const handleDialogConfirm = () => {
    handleDialogClose();
    onSkipped();
  };

  return (
    <Grid
      container
      flexDirection={"column"}
      alignContent={"space-between"}
      width={"100%"}
    >
      <Grid item>
        To proceed, turn the device on and use it <b>{EVENT_NUMBER_GOAL}</b>{" "}
        times.
      </Grid>
      <Grid item width={"100%"}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Device connection:</TableCell>
              <TableCell>{deviceIsOn ? "Online" : "Offline"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Motor returned count</TableCell>
              <TableCell>
                {state === "verified" ? (
                  <DoneIcon />
                ) : (
                  <>
                    {mrCount} / {EVENT_NUMBER_GOAL}
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Your real-time connection status</TableCell>
              <TableCell width={131}>
                {signalRConnectionFetchState === "loading" ? (
                  "Connecting..."
                ) : (
                  <SignalRHeart fontsize="small" />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item marginTop={4}>
        {signalRConnectionFetchState === "error" && (
          <>
            <p>Unable to connect to the real-time server.</p>
            <p>Please manually verify transactions.</p>
          </>
        )}
      </Grid>
      <Grid item marginTop={4}>
        <Button onClick={() => setOpenDialog(true)}>Skip this step</Button>
      </Grid>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogContent>Are you sure you want to skip this step?</DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TransactionsVerifyStep;
