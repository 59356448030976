import { useDispatch } from "react-redux";
import { CoordinateModel, PremiseModel } from "../../models/locations.model";
import { Button } from "../buttons/Button";
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { saveStateType } from "../../models/types";
import { updatePremiseLocation } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import { setPremise } from "../../store/actions/locationObjectActions";

type props = {premise:PremiseModel}
const AddCoordinatesButton = ({premise}:props) => {
    const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newLocation, setNewLocation] = useState<CoordinateModel | null>(null);
  const [rawText, setRawText] = useState("");
  const [saveState, setSaveState] = useState<saveStateType>("default");

  useEffect(() => {
    if (!!premise?.location) {
      setRawText(
        premise?.location.latitude + ", " + premise?.location.longitude
      );
      setNewLocation(premise.location);
    }
  }, [premise]);

  const updateDisabled = useMemo(
    () =>
      rawText.length === 0 ||
      isNaN(newLocation?.latitude ?? 0) ||
      isNaN(newLocation?.longitude ?? 0) ||
      !newLocation,
    [rawText, newLocation]
  );

  const toggleOpen = () => setDialogOpen((diaop) => !diaop);

  const updateRawText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const intext = event.target.value;
    setRawText(intext);

    const [lat, lon] = intext.split(", ");

    setNewLocation({
      latitude: parseFloat(lat),
      longitude: parseFloat(lon),
    });
  };

  const reset = () => {
    // setNewLocation(null);
    // setRawText("");
    setDialogOpen(false);
    setSaveState("default");
  };

  const updateLocation = useCallback(async () => {
    if (saveState !== "default" || !premise?.id || !newLocation) return;
    setSaveState("loading");

    var updated = await updatePremiseLocation(premise?.id, newLocation);
    
    if (!updated) {
      setSaveState("error");
      return;
    }

    setSaveState("success");
    dispatch(setPremise(updated))
  }, [premise, newLocation, saveState, dispatch]);

  const updateLocationClicked = () => {
    updateLocation();
  };

  return (
    <>
      <Button onClick={toggleOpen}>Add coordinates</Button>
      <Dialog open={dialogOpen} onClose={reset}>
        <DialogContent>
          <Grid container flexDirection={"column"} spacing={3}>
            <Grid item>
              <h3>Update location coordinates</h3>
            </Grid>
            <Grid item>
              Add coordinates to {premise?.name} by pasting google maps
              coordinates here
            </Grid>
            <Grid item>
              <TextField fullWidth onChange={updateRawText} value={rawText} />
            </Grid>
            <Grid item>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Latitude:</TableCell>
                    <TableCell>{newLocation?.latitude}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Longitude:</TableCell>
                    <TableCell>{newLocation?.longitude}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              {saveState === "default" && (
                <Button
                  disabled={updateDisabled}
                  onClick={updateLocationClicked}
                >
                  Update coordinates
                </Button>
              )}

              {saveState === "loading" && <CircularProgress />}
              {saveState === "success" && (
                <Alert severity="success">Location updated</Alert>
              )}
              {saveState === "error" && (
                <Alert severity="error">Unable to update location</Alert>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCoordinatesButton;
