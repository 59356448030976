import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { GroupRepresentation, UserSection } from "../../models/users.model";
import { RootState } from "../../store/reducers";
import { inviteUser } from "../../utilities/axios/admin-api-calls/users-calls/usersCalls";
import { Button } from "../buttons/Button";
import RolesComboBox from "../roles/RolesComboBox";
import UserSectionComboBox from "../user-section-combobox/UserSectionComboBox";

const AddUserForm = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [groups, setGroups] = useState<GroupRepresentation[]>([]);
  const [sections, setSections] = useState<UserSection[]>([]);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sentEmail, setSentEmail] = useState("");
  const tenant = useSelector((state: RootState) => state.tenantReducer.tenant);

  const validForm = useMemo(
    () =>
      validateEmail(email) &&
      validateName(firstName) &&
      validateName(lastName) &&
      groups.length > 0 &&
      sections.length > 0,
    [email, firstName, lastName, groups, sections]
  );

  const sendInvite = useCallback(async () => {
    if (!tenant) return;
    setSendingInvite(true);
    let res = await inviteUser({
      userInfo: {
        email,
        firstName,
        lastName,
        groups: groups.map((g) => g.id),
        tenantId: tenant.identifier,
      },
      allowedSections: sections.map((s) => s.id),
    });
    let inviteSent = res === 200;
    setSendingInvite(false);
    setSuccess(inviteSent);
    if (inviteSent) {
      setSentEmail(email);
      setEmail("");
      setFirstName("");
      setLastName("");
      setGroups([]);
      setSections([]);
    }
    setShowAlert(true);
  }, [email, firstName, lastName, tenant, groups, sections]);

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleInviteClicked = () => {
    if (!validForm) return;

    sendInvite();
  };

  return (
    <Box width={350}>
      <Grid item>
        {showAlert && (
          <>
            {success ? (
              <Alert severity="success">
                Invitation sent to <b>{sentEmail}</b>
              </Alert>
            ) : (
              <Alert severity="error">
                An error occured while trying to send email.
              </Alert>
            )}
          </>
        )}
      </Grid>
      <Grid item margin={1}>
        <TextField
          id="outlined-name"
          fullWidth
          label="First name"
          value={firstName}
          onChange={handleFirstNameChange}
          variant="outlined"
        />
      </Grid>
      <Grid item margin={1}>
        <TextField
          id="outlined-name"
          fullWidth
          label="Last name"
          value={lastName}
          onChange={handleLastNameChange}
          variant="outlined"
        />
      </Grid>
      <Grid item margin={1}>
        <TextField
          id="outlined-email"
          fullWidth
          label="Email"
          value={email}
          onChange={handleEmailChange}
          variant="outlined"
        />
      </Grid>

      <Grid item margin={1}>
        <RolesComboBox groups={groups} onGroupsChosen={setGroups} />
      </Grid>

      <Grid item margin={1}>
        <UserSectionComboBox
          sections={sections}
          onSectionsChosen={setSections}
          disabled={false}
        />
      </Grid>

      {sendingInvite ? (
        <Grid
          container
          item
          margin={1}
          flexDirection="column"
          alignContent="center"
        >
          <CircularProgress sx={{ alignSelf: "center" }} />
          <Typography textAlign={"center"} sx={{ alignSelf: "center" }}>
            Sending invitation...
          </Typography>
        </Grid>
      ) : (
        <Grid item margin={1}>
          <Button onClick={handleInviteClicked} fullWidth disabled={!validForm}>
            Invite user
          </Button>
        </Grid>
      )}
    </Box>
  );
};

export default AddUserForm;

const validateEmail = (email: string) => {
  return email.length > 0;
};
const validateName = (name: string) => {
  return name.length > 0;
};
