import { Device, Tenant } from "../../models"



export type assignmentState = {
    serialNumber: string
    tenant: null | Tenant,
    device: null | Device,
    step: number,
    formReady: boolean,
    canConfirm: boolean
}


export type createInitialStateType = (serialNumber:string) => assignmentState

export interface AssignmentAction {
    type: AssignmentActionTypes,
    payload?: any
}


export enum AssignmentActionTypes {
  reset = 'RESET',
  setTenant = 'SET_TENANT',
  setDevice = 'SET_DEVICE',
  setStep = 'SET_STEP',
  setFormReady = 'SET_FORM_READY',
  setCanConfirm = "SET_CAN_CONFIRM"
}