import { Breadcrumbs, Grid, Link, Skeleton, Typography } from "@mui/material";
import { Link as RouterLink, Outlet } from "react-router-dom";

export interface Crumb {
  preface: string | null;
  name: string;
  path: string;
  link: boolean;
  loading: boolean;
}

type props = { crumbs: Crumb[] };

const BreadCrumbs = ({ crumbs }: props) => {
  return (
    <>
      <Grid margin={2}>
        <Breadcrumbs>
          {crumbs.map((crumb) => {
            return (
              <Grid key={crumb.path} display={"flex"}>
                <Typography marginRight={1} ><i>{crumb.preface && `${crumb.preface}:`} </i></Typography>
                {crumb.loading? (
                  <Skeleton variant="text">
                    <Typography>{crumb.name}</Typography>
                  </Skeleton>
                ) : (
                  <>
                    {crumb.link ? (
                      <Link to={crumb.path} component={RouterLink}>
                        {crumb.name}
                      </Link>
                    ) : (
                      <Typography>{crumb.name}</Typography>
                    )}
                  </>
                )}
              </Grid>
            );
          })}
        </Breadcrumbs>
      </Grid>
      <Outlet />
    </>
  );
};

export default BreadCrumbs;
