import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadMostRecentEvents } from "../store/actions/eventActions";
import { RootState } from "../store/reducers";
import { useTenant } from "../hooks/tenant";
import { useFilter } from "../hooks/deviceEvent-hooks/filterHooks";
import { useIsAuthenticated } from "../hooks/authentication-hooks/authenticationHooks";
import useBroadcaster, {
  useTenantGroup,
} from "../hooks/signalR-hooks/signalRHook";
import { Tenant } from "../models";

type props = {
  children?: JSX.Element;
};

const SignalRToReduxProvider = ({ children }: props) => {
  const dispatch = useDispatch();

  const stateEvents = useSelector(
    (state: RootState) => state.eventsReducer.mostRecentEvents
  );

  const mostRecentEventsInitiated = useSelector(
    (state: RootState) => state.eventsReducer.initiated
  );

  const maxEventsInFeed = useSelector(
    (state: RootState) => state.eventsReducer.maxEventsInFeed
  );

  const { filter } = useFilter();

  const tenant = useTenant();

  const [prevTenant, setPrevTenant] = useState<Tenant | null>(null);

  const { authDone } = useIsAuthenticated();

  const  connection  = useBroadcaster();

  const { currentGroup, changeGroup } = useTenantGroup();

  useEffect(() => {
    if (tenant) dispatch(loadMostRecentEvents());
  }, [maxEventsInFeed, filter, tenant, dispatch]);

  useEffect(() => {
    if (tenant && connection) {
      if (!currentGroup) {
        changeGroup(tenant.identifier);
      } else if (tenant.identifier !== currentGroup) {
        changeGroup(tenant.identifier);
      }
      setPrevTenant(tenant);
    }
  }, [tenant, connection, currentGroup, prevTenant, changeGroup]);


  useEffect(() => {
    if (
      authDone &&
      stateEvents.length < maxEventsInFeed &&
      !mostRecentEventsInitiated &&
      tenant
    ) {
      dispatch(loadMostRecentEvents());
    }
  }, [
    stateEvents,
    authDone,
    mostRecentEventsInitiated,
    tenant,
    maxEventsInFeed,
    dispatch,
  ]);

  return <>{children}</>;
};

export default SignalRToReduxProvider;

