import store, { AppDispatch } from "..";
import { Tenant } from "../../models";
import { fetchStateType, saveStateType } from "../../models/types";
import { PaginationQuery } from "../../utilities/api";
import {
  getMaintenanceSessionsForUser,
  getServiceTenants,
  startMaintenanceMode,
} from "../../utilities/axios/maintenance-api-calls";
import {
  SET_MAINTENANCE_ACTIVE,
  SET_MAINTENANCE_ACTIVE_FETCH_STATE,
  SET_MAINTENANCE_ACTIVE_SAVE_STATE,
  SET_MAINTENANCE_TENANT,
  SET_MAINTENANCE_ID,
  SET_MAINTENANCE_SERVICE_TENANT,
  SET_MAINTENANCE_SERVICE_TENANT_FETCHSTATE
} from "./actionTypes";



export const fetchServiceTenant = () => async (dispatch: AppDispatch) => {
  
  let fetchState = store.getState().maintenanceReducer.serviceTenant.fetchState;
  let serviceTenant = store.getState().maintenanceReducer.serviceTenant;

  
  if (fetchState === "loading" || serviceTenant.tenant) return;

  
  dispatch(setServiceTenantFetchState("loading"));


  var serviceTenants = await getServiceTenants(new PaginationQuery())

  
  if(!serviceTenants)
    return dispatch(setServiceTenantFetchState("error"));


  dispatch(setServiceTenantFetchState("fetched"));

  return dispatch(setServiceTenant(serviceTenants.items[0]))
};

export const fetchMaintanceActive = () => async (dispatch: AppDispatch) => {
  let tenant = store.getState().tenantReducer.tenant;
  let userProfile = store.getState().userReducer.profile;
  let fetchState = store.getState().maintenanceReducer.fetchState;
  let serviceTenant = store.getState().maintenanceReducer.serviceTenant.tenant;


  if (!tenant || !userProfile?.id || fetchState === "loading" || fetchState === "error" || serviceTenant?.identifier === tenant.identifier) return;

  dispatch(setFetchState("loading"));
  var sessions = await getMaintenanceSessionsForUser(
    new PaginationQuery(),
    userProfile.id,
    [tenant.identifier]
  );
  if (!sessions) return dispatch(setFetchState("error"));

  let mostRecentSession = sessions?.items[0];

  let isToday = false;
  if (mostRecentSession) {
    isToday =
      new Date(mostRecentSession.created).getDate() === new Date().getDate();
    dispatch(setMaintenanceId(mostRecentSession.id));
  }

  dispatch(setMaintenanceModeTenant(tenant));
  dispatch(setMaintenanceModeActive(isToday));

  dispatch(setFetchState("fetched"));
};

export const setMaintenanceModeClicked =
  (tenant: Tenant) => async (dispatch: AppDispatch) => {
    dispatch(setSaveState("loading"));
    dispatch(setMaintenanceModeTenant(tenant));
    let res = await startMaintenanceMode();

    switch (res) {
      case 204:
        dispatch(setMaintenanceModeActive(true));
        return dispatch(setSaveState("success"));
      default:
        return dispatch(setSaveState("error"));
    }
  };

const setMaintenanceModeActive = (maintenanceModeActive: boolean) => {
  return {
    type: SET_MAINTENANCE_ACTIVE,
    payload: maintenanceModeActive,
  };
};

const setFetchState = (fetchState: fetchStateType) => {
  return {
    type: SET_MAINTENANCE_ACTIVE_FETCH_STATE,
    payload: fetchState,
  };
};

const setSaveState = (saveState: saveStateType) => {
  return {
    type: SET_MAINTENANCE_ACTIVE_SAVE_STATE,
    payload: saveState,
  };
};

const setMaintenanceModeTenant = (tenant: Tenant) => {
  return {
    type: SET_MAINTENANCE_TENANT,
    payload: tenant,
  };
};

const setMaintenanceId = (id: string) => {
  return {
    type: SET_MAINTENANCE_ID,
    payload: id,
  };
};


const setServiceTenant = (serviceTenant: Tenant) => {
  return {
    type: SET_MAINTENANCE_SERVICE_TENANT,
    payload: serviceTenant,
  };
};

const setServiceTenantFetchState = (fetchstate: fetchStateType) => {
  return {
    type: SET_MAINTENANCE_SERVICE_TENANT_FETCHSTATE,
    payload: fetchstate,
  };
};