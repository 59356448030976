import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../../provider/AuthContextProvider";
import { Button } from "./Button";

const CopyAccessTokenButton = () => {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = () => {
    const accessTokenResponse = authContext.getToken();
    let accessToken = accessTokenResponse ?? "";
    navigator.clipboard.writeText(accessToken);
  };

  return (
    <Box width={170} height={30} hidden={screenIsSmall}>
      {process.env.NODE_ENV === "development" && (
        <Button onClick={() => handleClick()} variant={"secondary"}>
          Copy token
        </Button>
      )}
    </Box>
  );
};

export default CopyAccessTokenButton;
