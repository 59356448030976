import { useCallback } from "react";
import { FloorPlanDto } from "../../models";
import {
  postFloorPlanToFloor,
  postFloorPlanToRoom,
  updateFloorPlanToFloor,
  updateFloorPlanToRoom,
} from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import { EntityType, useFloorPlanCallBacksType } from "./floorPlanHookTypes";

export const useFloorPlanCallBacks: useFloorPlanCallBacksType = ({
  setSaveStatus,
  createNew,
}) => {
  const saveTemplate = useCallback(
    (
        postApiCall: (
          premiseId: string,
          floorPlan: FloorPlanDto
        ) => Promise<number>,
        updateApiCall: (
          premiseId: string,
          floorPlan: FloorPlanDto
        ) => Promise<number>,
        createNew: boolean
      ) =>
      async (floorPlan: FloorPlanDto, entity: EntityType) => {
        if (!entity) return;

        setSaveStatus("loading")
        let result = 0;

        if (createNew) result = await postApiCall(entity.id, floorPlan);
        else result = await updateApiCall(entity.id, floorPlan);

        if (result <= 204) {
          setSaveStatus("success");
        } else {
          setSaveStatus("error");
        }
      },
    [setSaveStatus]
  );

  const saveFloorPlanForFloor = saveTemplate(
    postFloorPlanToFloor,
    updateFloorPlanToFloor,
    createNew
  );
  const saveFloorPlanForRoom = saveTemplate(
    postFloorPlanToRoom,
    updateFloorPlanToRoom,
    createNew
  );

  return {
    saveFloorPlanForFloor,
    saveFloorPlanForRoom,
  };
};
