import { useState, useEffect, useCallback, useContext } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import {
  AuthContext,
  AuthContextValues,
} from "../../provider/AuthContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { changeTenantIdGroup, setConnectionIdFetchState, setLockCurrentGroup } from "../../store/actions/signalrActions";
import { RootState } from "../../store/reducers";
import { config, signalRFunctions } from "../../config";
import { addEvent } from "../../store/actions/eventActions";
import { parseSignalREvent } from "../../utilities/events/eventUtils";
import { useTenant } from "../tenant";

const MAX_CONNECTION_RETRYS = 1;

let sharedConnection: HubConnection | null = null;

export const useBroadcaster = (
  hubUrl: string = `${config.signalR.url}/signalR`
): HubConnection | null => {
  const dispatch = useDispatch();

  const connectionIdState = useSelector(
    (state: RootState) => state.signalrReducer.connectionIdFetchState
  );
  const [callbacksAdded, setCallbacksAdded] = useState(false);

  const [connectionRetrys, setConnectionRetrys] = useState(0);

  const authContext = useContext(AuthContext);

  const [connection, setConnection] = useState<HubConnection | null>(
    sharedConnection
  );

  const connectHub = useCallback(
    async (keycloak: AuthContextValues) => {
      try {
        const connection = new HubConnectionBuilder()
          .configureLogging(LogLevel.Critical)
          .withUrl(hubUrl, {
            accessTokenFactory: async () => {
              let tokenResponse = keycloak.getToken();
              return tokenResponse ?? "";
            },
          })
          .withAutomaticReconnect()
          .build();

        await connection.start();

        console.log(
          "Signal R connected with connectionId " + connection.connectionId
        );
        return connection;
      } catch (error) {
        console.log(error);
        // console.log("Signal R connection error");
        return null;
      }
    },
    [hubUrl]
  );

  const establishConnection = useCallback(async () => {
    if (
      !authContext.isAuthenticated ||
      !!connection ||
      connectionIdState === "loading" ||
      connectionIdState === "fetched" ||
      connectionRetrys >= MAX_CONNECTION_RETRYS
    )
      return;

    dispatch(setConnectionIdFetchState("loading"));

    let newConnection = await connectHub(authContext);
    setConnection(newConnection);
    sharedConnection = newConnection;

    if (!newConnection) {
      dispatch(setConnectionIdFetchState("error"));

      setConnectionRetrys(connectionRetrys + 1);
      return;
    }

    setConnectionRetrys(0);

    dispatch(setConnectionIdFetchState("fetched"));
  }, [
    connection,
    authContext,
    connectionIdState,
    connectionRetrys,
    connectHub,
    dispatch,
  ]);


  // this useEffect needs to be in the same place as the hub is created, idk why
  useEffect(() => {
    if (!callbacksAdded && connection) {
      for (const [, value] of Object.entries(signalRFunctions.device)) {
        connection.on(value, (message) => {
          let receivedEvent = parseSignalREvent(value, message);
          dispatch(addEvent(receivedEvent));
        });
      }
      setCallbacksAdded(true);
    }
  }, [callbacksAdded, connection, dispatch]);

  useEffect(() => {
    if (!connection && authContext.isAuthenticated) {
      establishConnection();
    }
  }, [authContext, connection, establishConnection]);

  return connection;
};

export default useBroadcaster;

export const useTenantGroup = () => {
  const currentGroup = useSelector((state:RootState) => state.signalrReducer.currentTenantGroup)
  const connection = useBroadcaster();
  const dispatch = useDispatch()
  const tenant = useTenant()

  const changeGroup = useCallback(
    async (newGroup: string) => {
      if (!connection) return;
      dispatch(changeTenantIdGroup(connection, newGroup))
    },
    [connection, dispatch]
  );

  const forceCurrentGroup = useCallback(
    async (newGroup: string) => {
    if (!connection) return;
    dispatch(changeTenantIdGroup(connection, newGroup, true))},
    [connection, dispatch]
  );

  const undoForcedGroup = useCallback(() => {
    dispatch(setLockCurrentGroup(false))
    if(!tenant || !connection) return
    
    dispatch(changeTenantIdGroup(connection, tenant.identifier))
  },[dispatch, tenant, connection])


  return { currentGroup, changeGroup, forceCurrentGroup, undoForcedGroup };
};
