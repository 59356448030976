import { AnyAction, Reducer } from "redux";
import { DetailedDevice } from "../../models/detailedDevice.model";
import { fetchStateType } from "../../models/types";
import { SET_DETAILED_DEVICE, SET_FETCH_STATE_DETAILED_DEVICE,RESET_DETAILED_DEVICE_STATE, SET_FLUID } from "../actions/actionTypes";

type eventStateType = {
  device: null | DetailedDevice;
  fetchState: fetchStateType;
};

const initialState: eventStateType = {
  device: null,
  fetchState: "default",
};

const detailedDeviceReducer: Reducer<eventStateType, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_DETAILED_DEVICE:
      return {
        ...state,
        device: action.payload,
      };
    case SET_FLUID:
      return {
        ...state,
        device:{
          ...state.device,
          fluid: action.payload
        }
      }
    case SET_FETCH_STATE_DETAILED_DEVICE:
      return {
        ...state,
        fetchState: action.payload,
      };
      case RESET_DETAILED_DEVICE_STATE:
        return initialState
    default:
      return state;
  }
};

export default detailedDeviceReducer;
