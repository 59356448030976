import { fluidKind, LocationModel } from "./detailedDevice.model";

export interface BaseModel{
  id: string;
}

export interface Device {
  serialNumber: string
  fluidLeft: number
  batteryLeft: number
  isOn: boolean
  location: LocationModel
  firmware: Firmware
  mostRecentInstallation: DeviceInstallation | null
}

export interface BaseAdminDevice {
  serialNumber: string;
}

export interface CreatedUpdated extends BaseModel {
  created: Date,
  updated: Date
}

export interface AdminDevice extends BaseAdminDevice {
  firmware: Firmware | null
  isUpdatingFirmware: boolean
  tenant: Tenant | null;
  roomId: string
}

export interface DeviceSpecification{
  modelId: string

}
export interface DeviceProvisioningDto{
  deviceSpecification:DeviceSpecification
  numberOfNewDevices: number
}

export interface ProvisionResults{
  devicesRequested: number,
  successfullyProvisioned: number,
  serialNumbers:string[]
}

export interface DeviceStatus extends BaseModel {
  deviceId: string;
  fluid: number;
  battery: number;
  FirmwareVersion: string;
}


export interface CreateFluidDto{
  fluid: number
}

export interface FluidTypeDto{
  type: fluidKind
}


export interface Fluid{
}

export interface CreateStatusDto{
  lastChangedBattery: Date
}

export interface UpdateAdminDevice extends BaseAdminDevice {
  deviceId: string;
  tenantIdentifier: string;
}

export interface UpdatePowerSource{
  runsOnBattery: boolean
}

export interface UpdateTenantDevice {
roomId: string
}

export interface Tenant {
  name: string;
  identifier: string;
  realm: string;
}


export interface FloorPlan {
  map: FloorPlanMap;
  id: string;
}

export interface FloorPlanDto {
  map: FloorPlanMap;
}

export type FloorPlanMap = any;


export interface Firmware {
serialNumber: string,
version: string
link?: string
}



export interface FirmwareVersion {
  gitTag: string,
  availableInBlob: boolean,
  uploaded: Date
  
}

export interface Activity {
  expectedActivityWithTag: number;
  activityWithTag: number;
  date: string;
}

export interface CreateOrUpdateActivityProfile {
  id: string | null;
  start: string;
  end: string;
  expectedWithTag: number;
  expectedWithoutTag: number;
}

export interface ActivityProfile{
  id: string;
  created: string;
  updated: string;
  start: string;
  end: string;
  expectedWithTag: number;
  expectedWithoutTag: number;
}

export interface Activities {
  activities: Activity[];
  profiles: ActivityProfile[];
}

export enum DeviceInstallationStatus{
  READY = "Ready",
  IN_PROGRESS = "InProgress",
  INSTALLED = "Installed",
  ABORTED = "Aborted"
}

export const isReadyOrInProgress = (deviceInstallationStatus: DeviceInstallationStatus) => deviceInstallationStatus === DeviceInstallationStatus.READY || deviceInstallationStatus === DeviceInstallationStatus.IN_PROGRESS

export interface DeviceInstallation extends CreatedUpdated{
  serialNumber:string
  installationDate: Date | null
  installationStatus: DeviceInstallationStatus
  tenant: Tenant
  installerId: string | null
} 


export interface CompleteInstallDto{
  roomId: string
}