import axios, { AxiosError } from "axios";
import {
  API_URL,
  IPaginationQuery,
  PagedResponse,
  repeatedArrayFormat,
} from "../../../api";
import {
  CompleteInstallDto,
  DeviceInstallation,
  DeviceInstallationStatus,
} from "../../../../models";

export const getDeviceInstallations = async (
  serialnumber: string,
  paginationQuery: IPaginationQuery,
  tenantIdentifierFilter: string[] | null = null,
  statusFilter: DeviceInstallationStatus[] | null = null
): Promise<PagedResponse<DeviceInstallation> | null> => {
  try {
    let params: any = {};

    if (tenantIdentifierFilter !== null) {
      params.tenantIdentifierFilter = tenantIdentifierFilter;
    }

    if (statusFilter !== null) {
      params.installationStatusFilter = statusFilter;
    }

    
    let res = await axios.get(
      paginationQuery.toUrl(`/devices/${serialnumber}/installations`),
      {
        params,
        paramsSerializer: repeatedArrayFormat,
      }
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};


export const getAllDeviceInstallations = async (
  paginationQuery: IPaginationQuery,
  tenantIdentifierFilter: string[] | null = null,
  statusFilter: DeviceInstallationStatus[] | null = null
): Promise<PagedResponse<DeviceInstallation> | null> => {
  try {
    let params: any = {};

    if (tenantIdentifierFilter !== null) {
      params.tenantIdentifierFilter = tenantIdentifierFilter;
    }

    if (statusFilter !== null) {
      params.installationStatusFilter = statusFilter;
    }

    
    let res = await axios.get(
      paginationQuery.toUrl(`/device-installations`),
      {
        params,
        paramsSerializer: repeatedArrayFormat,
      }
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const createDeviceInstallation = async (
  serialnumber: string,
  tenantIdentifier: string
): Promise<DeviceInstallation | number> => {
  try {
    let res = await axios.post(`${API_URL}/device-installations`, {
      serialnumber,
      tenantIdentifier,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    return (e as AxiosError).response?.status ?? 500;
  }
};



export const updateStatus = async(
  id:string,
  newStatus:DeviceInstallationStatus
): Promise<DeviceInstallation | null> =>
{
  try {
    let res = await axios.patch(`${API_URL}/device-installations/${id}/status`, 
      {installationStatus: newStatus},
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }

}


export const updateInstaller = async(
  id:string,
): Promise<DeviceInstallation | null> =>
{
  try {
    let res = await axios.put(`${API_URL}/device-installations/${id}/installer`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }

}


export const completeInstall = async(
  id:string,
  completeInstallDto:CompleteInstallDto
  ): Promise<DeviceInstallation | null> =>
{
  try {
    let res = await axios.post(`${API_URL}/device-installations/${id}/complete`, completeInstallDto);
    return res.data;
  } catch (e) {
    console.error(e);
    return null;
  }

}