import { useGetLocationalBreadcrumb } from "../../hooks/location-hooks/breadcrumbHooks";
import BreadCrumbs from "./BreadCrumbs";
const FloorPlanBreadcrumbs = () => {
  const crumbs = useGetLocationalBreadcrumb();

  return (
    <>
      <BreadCrumbs crumbs={crumbs} />
    </>
  );
};

export default FloorPlanBreadcrumbs;
