import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPremise as setPremiseToStore,
  setSection as setSectionToStore,
  setRoom as setRoomToStore,
  setFloor as setFloorToStore,
} from "../../store/actions/locationObjectActions";
import { useParams } from "react-router-dom";
import {
  PremiseModel,
  SectionModel,
  RoomModel,
  FloorModel,
} from "../../models/locations.model";
import { RootState } from "../../store/reducers";
import {
  getPremiseById,
  getSectionById,
  getRoomById,
  getFloorById,
} from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import { entityEnum } from "../floor-plan-hooks/floorPlanHookTypes";

interface PathIds {
  roomId: string | null;
  sectionId: string | null;
  premiseId: string | null;
  floorId: string | null;
}
type useIdsFromPathType = () => PathIds;

export const useIdsFromPath: useIdsFromPathType = () => {
  const params = useParams();
  const ids = useMemo(() => {
    return {
      roomId: params.roomId ?? null,
      sectionId: params.sectionId ?? null,
      premiseId: params.premiseId ?? null,
      floorId: params.floorId ?? null,
    };
  }, [params]);

  return ids;
};

export const useObjectsFromPath = () => {
  const ids = useIdsFromPath();

  const [premise, setPremise] = useState<PremiseModel | null>(null);
  const [section, setSection] = useState<SectionModel | null>(null);
  const [floor, setFloor] = useState<FloorModel | null>(null);

  const [room, setRoom] = useState<RoomModel | null>(null);

  const [isLoadingPremise, setIsLoadingPremise] = useState(false);

  const locationObjects = useSelector(
    (state: RootState) => state.locationObjectsReducer
  );
  const dispatch = useDispatch();

  const shouldCallApiForPremise = useMemo(
    () =>
      ids.premiseId &&
      (locationObjects.premise?.id ?? null) !== ids.premiseId &&
      !isLoadingPremise,
    [ids, locationObjects, isLoadingPremise]
  );
  const shouldCallApiForSection = useMemo(
    () => ids.sectionId && locationObjects.section?.id !== ids.sectionId,
    [ids, locationObjects]
  );

  const shouldCallApiForFloor = useMemo(
    () => ids.floorId && locationObjects.floor?.id !== ids.floorId,
    [ids, locationObjects]
  );

  const shouldCallApiForRoom = useMemo(
    () => ids.roomId && locationObjects.room?.id !== ids.roomId,
    [ids, locationObjects]
  );

  const [outerMostEntity, setOuterMostEntity] = useState<entityEnum>(
    locationObjects.outerMostLocationObjectType
  );

  useEffect(() => {
    if (ids.roomId) {
      setOuterMostEntity(entityEnum.RoomModel);
    } else if (ids.floorId) {
      setOuterMostEntity(entityEnum.FloorModel);
    } else if (ids.premiseId) {
      setOuterMostEntity(entityEnum.PremiseModel);
    } else if (ids.sectionId) {
      setOuterMostEntity(entityEnum.SectionModel);
    } else {
      setOuterMostEntity(entityEnum.nullEntity);
    }
  }, [ids]);

  const findPremise = useCallback(async () => {
    if (!ids.premiseId || isLoadingPremise) return;

    setIsLoadingPremise(true);
    let newPremise = await getPremiseById(ids.premiseId);

    setIsLoadingPremise(false);

    if (!newPremise) return;

    setPremise(newPremise);
    dispatch(setPremiseToStore(newPremise));
  }, [ids, isLoadingPremise, dispatch]);

  const findSection = useCallback(async () => {
    if (!ids.sectionId) return;

    let newSection = await getSectionById(ids.sectionId);
    if (!newSection) return;

    setSection(newSection);
    dispatch(setSectionToStore(newSection));
  }, [ids, dispatch]);

  const findFloor = useCallback(async () => {
    if (!ids.floorId) return;

    let newFloor = await getFloorById(ids.floorId);
    if (!newFloor) return;

    setFloor(newFloor);
    dispatch(setFloorToStore(newFloor));
  }, [ids, dispatch]);

  const findRoom = useCallback(async () => {
    if (!ids.roomId) return;

    let newRoom = await getRoomById(ids.roomId);
    if (!newRoom) return;

    setRoom(newRoom);
    dispatch(setRoomToStore(newRoom));
  }, [ids, dispatch]);

  useEffect(() => {
    if (shouldCallApiForPremise) {
      findPremise();
    } else {
      setPremise(locationObjects.premise);
    }
  }, [shouldCallApiForPremise, locationObjects, findPremise]);

  useEffect(() => {
    if (shouldCallApiForSection) {
      findSection();
    } else {
      setSection(locationObjects.section);
    }

    if (shouldCallApiForFloor) {
      findFloor();
    } else {
      setFloor(locationObjects.floor);
    }

    if (shouldCallApiForRoom) {
      findRoom();
    } else {
      setRoom(locationObjects.room);
    }
  }, [
    ids,
    shouldCallApiForPremise,
    shouldCallApiForSection,
    shouldCallApiForFloor,
    shouldCallApiForRoom,
    locationObjects,
    findPremise,
    findSection,
    findFloor,
    findRoom,
  ]);

  return { premise, section, floor, room, outerMostEntity };
};
