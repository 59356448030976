import { useState, useCallback } from "react";
import { DetailedDevice } from "../../models/detailedDevice.model"
import { saveStateType } from "../../models/types";
import { changeBatteries } from "../../utilities/axios/admin-api-calls/tenant-calls";
import { useTenant } from "../tenant";



export const useUpdateBattery = (device:DetailedDevice, onSuccess: () => void) => {

    const [saveState, setSaveState] = useState<saveStateType>("default");

    const tenant = useTenant();
  
    const onBatteryChangeClick = useCallback(async () => {
      if (!tenant || saveState === "loading") return;
      setSaveState("loading");
      var res = await changeBatteries(device.serialNumber, tenant.identifier);
      if (res <= 204) {
        setSaveState("success");
        onSuccess();
        return;
      }
  
      setSaveState("error");
    }, [device, tenant, saveState, onSuccess]);


    return {
        onBatteryChangeClick,
        saveState
    }
}
