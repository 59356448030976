import { useCallback, useEffect, useState } from "react";
import { fetchStateType } from "../../models/types";
import {
  getSimpleDeviceById,
} from "../../utilities/axios/admin-api-calls/adminCalls";
import { DetailedDevice } from "../../models/detailedDevice.model";
import { getDetailedDeviceById } from "../../utilities/axios/admin-api-calls/tenant-calls";

export const useAdminDevice = (adminDeviceId: string | undefined) => {
  const [adminDevice, setAdminDevice] = useState<DetailedDevice | null>(null);
  const [fetchState, setFetchState] = useState<fetchStateType>("default");

  const fetchAdminDevice = useCallback(async () => {
    if (!adminDeviceId) return;
    setFetchState("loading");
    let simpleDevice = await getSimpleDeviceById(adminDeviceId);
    if (!simpleDevice) {
      setFetchState("error");
      return;
    }

    let deviceRes = await getDetailedDeviceById(
      simpleDevice.location.tenant.identifier,
      adminDeviceId
    );

    if (!deviceRes) {
      setFetchState("error");
      return;
    }

    if (deviceRes.status > 200){
      setFetchState("error");
      return;
    }

    setFetchState("fetched");
    console.log(deviceRes);
    

    setAdminDevice(deviceRes.data);
  }, [adminDeviceId]);

  useEffect(() => {
    if (!adminDevice && adminDeviceId) fetchAdminDevice();
  }, [adminDevice, adminDeviceId, fetchAdminDevice]);

  return { adminDevice, fetchAdminDevice, fetchState, setFetchState };
};

export type useAdminDeviceType = ReturnType<typeof useAdminDevice>;
