import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(utc);
dayjs.extend(isoWeek);

/**
 * Returns the sunday of any given week
 * @param week
 * @param year
 */
export const getSundayDateFromWeekNumberAndYear = (
  week: number,
  year: number
) => {
  const sunday = new Date(year, 0, 1 + (week - 1) * 7);
  while (sunday.getDay() !== 0) {
    sunday.setDate(sunday.getDate() - 1);
  }
  return dayjs(sunday).add(14, "day");
};

/**
 * Returns both the start and end date of a week (monday, friday)
 * based on a week number
 * @param week
 * @param year
 */
export const getStartAndEndDateFromWeekAndYear = (
  week: number,
  year: number
) => {
  const sunday = getSundayDateFromWeekNumberAndYear(week, year);
  const monday = sunday
    .add(-6, "day")
    .hour(15)
    .utc()
    .hour(0)
    .minute(0)
    .second(0);
  const friday = sunday
    .add(-2, "day")
    .hour(15)
    .utc()
    .hour(23)
    .minute(59)
    .second(59);

  return { start: monday, end: friday };
};

/**
 * Returns a week based on either a string or a number representation
 * If week cannot be determined, then the current week is provided
 * @param week
 */
export const getWeekOrCurrent = (week: string | null | number) => {
  if (week === null) return dayjs().isoWeek();
  const weekNumber =
    typeof week === typeof 1 ? (week as number) : parseInt(week as string);
  if (isNaN(weekNumber) || weekNumber < 1 || weekNumber > 52)
    return dayjs().isoWeek();
  return weekNumber;
};

/**
 * Returns a year based on either a string or a number representation
 * If year cannot be determined, then the current year is provided
 * @param year
 */
export const getYearOrCurrent = (year: string | null | number) => {
  if (year === null) return dayjs().year();
  const yearNumber =
    typeof year === typeof 1 ? (year as number) : parseInt(year as string);
  if (isNaN(yearNumber) || yearNumber < 2022) return dayjs().year();
  return yearNumber;
};

/**
 *
 * @param date Round up date to its nearest whole number
 */
export const roundUpToNextWholeMinute = (date: Date) => {
  if (new Date(date).getSeconds() === 0) return new Date(date);


  let ms = 1000 * 60;
  let roundedDate = new Date(Math.ceil(new Date(date).getTime() / ms) * ms);

  return roundedDate;
};
