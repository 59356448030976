import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { DeviceInstallation } from "../../models";
import { DetailedDevice } from "../../models/detailedDevice.model";
import { RoomModel } from "../../models/locations.model";
import { useMemo, useState } from "react";

type props = {
  deviceInstallation: DeviceInstallation;
  device: DetailedDevice;
  room: RoomModel | null;
  onConfirmed: () => void
};

const OverviewStep = ({ device, deviceInstallation, room, onConfirmed }: props) => {
  const labels = [
    <Typography>You are at {deviceInstallation.tenant.name}</Typography>,
    <Typography>
      The device is <b>{device.serialNumber}</b>
    </Typography>,
    <Typography>
      {" "}
      The device is{" "}
      {device.status.runsOnBattery ? <b>on battery</b> : <b>corded</b>}
    </Typography>,
    <Typography>
      {" "}
      The device is now physically installed in room <b>{room?.name}</b> on
      floor <b>{room?.floor?.name}</b>
    </Typography>,
      <Typography>
      {" "}
      Fluid level is {device.fluid.percentLeft} %
    </Typography>,
  ];

  const [checked, setChecked] = useState<boolean[]>(
    new Array(labels.length).fill(false)
  );

  const confirmDisabled = useMemo(() => checked.some((ch) => !ch), [checked]);

  const updateChecked = (ind: number, value: boolean) =>
    setChecked((currentChecked) => {
      currentChecked[ind] = value;
      return [...currentChecked];
    });


    const handelConfirm = onConfirmed

  return (
    <>
      <Grid
        container
        marginX={2}
        flexDirection={"column"}
        alignContent={"space-between"}
      >
        <Grid item>Ensure that all of these are correct:</Grid>
        <Grid item padding={1}>
          <FormGroup>
            {labels.map((label, ind) => {
              const handleCheckboxChanged = (
                _: React.SyntheticEvent<Element, Event>,
                checked: boolean
              ) => updateChecked(ind, checked);

              return (
                <FormControlLabel
                  key={ind}
                  value={checked[ind]}
                  onChange={handleCheckboxChanged}
                  control={<Checkbox />}
                  label={label}
                />
              );
            })}
          </FormGroup>
        </Grid>
        <Grid item alignSelf={"center"} marginTop={2}>
          <Button variant="outlined" disabled={confirmDisabled} onClick={handelConfirm}>
            Confirm installation
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default OverviewStep;
