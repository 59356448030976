import { Theme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

export const useIsMobile = () =>
  useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

export const useIsTablet = () =>
  useMediaQuery((theme: Theme) => theme.breakpoints.between("sm", "md"), {
    noSsr: true,
  });

export const useIsSmallScreen = () => {
  const mobile = useIsMobile();

  const tablet = useIsTablet();

  return mobile || tablet;
};

export const useIsMediumScreen = () => {
  const mobile = useIsMobile();

  const tablet = useIsTablet();

  const med = useMediaQuery(
    (theme: Theme) => theme.breakpoints.between("md", "lg"),
    {
      noSsr: true,
    }
  );

  return mobile || tablet || med;
};

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};
