import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  useAssignTenantToAdminDevice,
} from "../../hooks/admin-device/assignAdminDeviceToTenantHook";
import { assignmentState } from "../../hooks/admin-device/assignmentStateTypes";
import FormStepper from "./FormStepper";
import ChooseTenantStep from "./ChooseTenantStep";
import AssignmentStep from "./AssignmentStep";
import { DetailedDevice } from "../../models/detailedDevice.model";


type props = {
  open: boolean;
  onClose: () => void;
  onAssignment: (newTenantIdentifier: string) => void;
  device: DetailedDevice;
};

const AdminDeviceAssignmentDialog = ({
  open,
  onAssignment,
  onClose,
  device,
}: props) => {
  const {
    assignmentState,
    handleClose,
    handleTenantChosen,
    handleBack,
    handleCancel,
    handleConfirm,
    setRoom,
    saveState,
  } = useAssignTenantToAdminDevice({ onClose, onAssignment, device });

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>
          <b>{device.serialNumber}</b>
        </DialogTitle>
        <DialogContent sx={{ height: 400 }}>
          {assignmentState.step === 0 && (
            <ChooseTenantStep
              assignmentState={assignmentState}
              onTenantChosen={handleTenantChosen}
              disabledTenantId={device.tenant?.identifier}
            />
          )}
          {assignmentState.step === 1 && (
            <AssignmentStep
              assignmentState={assignmentState}
              saveState={saveState}
              setRoom={setRoom}
            />
          )}
        </DialogContent>
        <FormStepper assignmentState={assignmentState} />

        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            color="inherit"
            disabled={0 === assignmentState.step}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>

          {saveState === "loading" ? (
            <CircularProgress />
          ) : (
            <Button
              color="inherit"
              onClick={handleConfirm}
              disabled={!assignmentState.canConfirm}
              sx={{ mr: 1 }}
            >
              Confirm
            </Button>
          )}

          <Button color="inherit" onClick={handleCancel} sx={{ mr: 1 }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminDeviceAssignmentDialog;

export interface AssignDeviceStepProps {
  assignmentState: assignmentState;
}
