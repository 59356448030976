import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { useTenant } from "../../hooks/tenant";
import { RootState } from "../../store/reducers";
import TenantNotFound from "./TenantNotFound";

const TenantAsserter = () => {
  const { tenantIdentifier } = useParams();
  const tenant = useTenant();
  const tenantFetchState = useSelector(
    (state: RootState) => state.tenantReducer.fetchState
  );
  return (
    <>
      {tenant && <Outlet />}
      {tenantFetchState === "error" && (
        <TenantNotFound tenantId={tenantIdentifier ?? ""} />
      )}
    </>
  );
};

export default TenantAsserter;
