import { Typography } from '@mui/material'
import React from 'react'

type props =  {children: React.ReactNode}

const Title = ({children}:props) => {
  return  <Typography variant="h1" textAlign={"center"} marginTop={3}>
{children}
  </Typography>
}

export default Title