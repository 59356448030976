import { Grid, Skeleton, Typography } from "@mui/material";
import { useMemo } from "react";
import FloorPlanTextEditor from "../../components/floorplan-text-editor/FloorPlanTextEditor";
import { useFloorPlanManager } from "../../hooks/floor-plan-hooks/floorPlanManager";

const FloorPlanView = () => {
  var floorPlanManager = useFloorPlanManager();
  var entity = useMemo(() => floorPlanManager.entity, [floorPlanManager]);

  return (
    <>

      <Typography variant="h1" textAlign={"center"}>
        Floor-plan for <b>{entity && entity.name}</b>
      </Typography>
      {floorPlanManager.loadingFloorPlan? (
        <Grid container flexDirection={"column"} alignContent="center" marginTop={10}>
          <Skeleton variant="rectangular" height={360} width={1200} animation="wave"/>
        </Grid>
      ) : (
        <FloorPlanTextEditor floorPlanManager={floorPlanManager} />
      )}
    </>
  );
};

export default FloorPlanView;
