import axios, { AxiosError } from "axios";
import {
  NewUser,
  StaticScreenUser,
  UpdateUserDto,
  User,
} from "../../../../models/users.model";
import {
  PagedResponse,
  PaginationQuery,
  API_URL,
  repeatedArrayFormat,
} from "../../../api";

export const getUsers = async (
  paginationQuery: PaginationQuery,
  searchTerm?: string,
  groups?: string[]
): Promise<PagedResponse<User> | null> => {
  try {
    let params: { [key: string]: any } = { term: searchTerm };

    if (!!groups) {
      params["groups"] = groups;
    }

    let response = await axios.get(paginationQuery.toUrl("/users", API_URL), {
      params,
      paramsSerializer: repeatedArrayFormat,
    });
    return response.data as PagedResponse<User>;
  } catch (error) {
    return null;
  }
};

export const getUserById = async (userId: string): Promise<User | null> => {
  try {
    let response = await axios.get(`${API_URL}/users/${userId}`);
    return response.data as User;
  } catch (error) {
    return null;
  }
};

export const deleteUser = async (userId: string): Promise<number> => {
  try {
    let response = await axios.delete(`${API_URL}/users/${userId}`);
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const updateUser = async (
  userId: string,
  updateUserDto: UpdateUserDto
): Promise<User | null> => {
  try {
    let response = await axios.put(`${API_URL}/users/${userId}`, updateUserDto);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const inviteUser = async (user: NewUser): Promise<number> => {
  try {
    var response = await axios.post(`${API_URL}/users`, user);
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};


export const createScreenUser = async (user: StaticScreenUser): Promise<number> => {
  try {
    var response = await axios.post(`${API_URL}/screen-users`, user);
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};


export const getScreenUsers = async (
  paginationQuery: PaginationQuery,
): Promise<PagedResponse<User> | null> => {
  try {
    let response = await axios.get(paginationQuery.toUrl("/screen-users", API_URL));
    return response.data as PagedResponse<User>;
  } catch (error) {
    return null;
  }
};