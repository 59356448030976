import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
} from "@mui/material";
import { RoomModel } from "../../models/locations.model";
import RoomsDisplay from "../rooms-display/RoomsDisplay";
import { useState } from "react";

type props = {
  room: RoomModel | null;
  handleRoomChosen: (room: RoomModel | null) => void;
  onConfirm: (room: RoomModel) => void;
};

const InstallLocationSelector = ({
  room: roomIn,
  handleRoomChosen,
  onConfirm,
}: props) => {
  const [room, setRoom] = useState<RoomModel | null>(roomIn);
  const [showList, setShowList] = useState(room === null);



  const onButtonClicked = () => {
    setShowList((showList) => !showList);
    handleRoomChosen(null);
  };

  const roomChosen = (room: RoomModel) => {
    setShowList(false);
    setRoom(room);
    handleRoomChosen(room);
    onConfirm(room);
  };

  return (
    <>
      {showList ? (
        <RoomsDisplay handleRoomChosen={roomChosen} />
      ) : (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name of room </TableCell>
                <TableCell align="center">
                  <b>{room?.name}</b>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Type of room</TableCell>
                <TableCell align="center">{room?.type.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Floor</TableCell>
                <TableCell align="center">{room?.floor.name}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid container justifyContent="center" marginTop={2}>
            <Button onClick={onButtonClicked} variant="outlined">
              Choose different room
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default InstallLocationSelector;
