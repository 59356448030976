import {
  Alert,
  Grid,
} from "@mui/material";
import { RoomModel } from "../../models/locations.model";
import AddDeviceContent from "../add-device/AddDeviceContent";
import { AssignDeviceStepProps } from "./AdminDeviceAssignmentDialog";
import { saveStateType } from "../../models/types";

interface AssignmentStepProps extends AssignDeviceStepProps {
  setRoom: (room: RoomModel | null) => void;
  saveState: saveStateType;
}

const AssignmentStep = ({
  assignmentState,
  setRoom,
  saveState,
}: AssignmentStepProps) => {

  return (
    <Grid
      container
      justifyContent="space-between"
      flexDirection={"column"}
      alignContent="center"
    >
          {/* Create a new device */}
          <AddDeviceContent
            tenantName={assignmentState.tenant?.name ?? ""}
            onRoomChosen={setRoom}
          />

          {saveState === "conflict" && (
            <Alert severity="error" sx={{ fontSize: "1.5rem" }}>
              Device with name <b>{assignmentState.device?.serialNumber}</b> already
              exists.
            </Alert>
          )}

          {saveState === "error" && (
            <Alert severity="error" sx={{ fontSize: "1.5rem" }}>
              Unable to create device.
            </Alert>
          )}

    </Grid>
  );
};

export default AssignmentStep;
