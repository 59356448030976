import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";

import Breadcrumb from "../components/breadcrumb/FloorPlanBreadcrumbs";
import FotaBreadcrumb from "../components/breadcrumb/FotaBreadcrumb";
import { Header } from "../components/header/Header";
import TenantAsserter from "../components/tenant-asserter/TenantAsserter";
import AdminDeviceView from "../views/admin-device/AdminDeviceView";
import FirmwareView from "../views/firmware-view/DeviceFirmwareView";
import UpdateFirmwareView from "../views/firmware-view/UpdateFirmwareView";
import FloorPlanView from "../views/floorplan/FloorPlanView";
import TenantLoadingPage from "../views/tenant-loading-page/TenantLoadingPage";
import PremiseView from "../views/premise-view/PremiseView";
import RoomView from "../views/room-view/RoomView";
import SectionView from "../views/section-view/SectionView";
import InviteUserView from "../views/users/InviteUserView";
import UsersView from "../views/users/UsersView";
import UserView from "../views/users/UserView";

import { PrivateRoute } from "./PrivateRoute";
import DeviceAsserter from "../components/tenant-asserter/device-asserter/DeviceAsserter";
import FloorView from "../views/floor-view/FloorView";
import InstallDeviceView from "../views/installation-view/InstallDeviceView";
import ScreenUserView from "../views/users/ScreenUserView";
import AdminDeviceProvisioning from "../views/admin-device/AdminDeviceProvisioning";
import ProvisionDevicesForm from "../components/provision-devices/ProvisionDevicesForm";
import AdminDeviceProvisionResults from "../views/admin-device/AdminDeviceProvisionResults";

const LandingPage = lazy(() => import("../views/landing-page/LandingPage"));
const FotaView = lazy(() => import("../views/fota/FotaView"));
const DashboardView = lazy(() => import("../views/dashboard/DashboardView"));
const DeviceView = lazy(() => import("../views/device-view/DeviceView"));
const MaintenanceView = lazy(
  () => import("../views/maintenance-view/MaintenanceView")
);
const LocationView = lazy(() => import("../views/location-view/LocationView"));
const AdminDeviceDisplayView = lazy(
  () => import("../views/admin-device/AdminDeviceDisplayView")
);
const InstallationView = lazy(
  () => import("../views/installation-view/InstallationView")
);

const TenantView = lazy(() => import("../views/tenant-view/TenantView"));
const OnPremLanderView = lazy(() => import("../views/on-prem-lander-view/OnPremLanderView"));

const PrivateRoutes = () => {
  return (
    <PrivateRoute>
      <Routes>
        <Route path="/" element={<Header color={"primary"} />}>
          <Route index element={<TenantLoadingPage />} />

          <Route
            path="/lander"
            element={
              <Suspense fallback={<></>}>
                <LandingPage />
              </Suspense>
            }
          />

          <Route
            path="/tenants"
            element={
              <Suspense fallback={<></>}>
                <TenantView />
              </Suspense>
            }
          />

          <Route
            path="/on-prem"
            element={
              <Suspense fallback={<></>}>
                <OnPremLanderView />
              </Suspense>
            }
          />

          <Route path=":tenantIdentifier" element={<TenantAsserter />}>
            <Route
              path="dashboard"
              element={
                <Suspense fallback={<></>}>
                  <DashboardView />
                </Suspense>
              }
            />
            <Route path="installations">
              <Route
                index
                element={
                  <Suspense fallback={<></>}>
                    <InstallationView />
                  </Suspense>
                }
              />

              <Route path=":serialNumber" element={<InstallDeviceView />} />
            </Route>

            <Route
              path="maintenance"
              element={
                <Suspense fallback={<></>}>
                  <MaintenanceView />
                </Suspense>
              }
            />
            <Route path="sections" element={<Breadcrumb />}>
              <Route
                index
                element={
                  <Suspense fallback={<></>}>
                    <LocationView />
                  </Suspense>
                }
              />
              <Route path=":sectionId">
                <Route index element={<SectionView />} />
                <Route path="premises">
                  <Route path=":premiseId">
                    <Route index element={<PremiseView />} />
                    <Route path="floors">
                      <Route path=":floorId">
                        <Route index element={<FloorView />} />
                        <Route path="floor-plans" element={<FloorPlanView />} />
                        <Route path="rooms">
                          <Route path=":roomId">
                            <Route index element={<RoomView />} />
                            <Route
                              path="floor-plans"
                              element={<FloorPlanView />}
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="device" element={<DeviceAsserter />}>
              <Route
                path=":deviceId"
                element={
                  <Suspense fallback={<></>}>
                    <DeviceView />
                  </Suspense>
                }
              />
            </Route>
            <Route path="users">
              <Route index element={<UsersView />} />
              <Route path={"invite"} element={<InviteUserView />} />
              <Route path={"screen"} element={<ScreenUserView />} />
              <Route path={":userId"} element={<UserView />} />
            </Route>
          </Route>

          <Route path="fota" element={<FotaBreadcrumb />}>
            <Route
              index
              element={
                <Suspense fallback={<></>}>
                  <FotaView />
                </Suspense>
              }
            />
            <Route path=":serialNumber">
              <Route index element={<FirmwareView />} />
              <Route path=":firmwareType" element={<UpdateFirmwareView />} />
            </Route>
          </Route>
          <Route path="devices">
            <Route
              index
              element={
                <Suspense fallback={<></>}>
                  <AdminDeviceDisplayView />
                </Suspense>
              }
            />
            <Route path="provision" element={<AdminDeviceProvisioning/>}> 
            
            <Route path="form" element={<ProvisionDevicesForm/>}/> 
            <Route path="results" element={<AdminDeviceProvisionResults/>}/> 

            </Route>
            <Route path=":adminDeviceId" element={<AdminDeviceView />} />
          </Route>
        </Route>
      </Routes>
    </PrivateRoute>
  );
};

export default PrivateRoutes;
