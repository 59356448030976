import TextField from "@mui/material/TextField";
import React from "react";

type props = {
  onInputChange: (newInputValue: string) => void;
  onInputValueEmpty: () => void;
  onFocus: () => void;
  label: string;
};

const SearchBar = ({
  onInputChange,
  onInputValueEmpty,
  onFocus,
  label,
}: props) => {
  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onInputChange(event.target.value);
    if (event.target.value === "" || event.target.value === null)
      onInputValueEmpty();
  };

  return (
    <TextField
      color="secondary"
      fullWidth
      onFocus={onFocus}
      label={label}
      onChange={onChange}
    />
  );
};
export default SearchBar;
