import {
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useState } from "react";
import { User } from "../../models/users.model";
import { Button } from "../buttons/Button";
import { default as MuiButton } from "@mui/material/Button";
import { useTenant } from "../../hooks/tenant";

type props = {
  user: User;
  onDelete: () => void;
  disabled: boolean
};

const DeleteUserButton = ({ onDelete, user, disabled }: props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const tenant = useTenant();
  const handleDeleteClicked = () => {
    setDialogOpen(true);
  };

  const handleClose = () => setDialogOpen(false);

  return (
    <>
      <Button onClick={handleDeleteClicked} variant={'warning'} disabled={disabled}>Delete user</Button>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogContent>
          Delete user {user.firstName} {user.lastName} from{" "}<b>{tenant?.name}</b>?
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete}>Confirm</Button>
          <MuiButton onClick={handleClose}>Cancel</MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUserButton;
