import { AnyAction, Reducer } from "redux";
import { entityEnum } from "../../hooks/floor-plan-hooks/floorPlanHookTypes";
import {
  FloorModel,
  PremiseModel,
  SectionModel,
  RoomModel,
} from "../../models/locations.model";
import {
  RESET_LOCATION_OBJECTS,
  SET_PREMISE_LOCATION_OBJECT,
  SET_ROOM_LOCATION_OBJECT,
  SET_SECTION_LOCATION_OBJECT,SET_FLOOR_LOCATION_OBJECT
} from "../actions/actionTypes";

type locationObjectsStateType = {
  premise: PremiseModel | null;
  section: SectionModel | null;
  room: RoomModel | null;
  floor: FloorModel | null;
  outerMostLocationObjectType: entityEnum;
};

const initialState: locationObjectsStateType = {
  premise: null,
  section: null,
  room: null,
  floor: null,
  outerMostLocationObjectType: entityEnum.nullEntity,
};

const locationObjectsReducer: Reducer<locationObjectsStateType, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_PREMISE_LOCATION_OBJECT:
      return {
        ...state,
        premise: action.payload,
        outerMostLocationObjectType: entityEnum.PremiseModel,
      };
    case SET_SECTION_LOCATION_OBJECT:
      return {
        ...state,
        section: action.payload,
        outerMostLocationObjectType: entityEnum.SectionModel,
      };
      case SET_FLOOR_LOCATION_OBJECT:
        return {
          ...state,
          floor: action.payload,
          outerMostLocationObjectType: entityEnum.FloorModel,
        };
    case SET_ROOM_LOCATION_OBJECT:
      return {
        ...state,
        room: action.payload,
        outerMostLocationObjectType: entityEnum.RoomModel,
      };
    case RESET_LOCATION_OBJECTS:
      return initialState;
    default:
      return state;
  }
};

export default locationObjectsReducer;
