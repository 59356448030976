import {Action, applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {rootReducer, RootState} from "./reducers";
import thunkMiddleware, { ThunkAction } from 'redux-thunk'

const store = createStore(
    rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware))
);


export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>


export default store;

export type AppDispatch = typeof store.dispatch

export type StoreType = typeof store;
