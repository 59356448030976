import { useCallback, useEffect, useState } from "react";
import { Firmware } from "../../models";
import { fetchStateType } from "../../models/types";
import { getFirmware } from "../../utilities/axios/admin-api-calls/adminCalls";



export const useDeviceFirmware = (serialNumber: string | undefined) => 
{
    const [fetchState, setFetchState] = useState<fetchStateType>("default");
    const [firmware, setFirmware] = useState<Firmware | null>(null);

    const fetchFirmware = useCallback(async () => {
        if (!serialNumber) return;
        setFetchState("loading");
        var res = await getFirmware(serialNumber);
    
        if (res) setFetchState("fetched");
        else setFetchState("error");
        setFirmware(res);
      }, [serialNumber]);
    
      useEffect(() => {
        if (fetchState === "default") fetchFirmware();
      }, [fetchState, firmware, fetchFirmware]);


      return {
        fetchState,
        firmware
      }
}