import { useCallback } from "react";
import { getRooms } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";

import { PaginationQuery } from "../../utilities/api";
import ItemDisplay from "../display/ItemDisplay";
import { RoomModel } from "../../models/locations.model";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import { Filter } from "../device-filter/DeviceFilter";
import { Grid } from "@mui/material";
import { useIsMobile } from "../../hooks/media-hooks/mediaHooks";
import { ItemComponentProps } from "../item-lists/ItemList";

type props = {
  handleRoomChosen: (room: RoomModel) => void;
  disabledRoomId?: string | null;
};

const RoomsDisplay = ({ handleRoomChosen, disabledRoomId }: props) => {
  const mobile = useIsMobile()
  const getRoomsDisplaysPaginationQuery = useCallback(
    (paginationQuery: PaginationQuery, filter: Filter) =>
      getRooms(paginationQuery, filter.searchTerm),
    []
  );

  const handleRoomAsItemChoosen = (room: Item) => {
    handleRoomChosen(room as RoomModel);
  };

  const RoomDisplayItem = (props: ItemComponentProps) => {
    let room = props.model as RoomModel;
    return (
      <Grid container justifyContent={"space-between"} minWidth={"inherit"}>
        <Grid item>{room.name}</Grid>
        <Grid
          item
          container
          xs={mobile ? 6: 4}
          justifyContent={"space-between"}
          sx={{
            align: "right",
            fontSize: "small",
            color: "gray",
            textAlign: "center",
          }}
        >
          <Grid item>{room.type.name}</Grid> <Grid item>{room.floor.name}</Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <ItemDisplay
      itemName="rooms"
      getItems={getRoomsDisplaysPaginationQuery}
      labelProperty={"name"}
      disabledItemId={disabledRoomId}
      handleItemChosen={handleRoomAsItemChoosen}
      itemComponent={RoomDisplayItem}
    />
  );
};

export default RoomsDisplay;
