import { AnyAction, Reducer } from "redux";
import { Tenant } from "../../models";
import { fetchStateType, saveStateType } from "../../models/types";
import {
  SET_MAINTENANCE_TENANT,
  RESET_MAINTENANCE_STATE,
  SET_MAINTENANCE_ACTIVE,
  SET_MAINTENANCE_ACTIVE_FETCH_STATE,
  SET_MAINTENANCE_ACTIVE_SAVE_STATE,
  SET_MAINTENANCE_ID,
  SET_MAINTENANCE_SERVICE_TENANT,
  SET_MAINTENANCE_SERVICE_TENANT_FETCHSTATE,
} from "../actions/actionTypes";

type maintananceStateType = {
  fetchState: fetchStateType;
  maintenanceModeActive: boolean;
  tenant: Tenant | null;
  saveState: saveStateType;
  maintenanceId: string | null;
  serviceTenant: {
    tenant: Tenant | null;
    fetchState: fetchStateType;
  };
};

const initialState: maintananceStateType = {
  maintenanceModeActive: false,
  fetchState: "default",
  tenant: null,
  saveState: "default",
  maintenanceId: null,
  serviceTenant: {
    tenant: null,
    fetchState: "default",
  },
};

const maintenanceReducer: Reducer<maintananceStateType, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_MAINTENANCE_ACTIVE_FETCH_STATE:
      return {
        ...state,
        fetchState: action.payload,
      };
    case SET_MAINTENANCE_ACTIVE_SAVE_STATE:
      return {
        ...state,
        saveState: action.payload,
      };
    case SET_MAINTENANCE_ACTIVE:
      return {
        ...state,
        maintenanceModeActive: action.payload,
      };
    case SET_MAINTENANCE_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };
    case SET_MAINTENANCE_ID:
      return {
        ...state,
        maintenanceId: action.payload,
      };

    case SET_MAINTENANCE_SERVICE_TENANT:
      return {
        ...state,
        serviceTenant: { ...state.serviceTenant, tenant: action.payload },
      };
    case SET_MAINTENANCE_SERVICE_TENANT_FETCHSTATE:
      return {
        ...state,
        serviceTenant: { ...state.serviceTenant, fetchState: action.payload },
      };
    case RESET_MAINTENANCE_STATE:
      return initialState;
    default:
      return state;
  }
};

export default maintenanceReducer;
