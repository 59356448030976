import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/Button";
import Title from "../../components/title/Title";
import UsersDisplay from "../../components/users-display/UsersDisplay";

const UsersView = () => {
  const navigate = useNavigate();

  return (
    <>
      <Title>Users</Title>
      <Grid container justifyContent="center">
        <Grid item width={400} margin={10}>
          <UsersDisplay />
        </Grid>
        <Grid item marginTop={10}>
          <Grid container flexDirection={"column"} spacing={2} alignContent={"center"}>
            <Grid item>
              <Button onClick={() => navigate("invite")}>
                Invite new user
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => navigate("screen")}>Add screen user</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UsersView;
