import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Crumb } from "../../components/breadcrumb/BreadCrumbs";
import { RootState } from "../../store/reducers";
import { useIdsFromPath, useObjectsFromPath } from "./locationHelperHooks";

type useGetLocationalObjectsType = () => Crumb[];

export const useGetLocationalBreadcrumb: useGetLocationalObjectsType = () => {
  const tenantId = useSelector(
    (state: RootState) => state.tenantReducer.tenant?.identifier
  );

  const location = useLocation();

  const ids = useIdsFromPath();
  const { premise, section, room, floor } = useObjectsFromPath();
  const {
    premiseId: pathPremiseId,
    sectionId: pathSectionId,
    floorId: pathFloorId,
    roomId: pathRoomId,
  } = useIdsFromPath();
  const isFloorPlan = useMemo(
    () => location.pathname.includes("floor-plans"),
    [location]
  );
  const pathPrefix = useMemo(() => `/${tenantId}`, [tenantId]);

  const objectCrumbs = useMemo(() => {
    let newCrumbs: Crumb[] = [];
    newCrumbs.push({
      path: `${pathPrefix}/sections`,
      name: "Sections",
      link: true,
      loading: false,
      preface: null
      
    });

    if (ids.sectionId) {
      newCrumbs.push({
        path: section
          ? `${pathPrefix}/sections/${section.id}`
          : `${pathPrefix}/sections`,
        name: section ? section.name : "Section",
        link: true,
        loading: !section || section.id !== pathSectionId,
        preface: "Section"
      });
    }

    if (ids.premiseId) {
      newCrumbs.push({
        path: premise
          ? `${pathPrefix}/sections/${section?.id}/premises/${premise.id}`
          : `${pathPrefix}/sections/${section?.id}/premises/`,
        name: premise ? premise.name : "Premise",
        link: true,
        loading: !premise || premise.id !== pathPremiseId,
      preface: "Premise"

      });
    }

    if (ids.floorId) {
      newCrumbs.push({
        path: floor
          ? `${pathPrefix}/sections/${section?.id}/premises/${premise?.id}/floors/${floor.id}`
          : `${pathPrefix}/sections/${section?.id}/premises/${premise?.id}/floors`,
        name: floor ? floor.name : "Floor",
        link: true,
        loading: !floor || floor.id !== pathFloorId,
      preface: "Floor"
      });
    }

    if (ids.roomId) {
      newCrumbs.push({
        path: room
          ? `${pathPrefix}/sections/${section?.id}/premises/${premise?.id}/floors/${floor?.id}/rooms/${room.id}`
          : `${pathPrefix}/sections/${section?.id}/premises/${premise?.id}/floors/${floor?.id}/rooms/`,
        name: room ? room.name : "rooms",
        link: true,
        loading: !room || room.id !== pathRoomId,
      preface: "Room"
      });
    }

    return newCrumbs;
  }, [
    premise,
    section,
    floor,
    room,
    ids,
    pathPrefix,
    pathPremiseId,
    pathSectionId,
    pathFloorId,
    pathRoomId,
  ]);

  const [crumbs, setCrumbs] = useState<Crumb[]>(objectCrumbs);

  useEffect(() => {
    let c = objectCrumbs;
    if (isFloorPlan) {
      c.push({
        path: `${c[c.length - 1].path}/floor-plans`,
        name: "Floor-plan",
        link: false,
        loading: false,
        preface: null

      });
      setCrumbs(c);
    } else {
      setCrumbs(c);
    }
  }, [objectCrumbs, isFloorPlan]);

  return crumbs;
};

type useGetFotaBreadcrumbType = (root: {
  path: string;
  name: string;
}) => Crumb[];

export const useGetFotaBreadcrumb: useGetFotaBreadcrumbType = (root) => {
  const { serialNumber, firmwareType } = useParams();
  const crumbs: Crumb[] = useMemo(() => {
    let newCrumbs: Crumb[] = [];
    newCrumbs.push({
      path: root.path,
      name: root.name,
      link: true,
      loading: false,
      preface: null
    });

    if (!serialNumber) return newCrumbs;

    newCrumbs.push({
      path: serialNumber,
      name: serialNumber,
      link: true,
      loading: false,
      preface: null

    });

    if (!firmwareType) return newCrumbs;

    newCrumbs.push({
      path: `${serialNumber}/${firmwareType}`,
      name: firmwareType,
      link: true,
      loading: false,
      preface: null

    });
    return newCrumbs;
  }, [root, serialNumber, firmwareType]);

  return crumbs;
};
