import { AnyAction, Reducer } from "redux";
import {
  SET_CURRENT_GROUP_LOCKED,
  SET_CURRENT_TENANT_GROUP_ID,
  SET_SIGNALR_FETCH_STATE,
} from "../actions/actionTypes";
import { fetchStateType } from "../../models/types";

type signalRStateType = {
  connectionIdFetchState: fetchStateType;
  currentTenantGroup: string | null
  groupIsLocked: boolean
};

const initialState: signalRStateType = {
  connectionIdFetchState: "default",
  currentTenantGroup: null,
  groupIsLocked: false
};

const signalRStateReducer: Reducer<signalRStateType, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_SIGNALR_FETCH_STATE:
      return { ...state, connectionIdFetchState: action.payload };
      case SET_CURRENT_TENANT_GROUP_ID:
      return {...state, currentTenantGroup: action.payload}
      case SET_CURRENT_GROUP_LOCKED:
      return {...state, groupIsLocked: action.payload}

    default:
      return state;
  }
};

export default signalRStateReducer;
