import {
    BluetoothEvent,
    BluetoothEventType,
    DeviceEventCollection,
    EventType,
    ReceivedEvent,
    TransactionEvent,
    UNKNOWN_EVENT,
    BaseMessage,
    BaseEventApiDto,
    DeviceEvent,
    TransactionEventType,
  } from "../../models/deviceEvents.model";
import { IPaginationQuery } from "../api";
  import { getLatestDeviceEvents } from "./../axios/admin-api-calls/tenant-calls";
  import { getEventColor } from "./../events/eventColors";
  import {
    asQueryableEventTypeList,
    filterIsOn,
    objIsBluetoothEvent,
    objIsTransactionEvent,
    objIsDeviceEvent,
  } from "./eventUtils";
export const arraySizeMaintainer = (array: any[], length: number) => {
    if (array.length > length) {
      array = array.slice(0, length);
    }
  
    return array;
  };
  
  export interface SortedApiEvents {
    events: ReceivedEvent[];
    loading: boolean;
  }
  
  export const getSortedLatestEventsFromApi = async (
    paginationQuery: IPaginationQuery,
    typesIncluded?: EventType[],
    bluetoothFilter?: boolean,
    transactionFilter?: boolean,
    summaryFilter?: boolean
  ): Promise<ReceivedEvent[] | null> => {
    let eventTypeNumbers = asQueryableEventTypeList(typesIncluded);
    if (!filterIsOn) {
      eventTypeNumbers = undefined;
    }
  
    const apiData = await getLatestDeviceEvents(
      paginationQuery,
      eventTypeNumbers,
      bluetoothFilter,
      transactionFilter,
      summaryFilter
    );
  
    if (!apiData) return null;
  
    let mergedAndSorted = mergeAndSortDeviceEventCollection(apiData.items[0]);
  
  
    return mergedAndSorted;
  };
  
  export function mergeAndSortDeviceEventCollection(
    deviceEventCollection: DeviceEventCollection
  ): ReceivedEvent[] {
    let merged: BaseMessage[] = [
      ...deviceEventCollection.deviceTransactions,
      ...deviceEventCollection.deviceEvents,
      ...deviceEventCollection.bluetoothEvents,
    ];
    merged.sort(function (x, y) {
      let yDate = new Date(y.timestamp);
      let xDate = new Date(x.timestamp);
      return yDate.valueOf() - xDate.valueOf();
    });
  
    return merged.map(createReceivedEvent);
  }
  
  function createReceivedEvent(event: BaseMessage): ReceivedEvent {
    if (objIsDeviceEvent(event)) return createReceivedEventFromBaseEvent(event);
  
    if (objIsBluetoothEvent(event))
      return createReceivedEventFromBluetooth(event);
  
    if (objIsTransactionEvent(event))
      return createReceivedEventFromTransaction(event);
  
    return createUnknownEventType(event)
  }
  
  
  
  
  
  function createReceivedEventFromBaseEvent(event: BaseMessage): ReceivedEvent {
    return {
      event: event as DeviceEvent,
      source: "database",
      color: getEventColor(
        (event as DeviceEvent as BaseEventApiDto).event ?? UNKNOWN_EVENT,
        event
      ),
      eventType: (event as DeviceEvent).event ?? UNKNOWN_EVENT,
      name: (event as DeviceEvent).event ?? UNKNOWN_EVENT,
    };
  }
  function createReceivedEventFromBluetooth(event: BaseMessage): ReceivedEvent {
    return {
      event: event as BluetoothEvent,
      source: "database",
      color: getEventColor(BluetoothEventType, event),
      eventType: BluetoothEventType,
      name: BluetoothEventType,
    };
  }
  
  function createReceivedEventFromTransaction(event: BaseMessage): ReceivedEvent {
    let transactionEvent = event as TransactionEvent;
    return {
      event: transactionEvent,
      source: "database",
      color: getEventColor(TransactionEventType, event),
      eventType: TransactionEventType,
      name: TransactionEventType,
    };
  }

  function createUnknownEventType(event: BaseMessage): ReceivedEvent {
    return {
      event: event as BaseMessage,
      source: "database",
      color: getEventColor(UNKNOWN_EVENT, event),
      eventType: UNKNOWN_EVENT,
      name: UNKNOWN_EVENT,
    };
  }
  
  
  