export const signalRFunctions = {
  device: {
    ReceiveTransactionEvent: "ReceiveTransactionEvent",
    ReceiveDeviceEvent: "ReceiveDeviceEvent",
    ReceiveBluetoothEvent: "ReceiveBluetoothEvent",
    ReceiveConfigEvent: "ReceiveConfigEvent",
  },
  tenant: {
    JoinTenantGroup: "JoinTenantGroup",
    LeaveTenantGroup: "LeaveTenantGroup",
  },
};
