import { Alert, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import FloorRoomsDisplay from "../../components/rooms-display/FloorRoomsDisplay";
import { useIdsFromPath } from "../../hooks/location-hooks/locationHelperHooks";
import { RootState } from "../../store/reducers";
import { useState } from "react";
import AddRoomButton from "../../components/add-room/AddRoomButton";
import { v4 as uuidv4 } from "uuid";
import FloorPlanButton from "../../components/floor-plan-button/FloorPlanButton";

const FloorView = () => {
  const { floorId } = useIdsFromPath();
  const [floorViewKey, setFloorViewKey] = useState(uuidv4());

  const floor = useSelector(
    (state: RootState) => state.locationObjectsReducer.floor
  );

  return (
    <div key={floorViewKey}>
      <Typography variant={"h1"} textAlign="center">
        {floor?.name}
      </Typography>
      <Grid container justifyContent="center">
        <Grid item width={400} margin={10}>
          {floorId ? (
            <FloorRoomsDisplay floorId={floorId} />
          ) : (
            <Alert severity="error">Could not read floorId from url</Alert>
          )}
        </Grid>
        <Grid item display="flex" margin={10}>
          <Grid
            container
            flexDirection="column"
            spacing={2}
            alignItems={"center"}
          >
            {floorId && (
              <Grid item>
                <AddRoomButton
                  onAdd={() => setFloorViewKey(uuidv4())}
                  floorId={floorId}
                />
              </Grid>
            )}
            <Grid item alignContent={"center"}>
              <FloorPlanButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FloorView;
