import {  FloorPlan, FloorPlanDto, FloorPlanMap } from "../../models";
import {
  PremiseModel,
  SectionModel,
  RoomModel,
  FloorModel
} from "../../models/locations.model";
import { saveStateType } from "../../models/types";

export enum entityEnum {
  PremiseModel = "PremiseModel",
  SectionModel = "SectionModel",
  RoomModel = "RoomModel",
  FloorModel = "FloorModel",
  nullEntity = "null",
}


export type EntityType = PremiseModel | SectionModel | RoomModel | FloorModel | null;

export interface FloorPlanManager {
  loadingEntity: boolean;
  entity: EntityType;
  entityType: entityEnum;
  friendlyEntityType: string;
  saveStatus: saveStateType;
  map: FloorPlanMap | null;
  setMap: (map: FloorPlanMap) => void;
  saveFloorPlan: () => void;
  floorPlan: FloorPlan | null;
  loadingFloorPlan: boolean
}

export type useFloorPlanManagerType =
  () => FloorPlanManager;

export interface UseFloorPlanCallBacksProps {
  setSaveStatus: (saveState: saveStateType) => void;
  createNew:boolean
}

export type saveFloorPlanType = (
  floorPlan: FloorPlanDto,
  entity: EntityType
) => void;

export type findEntityFunction = (entityReference: string) => void;

export interface FloorPlanCallBacks {
  saveFloorPlanForFloor: saveFloorPlanType;
  saveFloorPlanForRoom: saveFloorPlanType
}

export type useFloorPlanCallBacksType = (
  props: UseFloorPlanCallBacksProps
) => FloorPlanCallBacks;
