import { Autocomplete, TextField } from "@mui/material";
import { UserSection } from "../../models/users.model";
import { useMemo, useState } from "react";
import { useSections } from "../../hooks/location-hooks/sectionHooks";

type props = {
  sections: UserSection[];
  onSectionsChosen: (sections: UserSection[]) => void;
  disabled:boolean
};

const UserSectionComboBox = ({ sections, onSectionsChosen, disabled }: props) => {
  const [open, setOpen] = useState(false);

  const {
    sections: fetchedSections,
    setSearchString,
    fetchstate,
  } = useSections();

  const availableSections = useMemo(
    () => fetchedSections.map((fs) => fs as UserSection),
    [fetchedSections]
  );

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: UserSection[]
  ) => {
    onSectionsChosen(value);
  };

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchString(event.target.value);
  };

  return (
    <Autocomplete
      id="combo-box-roles"
      multiple
      disabled={disabled}
      filterSelectedOptions
      open={open}
      value={sections}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setSearchString(undefined);
        setOpen(false);
      }}
      onChange={handleChange}
      isOptionEqualToValue={(option: UserSection, value: UserSection) =>
        option.id === value.id
      }
      options={availableSections}
      loading={fetchstate === "loading"}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleTextInputChange}
          label="User Sections"
          //   error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {/* {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null} */}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default UserSectionComboBox;
