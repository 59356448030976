import { AppDispatch, AppThunk } from "..";
import { entityEnum } from "../../hooks/floor-plan-hooks/floorPlanHookTypes";
import { FloorPlan } from "../../models";
import { fetchStateType } from "../../models/types";
import { getFloorPlanForFloor, getFloorPlanForRoom } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import {
  RESET_FLOOR_PLAN,
  SET_FLOOR_PLAN,
  SET_FLOOR_PLAN_FETCH_STATE,
} from "./actionTypes";

export const floorPlanThunkGetter =
  (entityId: string, entityType: entityEnum): AppThunk =>
  async (dispatch: AppDispatch) => {
    
    dispatch(setFloorPlanFetchState("loading"));
    let floorPlan = null;
    switch (entityType) {
      case entityEnum.FloorModel:
        floorPlan = await getFloorPlanForFloor(entityId);
        break;
      case entityEnum.RoomModel:
        floorPlan = await getFloorPlanForRoom(entityId);
        break;
      default:
        break;
    }
    dispatch(setFloorPlanFetchState("fetched"));

    return dispatch(setFloorPlan(floorPlan));
  };

export const setFloorPlan = (floorPlan: FloorPlan | null) => {
  return {
    type: SET_FLOOR_PLAN,
    payload: floorPlan,
  };
};

export const resetFloorPlanState = () => {
  return {
    type: RESET_FLOOR_PLAN,
  };
};

export const setFloorPlanFetchState = (fetchState: fetchStateType) => {
  return {
    type: SET_FLOOR_PLAN_FETCH_STATE,
    payload: fetchState,
  };
};

