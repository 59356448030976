import { useCallback } from "react";
import { DeviceModel } from "../../models/detailedDevice.model";
import { PaginationQuery } from "../../utilities/api";
import { getDeviceModels } from "../../utilities/axios/admin-api-calls/adminCalls";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import ItemDisplay from "../display/ItemDisplay";
import { Filter } from "../device-filter/DeviceFilter";

type props = {
    onModelChosen: (model: DeviceModel) => void;
    disabledModelId?: string | null;
    width?: number | string;
    loadingComponent?: "circularProgress" | "skeleton" | "none";
  };


const ModelDisplay = ({onModelChosen, disabledModelId, width, loadingComponent}: props) => {
    const getModelsPaginationQuery = useCallback(
        (paginationQuery: PaginationQuery, filter: Filter) =>
          getDeviceModels(
            paginationQuery,
            filter.searchTerm
          ),
        []
      );
  
    const handleTenantChosen = (item: Item) => {
      let model = item as unknown as DeviceModel;
      onModelChosen(model);
    };
  
    return (
      <ItemDisplay
      searchdisabled
      disableDivideLastItem
      itemName="Models"
      width={width}
      getItems={getModelsPaginationQuery}
      labelProperty={"name"}
      handleItemChosen={handleTenantChosen}
      disabledItemId={disabledModelId}
      loadingComponent={loadingComponent}
      maxItemsPerPage={9}
      />
    );
  };
export default ModelDisplay