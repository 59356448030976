import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";

import Title from "../../components/title/Title";
import UserForm from "../../components/user/UserForm";
import { useUserPathObject } from "../../hooks/user-management-hooks/UserHook";
import { User } from "../../models/users.model";

const UserView = () => {
  const { user: userFromApi, fetchstate } = useUserPathObject();
const navigate = useNavigate()
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    setUser(userFromApi);
  }, [userFromApi]);

  const handleUserUpdated = (updatedUser:User)=> {

    if(updatedUser.tenant.identifier !== user?.tenant.identifier){
      navigate(`/${updatedUser.tenant.identifier}/users/${updatedUser.id}`)
    }
    setUser(updatedUser)
  }

  return (
    <>
      <Title>
        {user?.firstName} {user?.lastName}
      </Title>
      <Grid container flexDirection="column" alignContent="center">
        {fetchstate === "loading" || !user ? (
          <Grid item margin={15} flexDirection="column" alignContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Grid item marginTop={7}>
            <UserForm user={user} onUserUpdated={handleUserUpdated} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default UserView;
