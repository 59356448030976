import { useState, useMemo, useCallback } from "react";
import { CreateFluidDto } from "../../models";
import { saveStateType } from "../../models/types";
import { createFluid } from "../../utilities/axios/admin-api-calls/tenant-calls";
import { useTenant } from "../tenant";
import { RemainderModel } from "../../models/detailedDevice.model";

const numberRegex = /^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

export const useFluidTextFieldUpdate = (
  deviceId: string,
  fluid: number,
  onFluidChanged: (fluid: RemainderModel) => void
) => {
  const [saveState, setSaveState] = useState<saveStateType>("default");
  const [newFluid, setNewFluid] = useState(fluid ?? 0);
  const [input, setInput] = useState("");

  const tenant = useTenant();

  const inputError = useMemo(() => !input.match(numberRegex), [input]);
  const fluidValueInvalid = useMemo(() => {
    if (!newFluid) return inputError;
    return newFluid < 0 || 100 < newFluid || inputError;
  }, [newFluid, inputError]);

  const updateFluid = useCallback(
    async (newFluidValue?: number) => {
      if (!tenant) return;

      if (!newFluidValue) {
        newFluidValue = newFluid;
      }
      let status = { fluid: newFluidValue } as CreateFluidDto;

      let res = await createFluid(deviceId, tenant.identifier, status);

      if (!res) {
        setSaveState("error");
        return;
      }

      setSaveState("success");
      onFluidChanged(res);
    },
    [newFluid, tenant, deviceId, onFluidChanged]
  );

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInput(event.target.value);

    let inputAsNumber = parseFloat(event.target.value);

    setNewFluid(inputAsNumber);
  };

  return {
    input,
    newFluid,
    fluidValueInvalid,
    saveState,
    handleTextFieldChange,
    updateFluid,
  };
};
