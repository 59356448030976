import { combineReducers } from "redux";
import eventsReducer from "./eventReducer";
import tenantReducer from "./tenantReducer";
import locationObjectsReducer from "./locationObjects";
import floorPlanReducer from "./floorPlanReducer"
import detailedDeviceReducer from "./detailedDeviceReducer";
import maintenanceReducer from "./maintenanceReducer"
import userReducer from "./userReducer"
import signalrReducer from "./signalrReducer"

export const rootReducer = combineReducers({
  eventsReducer,
  tenantReducer,
  locationObjectsReducer,
  floorPlanReducer,
  detailedDeviceReducer,
  maintenanceReducer,
  userReducer,
  signalrReducer
});

export type RootState = ReturnType<typeof rootReducer>;
