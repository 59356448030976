import { KeycloakProfile } from "keycloak-js";
import { AnyAction, Reducer } from "redux";
import { SET_PROFILE, SET_USER_INFO} from "../actions/actionTypes";

type userStateType = {
profile: KeycloakProfile | null,
userInfo: KeycloakProfile | null

};

const initialState: userStateType = {
    profile: null,
userInfo: null
};

const maintenanceReducer: Reducer<userStateType, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
      case SET_USER_INFO:
        return {
          ...state,
          userInfo: action.payload,
        };
    default:
      return state;
  }
};

export default maintenanceReducer;
