import { useState, useMemo, useEffect } from "react";
import { Tenant } from "../../models";
import { RoomModel } from "../../models/locations.model";
import { createDevice as createDeviceApiCall } from "../../utilities/axios/admin-api-calls/tenant-calls";

type props = {
  serialNumber: string;
  onDeviceCreated: (deviceId: string) => void;
  onReset: () => void;
  tenant: Tenant | null;
  room: RoomModel | null;
};
export const useAddTenantDeviceManager = ({
  serialNumber,
  onDeviceCreated,
  room,
  onReset,
  tenant,
}: props) => {
  //todo: set this to empty string when temporarilyHideAssignmentFeatures is removed
  const [deviceName, setDeviceName] = useState(serialNumber);

  const [error, setError] = useState(false);
  const [nameTakenError, setNameTakenError] = useState(false);
  const canSubmit = useMemo(
    () => deviceName.length > 0 && room && !error,
    [deviceName, room, error]
  );
  const [isCreatingDevice, setIsCreatingDevice] = useState(false);

  useEffect(() => {
    setError(false);
    setIsCreatingDevice(false);
  }, [deviceName, room]);


  const createDevice = async () => {
    if (!room || !tenant) return;

    setIsCreatingDevice(true);    
    let responseStatus = await createDeviceApiCall(tenant.identifier, {
      serialNumber,
      id: deviceName,
      roomId: room.id,
    });

    setError(responseStatus >= 400);
    setNameTakenError(responseStatus === 409);
    if (200 <= responseStatus && responseStatus < 300) {
      onDeviceCreated(deviceName);
      reset();
    }
  };

  const reset = () => {
    setDeviceName("");
    setIsCreatingDevice(false);

    setError(false);
    onReset();
  };

  return {
    deviceName,
    setDeviceName,
    error,
    nameTakenError,
    canSubmit,
    isCreatingDevice,
    createDevice,
  };
};
