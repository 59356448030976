import React from 'react'
import { useGetFotaBreadcrumb } from '../../hooks/location-hooks/breadcrumbHooks'
import { FOTA_ROUTE } from '../../routing/routes'
import BreadCrumbs from './BreadCrumbs'


const FotaBreadcrumb = () => {
    const crumbs = useGetFotaBreadcrumb(FOTA_ROUTE)
  return <BreadCrumbs crumbs={crumbs}/>
}

export default FotaBreadcrumb