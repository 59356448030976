import {
  Grid,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useState, useCallback } from "react";
import { DetailedDevice } from "../../models/detailedDevice.model";
import { saveStateType } from "../../models/types";
import { updatePowerSource } from "../../utilities/axios/admin-api-calls/adminCalls";

type ChangePowerSourceProps = {
  device: DetailedDevice;
  onChange: () => void;
};
const ChangePowerSource = ({ device, onChange }: ChangePowerSourceProps) => {
  const [powerSupplySaveState, setPowerSupplySaveState] =
    useState<saveStateType>("default");

  const updatePowerSupply = useCallback(async () => {
    setPowerSupplySaveState("loading");
    let res = await updatePowerSource(device.serialNumber, {
      runsOnBattery: !device.status.runsOnBattery,
    });

    if (res > 204) {
      setPowerSupplySaveState("error");
      return;
    }
    setPowerSupplySaveState("default");
    onChange();
  }, [device, onChange]);

  return (
    <Grid
      container
      alignContent="center"
      justifyContent={"center"}
      margin={1}
      flexDirection="column"
    >
      {powerSupplySaveState === "default" && (
        <Button variant="outlined" onClick={updatePowerSupply}>
          Change power source to{" "}
          {device.status.runsOnBattery ? `Corded` : `Battery`}
        </Button>
      )}

      {powerSupplySaveState === "loading" && <CircularProgress />}
      {powerSupplySaveState === "error" && (
        <Alert severity="error">Unable to change powersupply</Alert>
      )}
    </Grid>
  );
};

export default ChangePowerSource;
