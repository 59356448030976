import {  KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import config  from './config';

/**
 * KeycloakConfig configures the connection to the Keycloak server.
 */
 export const keycloakConfig: KeycloakConfig = {
	realm: config.keycloak.realm,
	clientId: config.keycloak.resource,
	url: config.keycloak.authServerUrl,
  };
  

  /**
   * KeycloakInitOptions configures the Keycloak client.
   */
  export const keycloakInitOptions: KeycloakInitOptions = {
	// Configure that Keycloak will check if a user is already authenticated (when opening the app or reloading the page). If not authenticated the user will be send to the login form. If already authenticated the webapp will open.
	onLoad: "login-required",
  };
