import { BaseModel, Device } from "../models";
import { IPaginationQuery, PagedResponse } from "./api";
import { getDevices } from "./axios/admin-api-calls/tenant-calls";
import { round } from "lodash";

export interface DeviceWithId extends Device, BaseModel {}

function toDeviceWithId(device: Device) {
  return { ...device, id: device.serialNumber } as DeviceWithId;
}

export const getDevicesWithId = async (
  tenantId: string,
  paginationQuery: IPaginationQuery,
  searchTerm: string = ""
): Promise<PagedResponse<DeviceWithId> | null> => {
  let devicePr = await getDevices(tenantId, paginationQuery, searchTerm);

  if (!devicePr) return null;

  let items = devicePr.items.map(toDeviceWithId);

  return { ...devicePr, items: items };
};

export const fluidOrBatteryLevelText = (level: number) => {
  if (level === 0) return `0`;
  return round(level, 0).toString();
};
