import { useCallback, useMemo, useReducer } from "react";
import {
  actionFunc,
  actionTypes,
  FormAction,
  initialForm,
  ProvisionForm,
} from "./provisioningHook.models";
import { DeviceModel } from "../../models/detailedDevice.model";
import { ProvisionResults } from "../../models";

function formReducer(state: ProvisionForm, action: FormAction): ProvisionForm {
  switch (action.type) {
    case actionTypes.Reset:
      return initialForm;
    case actionTypes.UpdateModel:
      return { ...state, model: action.payload };
    case actionTypes.UpdateNumDevices:
      return { ...state, numDevices: action.payload };
    default:
      return state;
  }
}

const resetState: actionFunc = () => {
  return {
    type: actionTypes.Reset,
  };
};

const changeNumDevices: actionFunc = (numDevices: number) => {
  return {
    type: actionTypes.UpdateNumDevices,
    payload: numDevices,
  };
};


const changeModel: actionFunc = (model: DeviceModel) => {
  return {
    type: actionTypes.UpdateModel,
    payload: model,
  };
};

const useProvisioningForm = () => {
  const [formState, dispatch] = useReducer(formReducer, initialForm);

  const reset = useCallback(() => dispatch(resetState()), [dispatch]);
  const updateModel = useCallback(
    (newModel: DeviceModel) => dispatch(changeModel(newModel)),
    [dispatch]
  );

  const updateNumDevices = useCallback(
    (num: number) => dispatch(changeNumDevices(num)),
    [dispatch]
  );

  const validForm = useMemo(() => {
    if (
      !!formState.model &&
      formState.numDevices &&
      0 < formState.numDevices  
      )
      return true;

    return false;
  }, [formState]);

  return {
    form: formState,
    validForm,
    reset,
    updateModel,
    updateNumDevices,
  };
};

export default useProvisioningForm;

const PROVISION_RESULT_KEY = "PROVISION_RESULT_KEY";

export const useSessionStorageProvisionResult = () => {
  const getProvisionResult = () => {
    let val = sessionStorage.getItem(PROVISION_RESULT_KEY);

    if (!val) return null;

    let pr = JSON.parse(val) as ProvisionResults;

    if (!pr) return null;

    return pr;
  };

  const storeProvisionResult = (pr: ProvisionResults) => {
    sessionStorage.setItem(PROVISION_RESULT_KEY, JSON.stringify(pr));
  };

  return { getProvisionResult, storeProvisionResult };
};
