import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "../routing/PrivateRoutes";
import { config } from "../config";

export const App: React.FC = () => {
  useEffect(() => {
    if (config.resaniEnvironment !== "production") {
      const oldTitle = document.title;
      document.title = `${oldTitle} ${config.resaniEnvironment.toUpperCase()}`;
      return () => {
        document.title = oldTitle;
      };
    }
  });
  return (
    <Routes>
      <Route path="/*" element={<PrivateRoutes />} />
    </Routes>
  );
};
