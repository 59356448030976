import { Button, ButtonProps, styled } from "@mui/material"


const BlackButton = styled(Button)<ButtonProps>(({ theme }) => ( {
    backgroundColor: theme.palette.primary.dark,
    padding: "1rem",
    color:"white",
    '&:hover': {
      backgroundColor: "black",
    },
    '&:disabled': {
        color: theme.palette.grey[600],
        backgroundColor:theme.palette.grey[800],

    }
  }
));

export default BlackButton