import { KeycloakProfile } from "keycloak-js";
import { SET_PROFILE, SET_USER_INFO } from "./actionTypes";

export const setProfile = (profile: KeycloakProfile) => {
  return { type: SET_PROFILE, payload: profile };
};


export const setUserInfo = (userInfo: KeycloakProfile) => {
    return { type: SET_USER_INFO, payload: userInfo };
  };
  