import axios, { AxiosError } from "axios";
import {
  CreateDeviceMaintenance,
  DeviceSession,
  UpdateDeviceSession,
  UserSession,
} from "../../../models/maintenance.model";
import { MAINTENANCE_API_URL, PagedResponse, PaginationQuery, repeatedArrayFormat } from "../../api";
import qs from "qs";
import { Tenant } from "../../../models";


export const getServiceTenants = async (
  paginationQuery: PaginationQuery,
): Promise<PagedResponse<Tenant> | null> => {
  try {

    var response = await axios.get(
      paginationQuery.toUrl(`/service-tenants`, MAINTENANCE_API_URL),
    );

    
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};


export const startMaintenanceMode = async (): Promise<number> => {
  try {
    var response = await axios.post(`${MAINTENANCE_API_URL}/sessions`);
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const getMaintenanceSessions = async (
  paginationQuery: PaginationQuery,
  searchTerm?: string,
  tenantIdentifiers: string[] | null = null
): Promise<PagedResponse<UserSession> | null> => {
  try {
    let params = { term: searchTerm, tenantIdentifiers: tenantIdentifiers };

    var response = await axios.get(
      paginationQuery.toUrl("/sessions", MAINTENANCE_API_URL),
      {
        params,

        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMaintenanceSessionsForUser = async (
  paginationQuery: PaginationQuery,
  userId: string,
  tenantIdentifiers: string[] | null = null
): Promise<PagedResponse<UserSession> | null> => {
  try {
    let params = { tenantIdentifiers: tenantIdentifiers };

    var response = await axios.get(
      paginationQuery.toUrl(`/users/${userId}/sessions`, MAINTENANCE_API_URL),
      {
        params,
        paramsSerializer: repeatedArrayFormat,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createDeviceMaintenance = async (
  deviceMaintenance: CreateDeviceMaintenance
): Promise<number> => {
  try {
    var response = await axios.post(
      `${MAINTENANCE_API_URL}/sessions/devices`,
      deviceMaintenance
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const updateDeviceSession = async (
  sessionId: string,
  updateDto: UpdateDeviceSession
): Promise<DeviceSession | null> => {
  try {
    var response = await axios.put(
      `${MAINTENANCE_API_URL}/device-sessions/${sessionId}`,
      updateDto
    );

    return response.data as DeviceSession;
  } catch {
    return null;
  }
};

export const stopDeviceSession = async (sessionId: string): Promise<number> => {
  try {
    var response = await axios.post(
      `${MAINTENANCE_API_URL}/device-sessions/${sessionId}/stop`
    );

    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const getActiveDeviceSessionsForTenant = async (
  paginationQuery: PaginationQuery,
  tenantIdentifier: string,
  searchTerm?: string
): Promise<PagedResponse<DeviceSession> | null> => {
  try {
    let params = {
      term: searchTerm,
      tenantIdentifiers: [tenantIdentifier],
      currentlyUnderMaintenance: true,
    };

    var response = await axios.get(
      paginationQuery.toUrl(`/device-sessions`, MAINTENANCE_API_URL),
      {
        params,
        paramsSerializer: repeatedArrayFormat,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDeviceSessionsForDevice = async (
  serialNumber: string,
  paginationQuery: PaginationQuery,
  searchTerm?: string,
  tenantIdentifiers: string[] | null = null
): Promise<PagedResponse<DeviceSession> | null> => {
  try {
    let params;
    if (tenantIdentifiers) {
      params = { term: searchTerm, tenantIdentifiers: tenantIdentifiers };
    } else {
      params = { term: searchTerm };
    }

    var response = await axios.get(
      paginationQuery.toUrl(
        `/devices/${serialNumber}/sessions`,
        MAINTENANCE_API_URL
      ),
      {
        params,
        paramsSerializer: repeatedArrayFormat,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

