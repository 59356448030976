import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  floorPlanThunkGetter,
  resetFloorPlanState,
} from "../../store/actions/floorPlanActions";
import { RootState } from "../../store/reducers";
import { useObjectsFromPath } from "../location-hooks/locationHelperHooks";
import { EntityType, entityEnum } from "./floorPlanHookTypes";

export const useFloorPlan = () => {
  const { outerMostEntity, premise, section, room, floor } =
    useObjectsFromPath();

  const floorPlanState = useSelector(
    (state: RootState) => state.floorPlanReducer
  );

  const dispatch = useDispatch();

  const [entity, setEntity] = useState<
    EntityType
  >(null);
  const [entityType, setEntityType] = useState<entityEnum>(
    entityEnum.nullEntity
  );

  const shouldFetchFloorPlan = useMemo(() => {
    if (floorPlanState.fetchState === "loading" || !entity) return false;


    return (
      floorPlanState.fetchState === "default"
    );
  }, [floorPlanState, entity]);

  useEffect(() => {
    switch (outerMostEntity) {
      case entityEnum.RoomModel:
        setEntityType(entityEnum.RoomModel);
        setEntity(room);
        break;
      case entityEnum.FloorModel:
        setEntityType(entityEnum.FloorModel);
        setEntity(floor);
        break;
      case entityEnum.PremiseModel:
        setEntityType(entityEnum.PremiseModel);
        setEntity(premise);
        break;
      case entityEnum.SectionModel:
        setEntityType(entityEnum.SectionModel);
        setEntity(section);
        break;
      default:
        setEntityType(entityEnum.nullEntity);
        setEntity(null);
        break;
    }
  }, [premise, section, room, floor, outerMostEntity, dispatch]);

  useEffect(() => {
    dispatch(resetFloorPlanState());
  }, [outerMostEntity, dispatch]);

  useEffect(() => {
    if (entity && shouldFetchFloorPlan) {
      dispatch(floorPlanThunkGetter(entity.id, entityType));
    }
  }, [entity, floorPlanState, entityType, shouldFetchFloorPlan, dispatch]);

  return floorPlanState;
};
