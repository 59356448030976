import { DeviceModel } from "../../models/detailedDevice.model";

export enum actionTypes {
    UpdateModel,
    UpdateNumDevices,
    Reset,
  }
  
  export  type actionFunc = (...args: any[]) => FormAction;
  
  export  interface FormAction {
    type: actionTypes;
    payload?: any;
  }
  export   interface ProvisionForm {
    numDevices: number | null;
    model: DeviceModel | null;
  }
  export  const initialForm: ProvisionForm = {
    numDevices: 1,
    model: null,
  };