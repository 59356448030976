import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import AddUserForm from "../../components/add-user/AddUserForm";
import Title from "../../components/title/Title";
import { RootState } from "../../store/reducers";

const InviteUserView = () => {
  const tenant = useSelector((state: RootState) => state.tenantReducer.tenant);
  return (
    <>
      <Title>Invite a new user to {tenant?.name}</Title>
      <Grid container flexDirection="column" alignContent="center">
        <Grid item margin={10} flexDirection="column" alignContent="center">
          <AddUserForm />
        </Grid>
      </Grid>
    </>
  );
};

export default InviteUserView;
