import { config } from "../../config";
import {
  BroadEventType,
  EventType,
  EventTypeEnum,
  UNKNOWN_EVENT,
  BaseMessage,
  DeviceEvent,
} from "../../models/deviceEvents.model";
import ColorHash from "color-hash";

export const getEventColor = (
  eventType: BroadEventType,
  event:  BaseMessage,
) => {
  let eventSpec = config.eventLogSpecs.events.find(eventSpec => eventSpec.name === eventType)

  if(eventSpec && eventSpec.color) return eventSpec.color

  return mapEventColor(eventType as EventType, event as BaseMessage);
};

const mapEventColor = (eventType: EventType, event: BaseMessage) => {
  switch (eventType) {
    case EventTypeEnum.BluetoothEvent:
      return "#0082fc";
    case EventTypeEnum.State:
      return "#f57600";
    default:
      return new ColorHash().hex((event as DeviceEvent).event ?? UNKNOWN_EVENT);
  }
};
