import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDeviceFirmware } from "../../hooks/admin-device/firmwareHooks";
import { useAdminDevice } from "../../hooks/admin-device/useAdminDeviceHooks";

const DeviceFirmwareView = () => {
  const { serialNumber } = useParams();
  const { fetchState, firmware } = useDeviceFirmware(serialNumber);
  const { adminDevice } = useAdminDevice(serialNumber);

  return (
    <>
      <Grid container margin={2} flexDirection="column">
        <Grid item>
          <h1>{serialNumber}</h1>
        </Grid>
        <Grid container flexDirection={"column"} alignContent="center">
          <Grid item width={400}>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Firmware</TableCell>
                  <TableCell>Current Version</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Version</TableCell>
                  <TableCell>
                    {fetchState === "loading" && <Skeleton variant="text" />}
                    {firmware?.version}
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {adminDevice?.isUpdatingFirmware && (
              <Typography textAlign="center">
                {serialNumber} is currently updating its firmware
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DeviceFirmwareView;
