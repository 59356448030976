import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import {downloadQrCode} from '../../utilities/axios/admin-api-calls/adminCalls';
import {AxiosError} from 'axios';

interface props{
    serialNumber: string;
    onDownloadFailed: (err: AxiosError) => void;
}

const DownloadFileButton = ({serialNumber, onDownloadFailed}: props) => {
    const download = () => {
        downloadQrCode(serialNumber).then((data: Blob) => {
            saveAs(data, `qr-code-${serialNumber}.png`);
        }).catch(err => {
            onDownloadFailed(err);
        })

    }
  return (
      <Button variant="outlined" onClick={download}>
          Download QR code
      </Button>
  );
};

export default DownloadFileButton;
