
export const FOTA_ROUTE = { path: "/fota", name: "Fota" }


export const adminRoutes: RouteObj[] = [
  { path: "/devices", name: "Devices" },
  { path: "/tenants", name: "Set tenant" },
  { path: "/lander", name: "Home" },
];

export const tenantRoutes: RouteObj[] = [
  { path: "/dashboard", name: "Dashboard" },
  { path: "/maintenance", name: "Maintenance" },
  { path: "/installations", name: "Installations" },
  { path: "/sections", name: "Sections" },
  { path: "/users", name: "Users" },
];

export interface RouteObj {
  path: string;
  name: string;
}
