import React from "react";
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import { App } from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "./index.scss";
import store from "./store";
import { ThemeProvider } from "@mui/material";
import muiTheme from "./styles/muiTheme";
import AuthContextProvider from "./provider/AuthContextProvider";


const container = document.getElementById('root');


const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <AuthContextProvider>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </AuthContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
