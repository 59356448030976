import { Box, Drawer, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DrawerLogo from "./DrawerLogo";
import RoutesList from "./RoutesList";

type props = { open: boolean; toggleDrawer: () => void };

const MenuDrawer = ({ open, toggleDrawer }: props) => {
  return (
    <Drawer anchor="left" open={open} onClose={toggleDrawer}>
      <Grid
      container
      justifyContent={"center"}
      alignContent="center"
      sx={{
        height:"64px"
      }}
      >
        <Box sx={{paddingLeft: "25px", backgroundColor: "primary.main"}}>
        <DrawerLogo Icon={CloseIcon} color="white" onClick={toggleDrawer}/>
        </Box>
      </Grid>
      <RoutesList toggleDrawer={toggleDrawer} />
    </Drawer>
  );
};

export default MenuDrawer;
