import { HubConnection } from "@microsoft/signalr";
import { fetchStateType } from "../../models/types";
import {
  SET_CURRENT_GROUP_LOCKED,
  SET_CURRENT_TENANT_GROUP_ID,
  SET_SIGNALR_FETCH_STATE,
} from "./actionTypes";
import store, { AppDispatch } from "..";
import { signalRFunctions } from "../../config";

export const changeTenantIdGroup =
  (hubConnection: HubConnection, tenantIdentifier: string, force:boolean=false) =>
  async (dispatch: AppDispatch) => {
    let isLocked = store.getState().signalrReducer.groupIsLocked;
    if (isLocked && !force) return



    let oldGroup = store.getState().signalrReducer.currentTenantGroup;
    if (oldGroup) await leaveGroup(hubConnection, oldGroup);
    await joinGroup(hubConnection, tenantIdentifier);
    if (force) dispatch(setLockCurrentGroup(true))

    return dispatch(setCurrentGroup(tenantIdentifier));
  };

export const setConnectionIdFetchState = (fetchState: fetchStateType) => {
  return { type: SET_SIGNALR_FETCH_STATE, payload: fetchState };
};

export const setLockCurrentGroup = (isLocked: boolean) => {
  return { type: SET_CURRENT_GROUP_LOCKED, payload: isLocked };
};

const setCurrentGroup = (tenantIdentifier: string) => {
  return { type: SET_CURRENT_TENANT_GROUP_ID, payload: tenantIdentifier };
};

async function leaveGroup(hubConnection: HubConnection, oldGroup: string) {
  await hubConnection.invoke(
    signalRFunctions.tenant.LeaveTenantGroup,
    oldGroup
  );
}
async function joinGroup(
  hubConnection: HubConnection,
  tenantIdentifier: string
) {
  await hubConnection.invoke(
    signalRFunctions.tenant.JoinTenantGroup,
    tenantIdentifier
  );
}
