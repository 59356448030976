import { Alert, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useIdsFromPath } from "../../hooks/location-hooks/locationHelperHooks";
import { RootState } from "../../store/reducers";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import SectionPremiseDisplay from "../../components/premise-display/SectionPremiseDisplay";
import AddPremiseButton from "../../components/add-premise/AddPremiseButton";

const SectionView = () => {
  const { sectionId } = useIdsFromPath();
  const [sectionViewKey, setSectionViewKey] = useState(uuidv4());

  const section = useSelector(
    (state: RootState) => state.locationObjectsReducer.section
  );

  return (
    <div key={sectionViewKey}>
      <Typography variant={"h1"} textAlign="center">
{section?.name}
      </Typography>
      <Grid container justifyContent="center">
        <Grid item width={400} margin={10}>
          {sectionId ? (
            <SectionPremiseDisplay sectionId={sectionId} />
          ) : (
            <Alert severity="error">Could not read sectionId from url</Alert>
          )}
        </Grid>
        <Grid item flexDirection="column" display="flex" margin={10}>
          <AddPremiseButton sectionId={section?.id ?? ""} onAdded={() => setSectionViewKey(uuidv4())} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SectionView;
