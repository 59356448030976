import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { newTenant } from "../../store/actions/tenantActions";
import { getTenantFromStorage } from "../../utilities/storage/tenantStorage";


const TenantLoadingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let tenant = getTenantFromStorage();
    if (tenant) {
      dispatch(newTenant(tenant));
      navigate(`/${tenant.identifier}/dashboard`);
    } else {
      navigate("/lander");
    }
  });

  return <></>;
};

export default TenantLoadingPage;
