import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TypeModel } from "../../models/locations.model";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { fetchStateType, saveStateType } from "../../models/types";
import {
  getRoomTypes,
  postRoomType,
} from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import { PaginationQuery } from "../../utilities/api";

type props = { onChange: (type: TypeModel | null) => void; disabled: boolean };

const RoomTypeSelect = ({ onChange, disabled }: props) => {
  const [createNew, setCreateNew] = useState(false);
  const [saveState, setSaveState] = useState<saveStateType>("default");

  const [roomTypesFetchStatus, setRoomTypeFetchStatus] =
    useState<fetchStateType>("default");

  const roomTypeHelperText = useMemo(() => {
    switch (saveState) {
      case "conflict":
        return "Room type already exists";
      case "error":
        return "Unable to save";
      default:
        return "";
    }
  }, [saveState]);

  const [roomTypes, setRoomTypes] = useState<TypeModel[]>([]);
  const [roomType, setRoomType] = useState<TypeModel>(defaultRoomType);
  const [createdRoomType, setCreatedRoomType] =
    useState<TypeModel>(defaultRoomType);

  const fetchRoomTypes = useCallback(async () => {
    if (roomTypesFetchStatus === "loading") return;

    setRoomTypeFetchStatus("loading");

    let res = await getRoomTypes(new PaginationQuery(0, 100));


    if (res === null) {
      setRoomTypeFetchStatus("error");
    } else {
      setRoomTypeFetchStatus("fetched");
      setRoomTypes(res.items);
    }
  }, [roomTypesFetchStatus]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreatedRoomType({ ...createdRoomType, name: event.target.value });
  };

  useEffect(() => {
    if (roomTypesFetchStatus === "default") {
      fetchRoomTypes();
    }
  }, [saveState, roomTypes, roomTypesFetchStatus, fetchRoomTypes]);

  const handleSelected = (event: SelectChangeEvent) => {
    let newType = roomTypes.find(
      (roomType) => roomType.id === event.target.value
    );

    if (newType === undefined) return;
    setRoomType(newType);
    onChange(newType);
  };

  const createRoomType = useCallback(async () => {
    if (saveState === "loading") return;
    setSaveState("loading");

    var res = await postRoomType(createdRoomType);

    if (res?.status === 409) {
      setSaveState("conflict");
      return;
    }

    if (res?.status === 200) {
      setRoomTypeFetchStatus("default");
      setSaveState("success");
      setCreateNew(false);
      setRoomType(res.data);
      onChange(res.data);
      return;
    }
    setSaveState("error");
  }, [saveState, createdRoomType, onChange]);

  const handleCreateNewButtonClicked = () => {
    setCreateNew(true);
    onChange(null);
  };

  const handleCancelCreateNewClicked = () => {
    setCreateNew(false);
    onChange(roomType);
  };

  const handleChangeTypeButtonClicked = () => {
    if (saveState === "success") {
      setSaveState("default");
      setRoomType(defaultRoomType);
    }
  };

  return (
    <Grid item flexDirection="column" marginBottom={5}>
      {saveState !== "loading" && roomTypesFetchStatus !== "loading" ? (
        <Grid item width={200}>
          {createNew || saveState === "success" ? (
            <>
              {saveState === "success" ? (
                <>
                  <Alert severity="success" variant="outlined">
                    {roomType.name}
                  </Alert>
                </>
              ) : (
                <>
                  <TextField
                    disabled={disabled}
                    id="outlined-type"
                    label="New room type"
                    variant="outlined"
                    value={createdRoomType.name}
                    onChange={handleChange}
                    error={saveState === "conflict" || saveState === "error"}
                    helperText={roomTypeHelperText}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {roomTypesFetchStatus === "fetched" && (
                <FormControl margin="none" fullWidth disabled={disabled}>
                  <InputLabel id="demo-simple-select-label">
                    Select type
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={roomType.id}
                    label="Room Type"
                    onChange={handleSelected}
                  >
                    {roomTypes.map((roomType) => (
                      <MenuItem value={roomType.id}>{roomType.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {roomTypesFetchStatus === "error" && (
                <Alert severity="error" variant="outlined">
                  Unable to fetch room types
                </Alert>
              )}
            </>
          )}
        </Grid>
      ) : (
        <Grid container justifyContent={"center"} minWidth={208}>
          <CircularProgress />
        </Grid>
      )}

      {createNew && saveState !== "loading" && (
        <Grid item>
          <Grid item textAlign={"center"}>
            Use for compliance?
            <Checkbox
              checked={createdRoomType.useForCompliance}
              onChange={() =>
                setCreatedRoomType({
                  ...createdRoomType,
                  useForCompliance: !createdRoomType.useForCompliance,
                })
              }
            />
          </Grid>
        </Grid>
      )}

      {!createNew && roomType !== defaultRoomType && (
        <Grid item>
          <Grid item textAlign={"center"}>
            Used for compliance
            <Checkbox checked={roomType.useForCompliance} disabled={true} />
          </Grid>
        </Grid>
      )}

      <Grid item>
        {!createNew && saveState === "success" && (
          <Button
            onClick={handleChangeTypeButtonClicked}
            size="small"
            disabled={disabled}
          >
            change type
          </Button>
        )}

        {saveState !== "loading" && saveState !== "success" && createNew && (
          <Button
            onClick={handleCancelCreateNewClicked}
            size="small"
            disabled={disabled}
          >
            Cancel
          </Button>
        )}
        {saveState !== "loading" && saveState !== "success" && !createNew && (
          <Button
            onClick={handleCreateNewButtonClicked}
            size="small"
            disabled={disabled}
          >
            or create new
          </Button>
        )}

        {createNew && saveState !== "loading" && saveState !== "success" && (
          <Button size="small" onClick={createRoomType} disabled={disabled}>
            Create
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default RoomTypeSelect;
const defaultRoomType: TypeModel = {
  id: "",
  name: "",
  useForCompliance: false,
};
