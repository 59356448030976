import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#424242",
    },
    secondary: {
      main: "#4c94e8",
      light: "#a9cbf4",
      dark: "#30639e",
    },
  },
  transitions: {},
  typography: {
    fontFamily: [
      "Barlow",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    fontWeightRegular: 500,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 400,
    fontSize: 24,
    h1: {
      font: "Barlow",
      fontWeight: 100,
      fontSize: "2.8rem",
      margin: 0,
    },
    body1: {
      font: "Barlow",
      fontWeight: 500,
      fontSize: "1.6rem",
      margin: 0,
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
    padding: "1rem",

          "&:hover": {
            fontWeight: 850,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "1.6rem",
        },
      },
    },

    MuiUseMediaQuery: {
      defaultProps: {
          noSsr: true,
      }
    },
 
  },
});

export default muiTheme;
