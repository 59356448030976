import { AnyAction, Reducer } from "redux";
import {
  filterableEventList,
  ReceivedEvent,
} from "../../models/deviceEvents.model";
import {
  RESET_EVENTS_REDUCER_STATE,
  RESET_MOST_RECENT_STATE,
  SET_MAX_EVENTS_IN_FEED,
  SET_MOST_RECENT_EVENTS,
  SET_FILTER,
  SET_EVENT_FETCHSTATE
} from "../actions/actionTypes";
import { fetchStateType } from "../../models/types";

export const DEFAULT_MAX_EVENTS_IN_FEED = 10;

type eventStateType = {
  mostRecentEvents: ReceivedEvent[];
  initiated: boolean;
  maxEventsInFeed: number;
  filter: { [key: string]: boolean };
  eventFetchState: fetchStateType
  filterIsOn: boolean;
};

export const initialFilter = Object.fromEntries(
  new Map(filterableEventList.map((eventType) => [eventType, false]))
);

const initialState: eventStateType = {
  mostRecentEvents: [],
  initiated: false,
  maxEventsInFeed: DEFAULT_MAX_EVENTS_IN_FEED,
  filter: initialFilter,
  filterIsOn: false,
  eventFetchState: "default"
};

const eventsReducer: Reducer<eventStateType, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_MOST_RECENT_EVENTS:
      return {
        ...state,
        mostRecentEvents: [...action.payload],
        initiated: true,
      };
    case SET_MAX_EVENTS_IN_FEED:
      return {
        ...state,
        maxEventsInFeed: action.payload,
      };
    case SET_EVENT_FETCHSTATE:
      return {
        ...state,
        eventFetchState: action.payload
      }
    case SET_FILTER:
      return {
        ...state,
        filter: {...action.payload.filter},
        filterIsOn: action.payload.filterIsOn,
        eventFetchState: "default"
      };
    case RESET_MOST_RECENT_STATE:
      return {
        ...state,
        initiated: false,
        mostRecentEvents: [],
        eventFetchState: "default"
      };
    case RESET_EVENTS_REDUCER_STATE:
      return initialState;
    default:
      return state;
  }
};

export default eventsReducer;
