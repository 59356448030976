
//events
export const SET_MOST_RECENT_EVENTS:'SET_MOST_RECENT_EVENTS' = 'SET_MOST_RECENT_EVENTS'
export const RESET_MOST_RECENT_STATE: 'RESET_MOST_RECENT_STATE' = 'RESET_MOST_RECENT_STATE'
export const SET_MAX_EVENTS_IN_FEED: 'SET_MAX_EVENTS_IN_FEED' = 'SET_MAX_EVENTS_IN_FEED'
export const RESET_EVENTS_REDUCER_STATE: 'RESET_EVENTS_REDUCER_STATE' = 'RESET_EVENTS_REDUCER_STATE'
export const SET_FILTER: 'SET_FILTER' = 'SET_FILTER'
export const SET_EVENT_FETCHSTATE: 'SET_EVENT_FETCHSTATE' = 'SET_EVENT_FETCHSTATE'


//tenant
export const SET_TENANT:'SET_TENANT' = 'SET_TENANT'
export const SET_TENANT_FETCH_STATE: 'SET_TENANT_FETCH_STATE' = 'SET_TENANT_FETCH_STATE'

//Location objects
export const SET_PREMISE_LOCATION_OBJECT: 'SET_PREMISE_LOCATION_OBJECT' = 'SET_PREMISE_LOCATION_OBJECT'
export const SET_SECTION_LOCATION_OBJECT: 'SET_SECTION_LOCATION_OBJECT' = 'SET_SECTION_LOCATION_OBJECT'
export const SET_ROOM_LOCATION_OBJECT: 'SET_ROOM_LOCATION_OBJECT' = 'SET_ROOM_LOCATION_OBJECT'
export const RESET_LOCATION_OBJECTS: 'RESET_LOCATION_OBJECTS' = 'RESET_LOCATION_OBJECTS'
export const SET_FLOOR_LOCATION_OBJECT: 'SET_FLOOR_LOCATION_OBJECT' = 'SET_FLOOR_LOCATION_OBJECT'


//floor-plan
export const SET_FLOOR_PLAN: 'SET_FLOOR_PLAN' = 'SET_FLOOR_PLAN'
export const RESET_FLOOR_PLAN:'RESET_FLOOR_PLAN' = 'RESET_FLOOR_PLAN'
export const SET_FLOOR_PLAN_FETCH_STATE:'SET_FLOOR_PLAN_FETCH_STATE'= 'SET_FLOOR_PLAN_FETCH_STATE'

//Detailed device
export const SET_DETAILED_DEVICE:'SET_DETAILED_DEVICE' = 'SET_DETAILED_DEVICE'
export const SET_FETCH_STATE_DETAILED_DEVICE:'SET_FETCH_STATE_DETAILED_DEVICE' = 'SET_FETCH_STATE_DETAILED_DEVICE'
export const RESET_DETAILED_DEVICE_STATE: 'RESET_DETAILED_DEVICE_STATE' = 'RESET_DETAILED_DEVICE_STATE'
export const SET_FLUID:'SET_FLUID' = 'SET_FLUID'

// Maintenance
export const SET_MAINTENANCE_ACTIVE_FETCH_STATE:'SET_MAINTENANCE_ACTIVE_FETCH_STATE' = 'SET_MAINTENANCE_ACTIVE_FETCH_STATE'
export const SET_MAINTENANCE_ACTIVE:'SET_MAINTENANCE_ACTIVE' = 'SET_MAINTENANCE_ACTIVE'
export const RESET_MAINTENANCE_STATE:'RESET_MAINTENANCE_STATE' = 'RESET_MAINTENANCE_STATE'
export const SET_MAINTENANCE_TENANT: 'SET_MAINTENANCE_TENANT' = 'SET_MAINTENANCE_TENANT'
export const SET_MAINTENANCE_ACTIVE_SAVE_STATE: 'SET_MAINTENANCE_ACTIVE_SAVE_STATE' = 'SET_MAINTENANCE_ACTIVE_SAVE_STATE'
export const SET_MAINTENANCE_ID: 'SET_MAINTENANCE_ID' = 'SET_MAINTENANCE_ID'
export const SET_MAINTENANCE_SERVICE_TENANT: 'SET_MAINTENANCE_SERVICE_TENANT' = 'SET_MAINTENANCE_SERVICE_TENANT'
export const SET_MAINTENANCE_SERVICE_TENANT_FETCHSTATE: 'SET_MAINTENANCE_SERVICE_TENANT_FETCHSTATE' = 'SET_MAINTENANCE_SERVICE_TENANT_FETCHSTATE'


//user
export const SET_PROFILE: 'SET_PROFILE' = 'SET_PROFILE'
export const SET_USER_INFO: 'SET_USER_INFO' = 'SET_USER_INFO'

//signalR
export const SET_SIGNALR_FETCH_STATE: 'SET_SIGNALR_FETCH_STATE' = 'SET_SIGNALR_FETCH_STATE'
export const SET_CURRENT_TENANT_GROUP_ID: 'SET_CURRENT_TENANT_GROUP_ID' = 'SET_CURRENT_TENANT_GROUP_ID'
export const SET_CURRENT_GROUP_LOCKED: 'SET_CURRENT_GROUP_LOCKED' = 'SET_CURRENT_GROUP_LOCKED'