import React from 'react';
import cn from 'classnames';

import logoWhite from '../../media/logo_white.png';
import logoBlack from '../../media/logo_black.png';
import logoDefault from '../../media/logo.png';

import styles from './Logo.module.scss';

interface Props {
  color?: 'white' | 'black';
  size: 'small' | 'large';
}

export const Logo: React.FC<Props> = ({ color, size }) => {
  let logo: string;

  switch (color) {
    case 'white':
      logo = logoWhite;
      break;
    case 'black':
      logo = logoBlack;
      break;
    default:
      logo = logoDefault;
  }

  return <img className={cn(styles.logo, styles[size])} src={logo} alt="Resani logo in text" />;
};
