import { useCallback, useEffect, useMemo, useState } from "react";
import { Tenant } from "../../models";
import { saveStateType } from "../../models/types";
import {
  GroupRepresentation,
  User,
  UserSection,
} from "../../models/users.model";
import { updateUser as updateUserApiCall } from "../../utilities/axios/admin-api-calls/users-calls/usersCalls";
import { arraySetEquality } from "../../utilities/helpers";
import { useTenant } from "../tenant";

type useUpdateUserManagerType = (
  props: UpdateUserManagerProps
) => UpdateUserManager;

//input
interface UpdateUserManagerProps {
  user: User;
  onUserUpdated: (user: User) => void;
}

//output
interface UpdateUserManager {
  saveState: saveStateType;
  updateDisabled: boolean;
  updateUser: () => void;
  tenantName: string;
  setTenant: React.Dispatch<React.SetStateAction<Tenant | null>>;
  groups: GroupRepresentation[];
  setGroups: React.Dispatch<React.SetStateAction<GroupRepresentation[]>>;
  notifyUserTimeOut: boolean;
  tenantChanged: boolean;
  setSections: React.Dispatch<React.SetStateAction<UserSection[]>>;
  sections: UserSection[];
}

export const useUpdateUserManager: useUpdateUserManagerType = ({
  user,
  onUserUpdated,
}: UpdateUserManagerProps) => {
  const initialUserTenant = useTenant();

  const [tenant, setTenant] = useState<Tenant | null>(initialUserTenant);
  const [notifyUserTimeOut, setNotifyUserTimeOut] = useState(false);

  const [groups, setGroups] = useState<GroupRepresentation[]>([]);
  const [sections, setSections] = useState<UserSection[]>([]);

  const [saveState, setSaveState] = useState<saveStateType>("default");
  const [updatedUser, setUpdatedUser] = useState<User>(user);

  const tenantName = useMemo(() => tenant?.name ?? "unknown", [tenant]);

  const updateDisabled = useMemo(() => {
    if (groups.length === 0 && sections.length === 0) return true;

    if (
      arraySetEquality(groups, user.groups, "id") &&
      arraySetEquality(sections, user.allowedSections, "id")
    )
      return true;

    return false;
  }, [user, groups, sections]);
  const tenantChanged = useMemo(
    () => user.tenant.identifier !== tenant?.identifier,
    [tenant, user]
  );

  useEffect(() => {
    if (user) {
      setGroups(user.groups);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setSections(user.allowedSections);
    }
  }, [user]);

  useEffect(() => {
    if (!notifyUserTimeOut) setSaveState("default");
  }, [groups, tenantName, notifyUserTimeOut]);

  useEffect(() => {
    if (saveState === "success") {
      setNotifyUserTimeOut(true);
      const timeoutId = setTimeout(() => {
        setNotifyUserTimeOut(false);

        onUserUpdated(updatedUser);
      }, 3000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setNotifyUserTimeOut(false);
    }
  }, [saveState, updatedUser, onUserUpdated]);

  const updateUser = useCallback(async () => {
    if (saveState === "loading" || !tenant) return;
    setSaveState("loading");
    let updatedUser = await updateUserApiCall(user.id, {
      userInfo: { groups: groups.map((g) => g.id) },
      allowedSections: sections.map((s) => s.id),
    });

    if (!!updatedUser) {
      setSaveState("success");
      setUpdatedUser(updatedUser);
    } else {
      setSaveState("error");
    }
  }, [saveState, sections, user, groups, tenant]);

  return {
    saveState,
    updateUser,
    updateDisabled,
    tenantName,
    setTenant,
    groups,
    setGroups,
    notifyUserTimeOut,
    tenantChanged,
    setSections,
    sections,
  };
};
