import { useCallback } from "react";

type csvData = {
    columnNames: string[],
    rows: any[][]
}

export const useCsvDownloading = (data : csvData, fileName: string) => {


  const handleDownload = useCallback(() => {
    try {
      // Convert JSON to CSV
      const csv = parse(data);

      // Create a blob from the CSV string
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", `${fileName}.csv`);

      // Append the link and trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting CSV:", error);
    }
  }, [data, fileName]);

  return {handleDownload}
};


function appendLineToStr(str:string, row:any[])
{
    let line = "";

    line = row.join(",")

    str += line + "\r\n";
    return str;
}


 function parse(objArray: csvData){
    let str = "";
    // header
    str = appendLineToStr(str, objArray.columnNames)

    for (let rowId = 0; rowId < objArray.rows.length; rowId++) {
        const row = objArray.rows[rowId];

        
        str = appendLineToStr(str, row)
        
    }


    return str
 }