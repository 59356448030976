import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../provider/AuthContextProvider";
import { setAuthHeaderInAxios } from "../../utilities/axios";

export const useIsAuthenticated = () => {
  const authContext = useContext(AuthContext);

  const isAuthenticated = useMemo(
    () => authContext.isAuthenticated,
    [authContext.isAuthenticated]
  );
  const [authDone, setAuthDone] = useState(false);

  useEffect(() => {
    let token = authContext.getToken();
    if (isAuthenticated && token) {
      setAuthHeaderInAxios(token);
      setAuthDone(true);
    }
  }, [isAuthenticated, authContext]);

  return { isAuthenticated, authDone };
};
