import { Tenant } from "../models"

export const pathToTenantDashboard = (tenant:Tenant) => `/${tenant.identifier}/dashboard`
export const pathToTenantUsers = (tenant:Tenant | null) => `/${tenant?.identifier}/users`

export const pathToTenantDevice = ( tenantIdentfier:string, serialNumber: string) => `/${tenantIdentfier}/device/${serialNumber}`
export const pathToAdminDevice = (deviceId: string) => `/devices/${deviceId}`

export const pathToTenantInstall = (tenantIdentfier:string) => `/${tenantIdentfier}/installations`

