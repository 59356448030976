import { Typography } from "@mui/material";
import { Tenant } from "../../models";
import TenantDisplay from "../tenant-display/TenantDisplay";
import { AssignDeviceStepProps } from "./AdminDeviceAssignmentDialog";

interface ChooseTenantStepProps extends AssignDeviceStepProps {
  onTenantChosen: (tenant: Tenant) => void;
  disabledTenantId?: string | null
}

const ChooseTenantStep = ({
  assignmentState,
  onTenantChosen,
  disabledTenantId = null
}: ChooseTenantStepProps) => {
  return (
    <>
      <Typography textAlign={"center"} marginBottom={2}>
        Choose tenant
      </Typography>
      <TenantDisplay
        key={assignmentState.step}
        onTenantChosen={onTenantChosen}
        disabledTenantId={disabledTenantId}
      />
    </>
  );
};

export default ChooseTenantStep;
