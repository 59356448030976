import { Grid, Typography } from "@mui/material";
import { useObjectsFromPath } from "../../hooks/location-hooks/locationHelperHooks";
import FloorPlanButton from "../../components/floor-plan-button/FloorPlanButton";

const RoomView = () => {
  const { room } = useObjectsFromPath();

  return (
    <>
      <Typography variant={"h1"} textAlign="center">
      {room?.name}
      </Typography>
      <Grid container justifyContent="center">
        <Grid item width={400} margin={10}></Grid>
        <Grid
          item
          flexDirection="column"
          display="flex"
          justifyContent="space-evenly"
        >
          <FloorPlanButton/>
        </Grid>
      </Grid>
    </>
  );
};

export default RoomView;
