import { AppDispatch } from "..";
import { Tenant } from "../../models";
import { fetchStateType } from "../../models/types";
import { setTenantHeaderInAxios } from "../../utilities/axios";
import { setTenantInStorage } from "../../utilities/storage/tenantStorage";
import { SET_TENANT, SET_TENANT_FETCH_STATE } from "./actionTypes";
import { resetMostRecentEvents } from "./eventActions";

export const newTenant = (tenant: Tenant) => (dispatch: AppDispatch) => {
  dispatch(setTenantFetchState("loading"))
  setTenantHeaderInAxios(tenant);
  setTenantInStorage(tenant);
  dispatch(resetMostRecentEvents());
  return dispatch(setTenant(tenant));
};

const setTenant = (tenant: Tenant) => {
  return {
    type: SET_TENANT,
    payload: tenant,
  };
};

export const setTenantFetchState = (fetchState: fetchStateType) => {
return {
  type: SET_TENANT_FETCH_STATE,
  payload: fetchState
}
}
