import { AnyAction, Reducer } from "redux";
import { FloorPlan } from "../../models";
import {
  RESET_FLOOR_PLAN,
  SET_FLOOR_PLAN,
  SET_FLOOR_PLAN_FETCH_STATE,

} from "../actions/actionTypes";
import { fetchStateType } from "../../models/types";

type floorplanStateType = {
  floorPlan: FloorPlan | null;
  fetchState: fetchStateType
};

const initialState: floorplanStateType = {
  floorPlan: null,
fetchState: "default"
};

const floorplansReducer: Reducer<floorplanStateType, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_FLOOR_PLAN:
      return {
        ...state,
        floorPlan: action.payload,
      };
    case SET_FLOOR_PLAN_FETCH_STATE:
      return {
        ...state,
        fetchState: action.payload,
      };
    case RESET_FLOOR_PLAN:
      return initialState;
    default:
      return state;
  }
};

export default floorplansReducer;
