import { Alert, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useIdsFromPath } from "../../hooks/location-hooks/locationHelperHooks";
import { RootState } from "../../store/reducers";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import PremiseFloorsDisplay from "../../components/floor-display/PremiseFloorsDisplay";
import AddFloorButton from "../../components/add-floor/AddFloorButton";
import AddCoordinatesButton from "../../components/add-coordinates-button/AddCoordinatesButton";

const PremiseView = () => {
  const { premiseId } = useIdsFromPath();
  const [premiseViewKey, setPremiseViewKey] = useState(uuidv4());

  const premise = useSelector(
    (state: RootState) => state.locationObjectsReducer.premise
  );

  const updateKey = () => {
    setPremiseViewKey(uuidv4());
  };

  return (
    <div key={premiseViewKey}>
      <Typography variant={"h1"} textAlign="center">
        {premise?.name}
      </Typography>
      <Grid container justifyContent="center">
        <Grid item width={400} margin={10}>
          {premiseId ? (
            <PremiseFloorsDisplay premiseId={premiseId} />
          ) : (
            <Alert severity="error">Could not read premiseId from url</Alert>
          )}
        </Grid>
        <Grid
          item
          flexDirection="column"
          display="flex"
          margin={10}
          justifyContent={"space-evenly"}
        >
          <AddFloorButton onAdd={updateKey} />
          {!!premise && <AddCoordinatesButton premise={premise} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default PremiseView;
