import {
  Box,
  Typography,
  Button,
  MobileStepper,
  Paper,
  useTheme,
  Skeleton,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { CompleteInstallDto, DeviceInstallation } from "../../models";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { RoomModel } from "../../models/locations.model";
import InstallLocationSelector from "./InstallLocationSelector";
import TransactionsVerifyStep, {
  transactionState,
} from "./TransactionsVerifyStep";
import PowerSupplyStep from "./PowerSupplyStep";
import { useAdminDevice } from "../../hooks/admin-device/useAdminDeviceHooks";
import OverviewStep from "./OverviewStep";
import FluidStep from "./FluidStep";
import { useIsSmallScreen } from "../../hooks/media-hooks/mediaHooks";

const steps = [
  "Select Room",
  "Power Supply",
  "Liquid",
  "Verify Motor Returned",
  "Overview",
];

type props = {
  deviceInstallation: DeviceInstallation;
  onConfirm: (completeInstallDto: CompleteInstallDto) => void;
};

const InstallationStepper = ({ deviceInstallation, onConfirm }: props) => {
  const smallScreen = useIsSmallScreen();

  const maxSteps = steps.length;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [roomChosen, setRoom] = useState<RoomModel | null>(null);
  const [roomConfirmed, setRoomConfirmed] = useState(false);
  const [transactionsState, setTransactionsState] =
    useState<transactionState>("default");

  const {
    adminDevice: device,
    fetchState: deviceFetchState,
    fetchAdminDevice: reloadDetails,
  } = useAdminDevice(deviceInstallation.serialNumber);

  const handleConfirm = useCallback(() => {
    let dto: CompleteInstallDto = {
      roomId: roomChosen?.id ?? "unknown",
    };
    onConfirm(dto);
  }, [onConfirm, roomChosen]);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = useCallback(() => {
    // if (!isStepOptional(activeStep)) {
    //   throw new Error("You can't skip a step that isn't optional.");
    // }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  },[activeStep] );

  const currentStepComponent = useMemo(() => {
    if (!device || deviceFetchState === "loading") return <></>;
    switch (activeStep) {
      case 0:
        return (
          <InstallLocationSelector
            room={roomChosen}
            handleRoomChosen={(room) => {
              setRoomConfirmed(false);
              setRoom(room);
            }}
            onConfirm={(_) => setRoomConfirmed(true)}
          />
        );

      case 1:
        return (
          <PowerSupplyStep device={device} reloadDetails={reloadDetails} />
        );
      case 2:
        return <FluidStep device={device} reloadDetails={reloadDetails} />;
      case 3:
        return (
          <TransactionsVerifyStep
            state={transactionsState}
            device={device}
            deviceInstallation={deviceInstallation}
            onVerified={() => setTransactionsState("verified")}
            onSkipped={() => {
              setTransactionsState("skipped");
              handleSkip();
            }}
          />
        );

      case 4:
        return (
          <OverviewStep
            deviceInstallation={deviceInstallation}
            device={device}
            room={roomChosen}
            onConfirmed={handleConfirm}
          />
        );
      default:
        <>Step not implemented</>;
    }
  }, [
    device,
    handleConfirm,
    handleSkip,
    reloadDetails,
    transactionsState,
    activeStep,
    deviceInstallation,
    roomChosen,
    deviceFetchState,
  ]);

  const isNextDisabled = useMemo(() => {
    switch (activeStep) {
      case 0:
        return !roomConfirmed;

      case 1:
        return false;

      case 2:
        return false;

      case 3:
        return transactionsState !== "verified";

      default:
        return activeStep === maxSteps - 1;
    }
  }, [activeStep, transactionsState, roomConfirmed, maxSteps]);

  return (
    <>
      {deviceFetchState !== "fetched" ? (
        // deviceFetchState === "loading" || deviceFetchState === "default"
        <Skeleton width={400} variant="rectangular" />
      ) : (
        <Box sx={{ maxWidth: 400, width: 400,  height: 500, flexGrow: 1 }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 50,
              pl: 2,
              bgcolor: "background.default",
            }}
          >
            <Typography>
              <b>{steps[activeStep]}</b>
            </Typography>
          </Paper>
          {device && (
            <Box sx={{ maxWidth: 400, minHeight: 400 }}>
              {currentStepComponent}
            </Box>
          )}
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position={smallScreen ? "bottom" : "static"}
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={isNextDisabled}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
      )}
    </>
  );
};

export default InstallationStepper;
