import store, { AppDispatch } from "..";
import {
  BluetoothEventType,
  DeviceEvent,
  EventType,
  ReceivedEvent,
  SummaryEventType,
  TransactionEventType,
} from "../../models/deviceEvents.model";
import { fetchStateType } from "../../models/types";
import {
  PaginationQuery,
} from "../../utilities/api";
import { getSortedLatestEventsFromApi } from "../../utilities/events/api";
import {
  filterIsOn,
  getFilteredEventTypes,
  isEventUnique,
} from "../../utilities/events/eventUtils";
import { initialFilter } from "../reducers/eventReducer";
import {
  SET_MOST_RECENT_EVENTS,
  RESET_MOST_RECENT_STATE,
  SET_MAX_EVENTS_IN_FEED,
  RESET_EVENTS_REDUCER_STATE,
  SET_FILTER,
  SET_EVENT_FETCHSTATE,
} from "./actionTypes";

export const loadMostRecentEvents = () => async (dispatch: AppDispatch) => {
  let eventFetchstate = store.getState().eventsReducer.eventFetchState;
  let filter = store.getState().eventsReducer.filter;
  let maxEventsInFeed = store.getState().eventsReducer.maxEventsInFeed;

  if (eventFetchstate === "loading") return;

  let filteredAsList = getFilteredEventTypes({
    ...filter,
  }).filter(ev => ev) as EventType[];

  let bluetoothFilter = undefined;
  let transactionFilter = undefined;
  let summaryFilter = undefined;

  if (store.getState().eventsReducer.filterIsOn) {
    bluetoothFilter = filter[BluetoothEventType];
    transactionFilter = filter[TransactionEventType];
    summaryFilter = filter[SummaryEventType]
  }

  dispatch(setEventFetchState("loading"));
  let recentApiData = await getSortedLatestEventsFromApi(
    new PaginationQuery(0, maxEventsInFeed),
    filteredAsList,
    bluetoothFilter,
    transactionFilter,
    summaryFilter
  );

  if (!recentApiData) return dispatch(setEventFetchState("error"));

  let mostRecentEvents = recentApiData
    .concat(store.getState().eventsReducer.mostRecentEvents)
    .sort(function (x:ReceivedEvent, y:ReceivedEvent) {
      let yDate = new Date(y.event.timestamp);
      let xDate = new Date(x.event.timestamp);

      return yDate.valueOf() - xDate.valueOf();
    })
    .filter(isEventUnique);

  

  dispatch(setMostRecentEvents(mostRecentEvents));
  return dispatch(setEventFetchState("fetched"));
};

export const addEvent = (event: ReceivedEvent) => (dispatch: AppDispatch) => {
  let mostRecentEvents = store.getState().eventsReducer.mostRecentEvents;
  mostRecentEvents.unshift(event);
  return dispatch(setMostRecentEvents(mostRecentEvents));
};

export const resetEventsFilter = () => (dispatch: AppDispatch) => {
  dispatch(
    setEventTypeFilter( initialFilter)
  );

  return dispatch({
    type: RESET_EVENTS_REDUCER_STATE,
  });
};

const setMostRecentEvents = (mostRecentEvents: ReceivedEvent[]) => {
  // filter if it's on
  if (store.getState().eventsReducer.filterIsOn) {
    let oldfilter = store.getState().eventsReducer.filter;

    let filter = new Set(getFilteredEventTypes({...oldfilter }));

    mostRecentEvents = mostRecentEvents.filter((receivedEvent) => {

      if (receivedEvent.eventType === BluetoothEventType)
        return filter.has(BluetoothEventType);

        if (receivedEvent.eventType === TransactionEventType)
        return filter.has(TransactionEventType)

      return filter.has((receivedEvent.event as DeviceEvent).event);
    });
  }

  //Keep the array small enough
  let eventMaxLength = store.getState().eventsReducer.maxEventsInFeed;
  if (mostRecentEvents.length > eventMaxLength) {
    mostRecentEvents = mostRecentEvents.slice(0, eventMaxLength);
  }

  return {
    type: SET_MOST_RECENT_EVENTS,
    payload: mostRecentEvents,
  };
};

export const resetMostRecentEvents = () => {
  return {
    type: RESET_MOST_RECENT_STATE,
  };
};

export type filterDict = { [key: string]: boolean };
;

export const setEventTypeFilter = (
  eventTypeFilter: filterDict
) => {
  return {
    type: SET_FILTER,
    payload: {
      filter: eventTypeFilter,
      filterIsOn:
        filterIsOn(eventTypeFilter),
    },
  };
};

export const setMaxEventsInFeed = (maxEventsInFeed: number) => {
  if (maxEventsInFeed < 0)
    throw new Error("maxEventsInFeed cannot be negative");

  return {
    type: SET_MAX_EVENTS_IN_FEED,
    payload: maxEventsInFeed,
  };
};

export const setEventFetchState = (fetchstate: fetchStateType) => {
  return { type: SET_EVENT_FETCHSTATE, payload: fetchstate };
};
