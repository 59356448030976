import { FC, ReactElement } from "react";
import { useIsAuthenticated } from "../hooks/authentication-hooks/authenticationHooks";
import { useKeycloakUserToRedux } from "../hooks/user-hooks/userHooks";
import SignalRToReduxProvider from "../provider/SignalRToReduxProvider";

type props = { children: ReactElement }
export const PrivateRoute: FC<props> = ({ children }:props) => {
  const { authDone, isAuthenticated } = useIsAuthenticated();
  useKeycloakUserToRedux()
  return (
    <>
      {isAuthenticated && authDone && (
        <SignalRToReduxProvider>
          <>{children}</>
        </SignalRToReduxProvider>
      )}
    </>
  );
};


