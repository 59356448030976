import {
  Alert,
  Button,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdminDevice } from "../../hooks/admin-device/useAdminDeviceHooks";
import { FirmwareVersion } from "../../models";
import { fetchStateType } from "../../models/types";
import {
  getM0FirmwareVersions,
  updateM0FirmwareForDevice,
} from "../../utilities/axios/admin-api-calls/adminCalls";
import { dateTimeToString } from "../../utilities/helpers";

const UpdateFirmwareView = () => {
  const { serialNumber, firmwareType } = useParams();
  const [firmwareVersions, setFirmwareVersions] = useState<FirmwareVersion[]>(
    []
  );
  const [fetchState, setFetchState] = useState<fetchStateType>("default");

  const {
    adminDevice,
    fetchAdminDevice,
    fetchState: adminDeviceFetchState,
  } = useAdminDevice(serialNumber);

  const [fotaUpdateFetchState, setFotaUpdateFetchState] =
    useState<fetchStateType>("default");

  useEffect(() => {
    if (adminDeviceFetchState === "default") {
      fetchAdminDevice();
    }
  }, [adminDeviceFetchState, fetchAdminDevice]);


  const currentVersion = useMemo(() => {
    return adminDevice?.firmware?.version;
  }, [adminDevice]);

  const fetchFirmwareVersions = useCallback(async () => {
    setFetchState("loading");
    var res = await getM0FirmwareVersions();

    if (res) setFetchState("fetched");
    else setFetchState("error");
    setFirmwareVersions(res ?? []);
  }, []);

  const callFotaEndpoint = useCallback(
    async (newVersion: string) => {
      if (!serialNumber) return;
      setFotaUpdateFetchState("loading");
      var res = await updateM0FirmwareForDevice(
        serialNumber,
        newVersion
      );

      if (200 <= res && res <= 299) {
        setFotaUpdateFetchState("fetched");
      } else {
        setFotaUpdateFetchState("error");
      }
      fetchAdminDevice();

    },
    [serialNumber, fetchAdminDevice]
  );

  useEffect(() => {
    if (fetchState === "default") fetchFirmwareVersions();
  }, [fetchState, firmwareVersions, fetchFirmwareVersions]);


  return (
    <Grid container margin={2} flexDirection="column">
      <Grid item>
        <h1>{serialNumber}</h1>
      </Grid>

      <Grid container flexDirection={"column"} alignContent="center">
        <Grid item container width={400}>
          <Typography variant="h6">
            Available {firmwareType} versions
          </Typography>

          {fetchState === "loading" && (
            <Skeleton variant="rectangular" width={400} height={400} />
          )}
          {fetchState === "fetched" && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Version</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firmwareVersions.map((version) => {
                  return (
                    <TableRow key={version.gitTag}>
                      <TableCell>
                        {version.gitTag === currentVersion ? (
                          <b>{version.gitTag}</b>
                        ) : (
                          <>{version.gitTag}</>
                        )}{" "}
                      </TableCell>
                      <TableCell>
                        {dateTimeToString(new Date(version.uploaded))}
                      </TableCell>
                      <TableCell>
                        {version.gitTag === currentVersion ? (
                          <>Current</>
                        ) : (
                          <Button
                            onClick={() => callFotaEndpoint(version.gitTag)}
                            disabled={
                              adminDevice?.isUpdatingFirmware ||
                              fotaUpdateFetchState === "loading" ||
                              fotaUpdateFetchState === "fetched"
                            }
                          >
                            Update
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Grid>
        <Grid item container width={400}>
          {fotaUpdateFetchState === "error" && (
            <Alert severity="error">Error when calling update endpoint</Alert>
          )}
          {fotaUpdateFetchState === "fetched" && (
            <Alert severity="success">
              A job has been started to upgrade <b>{firmwareType}</b> for device
              <b>{serialNumber}</b>
            </Alert>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateFirmwareView;
