import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTenant } from "../../hooks/tenant";
import { Button } from "../buttons/Button";
import { saveStateType } from "../../models/types";
import { CreateRoom } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import {  TypeModel } from "../../models/locations.model";
import { useIdsFromPath } from "../../hooks/location-hooks/locationHelperHooks";
import RoomTypeSelect from "./RoomTypeSelect";

type props = { floorId: string; onAdd: () => void };
const AddRoomButton = ({ floorId, onAdd }: props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [saveState, setSaveState] = useState<saveStateType>("default");
  const [roomType, setRoomType] = useState<TypeModel | null>(null);
  const tenant = useTenant();

  const buttonDisabled = useMemo(
    () =>
      roomType === null ||
      roomType?.name === defaultRoomType.name ||
      roomName.length === 0 ||
      !floorId,
    [roomType, roomName, floorId]
  );

  const { sectionId } = useIdsFromPath();

  const addRoom = useCallback(async () => {
    if (saveState === "loading" || sectionId === null || roomType === null)
      return;

    setSaveState("loading");
    var success = await CreateRoom({
      name: roomName,
      sectionId: sectionId,
      roomTypeId: roomType.id,
      floorId: floorId,
    });

    if (success) {
      setSaveState("success");
      onAdd();
    } else setSaveState("error");
  }, [saveState, sectionId, roomName, roomType, floorId, onAdd]);

  const handleAddRoomClicked = () => {
    if (saveState !== "loading") addRoom();
  };

  const resetStates = () => {
    setRoomName("");
    setRoomType(defaultRoomType);
    setSaveState("default");
  };

  const toggleDialogState = () => {
    setDialogOpen(!dialogOpen);
    if (saveState === "success") onAdd();
    resetStates();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleTypeChange = (newType: TypeModel | null) => {
    setRoomType(newType);
  };

  return (
    <>
      <Button onClick={toggleDialogState}>Add Room</Button>

      <Dialog open={dialogOpen} onClose={toggleDialogState}>
        <DialogContent>
          <Grid
            container
            flexDirection={"column"}
            minWidth={400}
            minHeight={400}
            spacing={2}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Grid item>
              <h3>Add a Room to {tenant?.name}</h3>
            </Grid>
            <Grid item>
              <TextField
                disabled={saveState === "success"}
                id="outlined-name"
                label="Room name"
                fullWidth
                variant="outlined"
                value={roomName}
                onChange={handleNameChange}
              />
            </Grid>

            <Grid item>
              <RoomTypeSelect
                onChange={handleTypeChange}
                disabled={saveState === "success"}
              />
            </Grid>


            <Grid item height={"50px"}>
              {saveState === "error" && (
                <Alert severity="error">Unable to create Room</Alert>
              )}

              {saveState === "success" && (
                <Alert severity="success">
                  Room <b>{roomName}</b> created
                </Alert>
              )}
            </Grid>

            <Grid item>
              {saveState === "loading" && <CircularProgress />}

              {(saveState === "default" || saveState === "error") && (
                <Button
                  onClick={handleAddRoomClicked}
                  disabled={buttonDisabled}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddRoomButton;

const defaultRoomType: TypeModel = {
  id: "",
  name: "",
  useForCompliance: false,
};
