import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../utilities/axios/admin-api-calls/users-calls/usersCalls";

import { PaginationQuery } from "../../utilities/api";
import { BaseModel } from "../../models";
import { User } from "../../models/users.model";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import ItemDisplay from "../display/ItemDisplay";
import { Filter } from "../device-filter/DeviceFilter";

const UserDisplay = () => {
  const navigate = useNavigate();

  const getUsersPaginationQuery = useCallback(
    (pagination: PaginationQuery, filter: Filter | undefined) =>
      getUsers(pagination, filter?.searchTerm),
    []
  );

  const navigateToUser = (user: BaseModel) => {
    navigate(`${user.id}`);
  };

  const buildFullName = (item: Item) => {
    let user = item as User;

    return user.firstName + " " + user.lastName;
  };

  return (
    <ItemDisplay
      itemName="users"
      getItems={getUsersPaginationQuery}
      labelProperty={"name"}
      handleItemChosen={navigateToUser}
      getLabel={buildFullName}
    />
  );
};

export default UserDisplay;
