import store, { AppDispatch } from "..";
import { DetailedDevice, RemainderModel } from "../../models/detailedDevice.model";
import {
  RESET_DETAILED_DEVICE_STATE,
  SET_DETAILED_DEVICE,
  SET_FETCH_STATE_DETAILED_DEVICE,
  SET_FLUID,
} from "./actionTypes";
import { getDetailedDeviceById as getDetailedDeviceApiCall } from "../../utilities/axios/admin-api-calls/tenant-calls";
import { fetchStateType } from "../../models/types";

export const fetchDetailedDevice =
  (deviceId: string, tenantIdentifier: string) =>
  async (dispatch: AppDispatch) => {
    if (store.getState().detailedDeviceReducer.fetchState === "loading") return;

    dispatch(setFetchStateDetailedDevice("loading"));
    let response = await getDetailedDeviceApiCall(tenantIdentifier, deviceId);

    if (response?.status === 200) {
      dispatch(setDetailedDevice(response.data));
      return dispatch(setFetchStateDetailedDevice("fetched"));
    }

    if (response?.status === 404)
      return dispatch(setFetchStateDetailedDevice("notFound"));

    return dispatch(setFetchStateDetailedDevice("error"));
  };

export const resetDetailedDeviceState = () => {
  return {
    type: RESET_DETAILED_DEVICE_STATE,
  };
};
export const setDetailedDevice = (device: DetailedDevice) => {
  return {
    type: SET_DETAILED_DEVICE,
    payload: device,
  };
};


export const setFluid = (fluid: RemainderModel) => {
  return {
    type: SET_FLUID,
    payload: fluid,
  };
};

export const setFetchStateDetailedDevice = (fetchState: fetchStateType) => {
  return {
    type: SET_FETCH_STATE_DETAILED_DEVICE,
    payload: fetchState,
  };
};
