import axios, { AxiosError, AxiosResponse } from "axios";
import { FloorPlanDto, FloorPlan } from "../../../../models";
import {
  PremiseModel,
  CreatePremiseDto,
  SectionModel,
  RoomModel,
  CreateSectionDto,
  TypeModel,
  CreateRoomDto,
  CreateFloorDto,
  FloorModel,
  CoordinateModel,
} from "../../../../models/locations.model";
import {
  PaginationQuery,
  PagedResponse,
  API_URL,
  LocationQuery,
} from "../../../api";

export const getSections = async (
  paginationQuery: PaginationQuery,
  searchTerm?: string
): Promise<PagedResponse<SectionModel> | null> => {
  try {
    let params = { term: searchTerm };

    let response = await axios.get(paginationQuery.toUrl("/sections"), {
      params,
    });
    return response.data as PagedResponse<SectionModel>;
  } catch (error) {
    return null;
  }
};

export const getPremises = async (
  paginationQuery: PaginationQuery,
  searchTerm?: string,
  locationQuery?: LocationQuery
): Promise<PagedResponse<PremiseModel> | null> => {
  try {
    let params: { [index: string]: any } = {};

    if (!!searchTerm) {
      params["term"] = searchTerm;
    }

    if (!!locationQuery) {
      params["longitude"] = locationQuery.longitude;
      params["latitude"] = locationQuery.latitude;
      params["orderByProximity"] = locationQuery.orderByProximity;
    }

    let response = await axios.get(paginationQuery.toUrl("/premises"), {
      params,
    });
    return response.data as PagedResponse<PremiseModel>;
  } catch (error) {
    return null;
  }
};

export const CreatePremise = async (
  premise: CreatePremiseDto
): Promise<boolean> => {
  try {
    await axios.post(`${API_URL}/premises`, premise);
    return true;
  } catch (error) {
    return false;
  }
};

export const CreateSection = async (
  section: CreateSectionDto
): Promise<boolean> => {
  try {
    await axios.post(`${API_URL}/sections`, section);
    return true;
  } catch (error) {
    return false;
  }
};

export const CreateFloor = async (floor: CreateFloorDto): Promise<boolean> => {
  try {
    await axios.post(`${API_URL}/sections/${floor.sectionId}/floors`, floor);
    return true;
  } catch (error) {
    return false;
  }
};

export const CreateRoom = async (room: CreateRoomDto): Promise<boolean> => {
  try {
    await axios.post(`${API_URL}/floors/${room.floorId}/rooms`, room);
    return true;
  } catch (error) {
    return false;
  }
};

export const getPremiseById = async (
  premiseId: string
): Promise<PremiseModel | null> => {
  try {
    let response = await axios.get(`${API_URL}/premises/${premiseId}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getSectionsForPremise = async (
  paginationQuery: PaginationQuery,
  premiseId: string,
  searchTerm?: string
): Promise<PagedResponse<SectionModel> | null> => {
  try {
    let params = { term: searchTerm };

    let response = await axios.get(
      paginationQuery.toUrl(`/premises/${premiseId}/sections`),
      {
        params,
      }
    );
    return response.data as PagedResponse<SectionModel>;
  } catch (error) {
    return null;
  }
};

export const getRooms = async (
  paginationQuery: PaginationQuery,
  searchTerm?: string
): Promise<PagedResponse<RoomModel> | null> => {
  try {
    let params = { term: searchTerm };

    let response = await axios.get(paginationQuery.toUrl(`/rooms`), {
      params,
    });
    return response.data as PagedResponse<RoomModel>;
  } catch (error) {
    return null;
  }
};

export const getPremisesForSection = async (
  paginationQuery: PaginationQuery,
  sectionId: string,
  searchTerm?: string
): Promise<PagedResponse<PremiseModel> | null> => {
  try {
    let params = { term: searchTerm };

    let response = await axios.get(
      paginationQuery.toUrl(`/sections/${sectionId}/premises`),
      {
        params,
      }
    );
    return response.data as PagedResponse<PremiseModel>;
  } catch (error) {
    return null;
  }
};

export const getFloorsForSection = async (
  paginationQuery: PaginationQuery,
  sectionId: string,
  searchTerm?: string
): Promise<PagedResponse<FloorModel> | null> => {
  try {
    let params = { term: searchTerm };

    let response = await axios.get(
      paginationQuery.toUrl(`/sections/${sectionId}/floors`),
      {
        params,
      }
    );
    return response.data as PagedResponse<FloorModel>;
  } catch (error) {
    return null;
  }
};

export const getFloorsForPremise = async (
  paginationQuery: PaginationQuery,
  premiseId: string,
  searchTerm?: string
): Promise<PagedResponse<FloorModel> | null> => {
  try {
    let params = { term: searchTerm };

    let response = await axios.get(
      paginationQuery.toUrl(`/premises/${premiseId}/floors`),
      {
        params,
      }
    );
    return response.data as PagedResponse<FloorModel>;
  } catch (error) {
    return null;
  }
};

export const getRoomsForFloor = async (
  paginationQuery: PaginationQuery,
  floorId: string,
  searchTerm?: string
): Promise<PagedResponse<FloorModel> | null> => {
  try {
    let params = { term: searchTerm };

    let response = await axios.get(
      paginationQuery.toUrl(`/floors/${floorId}/rooms`),
      {
        params,
      }
    );
    return response.data as PagedResponse<FloorModel>;
  } catch (error) {
    return null;
  }
};

export const getSectionById = async (
  sectionId: string
): Promise<SectionModel | null> => {
  try {
    let response = await axios.get(`${API_URL}/sections/${sectionId}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getFloorById = async (
  floorId: string
): Promise<FloorModel | null> => {
  try {
    let response = await axios.get(`${API_URL}/floors/${floorId}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getRoomById = async (
  roomId: string
): Promise<RoomModel | null> => {
  try {
    let response = await axios.get(`${API_URL}/rooms/${roomId}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const postFloorPlanToFloor = async (
  floorId: string,
  floorPlan: FloorPlanDto
): Promise<number> => {
  try {
    var response = await axios.post(
      `${API_URL}/floors/${floorId}/floor-plans`,
      floorPlan
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const updateFloorPlanToFloor = async (
  floorId: string,
  floorPlan: FloorPlanDto
): Promise<number> => {
  try {
    var response = await axios.put(
      `${API_URL}/floors/${floorId}/floor-plans`,
      floorPlan
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const postFloorPlanToRoom = async (
  roomId: string,
  floorPlan: FloorPlanDto
): Promise<number> => {
  try {
    var response = await axios.post(
      `${API_URL}/rooms/${roomId}/floor-plans`,
      floorPlan
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const updateFloorPlanToRoom = async (
  roomId: string,
  floorPlan: FloorPlanDto
): Promise<number> => {
  try {
    var response = await axios.put(
      `${API_URL}/rooms/${roomId}/floor-plans`,
      floorPlan
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};
export const getFloorPlanForPremise = async (
  premiseId: string
): Promise<FloorPlan | null> => {
  try {
    var response = await axios.get(
      `${API_URL}/premises/${premiseId}/floor-plans`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getFloorPlanForFloor = async (
  floorId: string
): Promise<FloorPlan | null> => {
  try {
    var response = await axios.get(`${API_URL}/floors/${floorId}/floor-plans`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getFloorPlanForRoom = async (
  roomId: string
): Promise<FloorPlan | null> => {
  try {
    var response = await axios.get(`${API_URL}/rooms/${roomId}/floor-plans`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRoomTypes = async (
  paginationQuery: PaginationQuery
): Promise<PagedResponse<TypeModel> | null> => {
  try {
    let response = await axios.get(paginationQuery.toUrl(`/room-types`));
    return response.data as PagedResponse<TypeModel>;
  } catch (error) {
    return null;
  }
};

export const postRoomType = async (
  roomType: TypeModel
): Promise<AxiosResponse<TypeModel, number> | null> => {
  try {
    var response = await axios.post(`${API_URL}/room-types`, {
      name: roomType.name,
      useForCompliance: roomType.useForCompliance,
    });
    return response;
  } catch (error) {
    let resError = error as AxiosError;

    return resError.response ?? null;
  }
};

export const updatePremiseLocation = async (
  premiseId: string,
  location: CoordinateModel
): Promise<PremiseModel | null> => {
  try {
    var response = await axios.put<PremiseModel>(
      `${API_URL}/premises/${premiseId}/location`,
      location
    );
    return response.data as PremiseModel;
  } catch (error) {
    return null;
  }
};
