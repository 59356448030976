import { useCallback, useEffect, useState } from "react";
import { SectionModel } from "../../models/locations.model";
import { fetchStateType } from "../../models/types";
import { getSections } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";
import { PaginationQuery } from "../../utilities/api";

const DEFAULT_PAGE_SIZE = 9;

export const useSections = () => {
  const [sections, setSections] = useState<SectionModel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchstate, setFetchState] = useState<fetchStateType>("default");
  const [searchString, setSearchString] = useState<string | undefined>(
    undefined
  );

  const fetchSections = useCallback(async () => {
    if (fetchstate !== "default") return;

    const sections = await getSections(
      new PaginationQuery(currentPage, DEFAULT_PAGE_SIZE),
      searchString
    );

    if (!sections) {
      setFetchState("error");
      return;
    }

    setFetchState("fetched");
    setSections(sections.items);
  }, [fetchstate, currentPage, searchString]);

  useEffect(() => {
    if (fetchstate === "default") fetchSections();
  }, [fetchstate, fetchSections]);

  useEffect(() => {
    setFetchState("default");
  }, [searchString]);

  useEffect(() => {
    setFetchState("default");
  }, [currentPage]);

  return {
    sections,
    fetchstate,
    searchString,
    setSearchString,
    currentPage,
    setCurrentPage,
  };
};
