import AddScreenUserForm from "../../components/add-user/AddScreenUserForm";
import Title from "../../components/title/Title";

const ScreenUserView = () => {
  return (
    <>
      <Title>Add a screen user</Title>


      <AddScreenUserForm/>
    </>
  );
};

export default ScreenUserView;
