import {
  Alert,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { User } from "../../models/users.model";
import RolesComboBox from "../roles/RolesComboBox";
import { Button } from "../buttons/Button";
import { deleteUser as deleteUserApiCall } from "../../utilities/axios/admin-api-calls/users-calls/usersCalls";
import DeleteUserButton from "./DeleteUserButton";
import { useNavigate } from "react-router-dom";
import { useTenant } from "../../hooks/tenant";
// import { Tenant } from "../../models";
import { useUpdateUserManager } from "../../hooks/user-management-hooks/UpdateUserHook";
import UserSectionComboBox from "../user-section-combobox/UserSectionComboBox";
// import ChooseUserTenantButton from "./ChooseUserTenantButton";

type props = {
  user: User;
  onUserUpdated: (user: User) => void;
};

const UserForm = ({ user, onUserUpdated }: props) => {
  const navigate = useNavigate();
  const tenant = useTenant();
  const updateUserManager = useUpdateUserManager({ user, onUserUpdated });

  const [deleteLoading, setDeleteLoading] = useState(false);

  const success = useMemo(
    () =>
      updateUserManager.saveState === "success",
    [updateUserManager.saveState]
  );
  const showAlert = useMemo(
    () => updateUserManager.notifyUserTimeOut,
    [updateUserManager.notifyUserTimeOut]
  );
  const loading = useMemo(
    () => deleteLoading || updateUserManager.saveState === "loading",
    [deleteLoading, updateUserManager.saveState]
  );
  const disabled = useMemo(
    () => loading || updateUserManager.notifyUserTimeOut,
    [loading, updateUserManager.notifyUserTimeOut]
  );
  const deleteUser = useCallback(
    async (userId: string) => {
      if (loading) return;
      setDeleteLoading(true);
      let userDeleted = await deleteUserApiCall(userId);
      if (userDeleted >= 200) {
        navigate(`/${tenant?.identifier}/users`);
      }
      setDeleteLoading(false);
    },
    [loading, tenant?.identifier, navigate]
  );

  const handleDeleteClicked = () => {
    deleteUser(user.id);
  };

  const handleUpdateClicked = () => {
    updateUserManager.updateUser();
  };



  return (
    <Grid
      item
      container
      flexDirection="column"
      alignItems="center"
      rowSpacing={2}
      width={400}
    >
      <Table aria-label="simple table">
        <TableBody>
          <UserAttribute name={"Email"} value={user.email} />

          <UserAttribute name={"First name"} value={user.firstName ?? ""} />

          <UserAttribute name={"Last name"} value={user.lastName ?? ""} />

          <UserAttribute
            name={"Tenant"}
            value={user.tenant.name}
          />
        </TableBody>
      </Table>
      <Grid item height={200} marginTop={1}>
        <RolesComboBox
          onGroupsChosen={updateUserManager.setGroups}
          groups={updateUserManager.groups}
          width={400}
        />
      </Grid>
      <Grid item height={200} width={400} marginTop={1}>
        <UserSectionComboBox sections={updateUserManager.sections} onSectionsChosen={updateUserManager.setSections}   disabled={loading}     />
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container justifyContent={"space-evenly"} width={400}>
            <Button
              onClick={handleUpdateClicked}
              disabled={updateUserManager.updateDisabled || disabled}
            >
              update user
            </Button>
            <DeleteUserButton
              onDelete={handleDeleteClicked}
              disabled={disabled}
              user={user}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        {showAlert && (
          <>
            {success ? (
              <Alert severity="success">
                User successfully updated.{" "}
                {updateUserManager.tenantChanged && (
                  <>
                    Changing to tenant <b>{updateUserManager.tenantName}</b> ...
                  </>
                )}
              </Alert>
            ) : (
              <Alert severity="error">
                An error occured while trying to update user.
              </Alert>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

type userAttributeProps = {
  name: string;
  value: string;
  component?: React.ReactNode;
};
const UserAttribute = ({ name, value, component }: userAttributeProps) => {
  const showComponent = useMemo(() => component !== undefined, [component]);
  return (
    <TableRow>
      <TableCell>
        <Typography>{name}</Typography>
      </TableCell>
      <TableCell>
        {showComponent ? (
          component
        ) : (
          <Typography textAlign={"right"}>{value}</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default UserForm;
