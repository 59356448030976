import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FloorPlanMap } from "../../models";
import {
  PremiseModel,
  SectionModel,
  RoomModel,
  FloorModel,
} from "../../models/locations.model";
import { saveStateType } from "../../models/types";
import { RootState } from "../../store/reducers";
import { useFloorPlanCallBacks } from "./floorPlanCallBacksHook";
import { useFloorPlan } from "./floorPlanHook";
import { entityEnum, useFloorPlanManagerType } from "./floorPlanHookTypes";

export const useFloorPlanManager: useFloorPlanManagerType = () => {
  const params = useParams();
  const [loadingEntity, setLoadingEntity] = useState(false);

  const [entity, setEntity] = useState<
    PremiseModel | SectionModel | FloorModel | RoomModel | null
  >(null);
  const [entityType, setEntityType] = useState<entityEnum>(
    entityEnum.nullEntity
  );
  const [saveStatus, setSaveStatus] = useState<saveStateType>("default");

  const floorplanMap = useSelector(
    (state: RootState) => state.floorPlanReducer.floorPlan?.map
  );

  const [map, setMap] = useState<FloorPlanMap | null>(floorplanMap ?? null);

  const {
    premise,
    section,
    floor,
    room,
    outerMostLocationObjectType: outerMostEntity,
  } = useSelector((state: RootState) => state.locationObjectsReducer);

  const createNew = useMemo(
    () =>
      (floorplanMap === null || floorplanMap === undefined) &&
      saveStatus !== "success",
    [floorplanMap, saveStatus]
  );

  const { saveFloorPlanForFloor, saveFloorPlanForRoom } = useFloorPlanCallBacks(
    { setSaveStatus, createNew }
  );

  const { floorPlan, fetchState } = useFloorPlan();

  var friendlyEntityType = useMemo(() => {
    switch (entityType) {
      case entityEnum.SectionModel:
        return "Section";
      case entityEnum.PremiseModel:
        return "Premise";
      case entityEnum.FloorModel:
        return "Floor";
      case entityEnum.RoomModel:
        return "Room";

      default:
        return "Unknown";
    }
  }, [entityType]);

  const saveFloorPlan = useCallback(() => {
    if (!entity || !map) return;

    setSaveStatus("default");
    switch (entityType) {
      case entityEnum.FloorModel:
        return saveFloorPlanForFloor({ map }, entity);
      case entityEnum.RoomModel:
        return saveFloorPlanForRoom({ map }, entity);
      default:
        throw new Error("floorPlanEntity is null");
    }
  }, [entity, map, entityType, saveFloorPlanForFloor, saveFloorPlanForRoom]);

  useEffect(() => {
    setLoadingEntity(true);
    if (params.roomId) {
      setEntityType(entityEnum.RoomModel);
      setEntity(room);
    } else if (params.floorId) {
      setEntityType(entityEnum.FloorModel);
      setEntity(floor);
    } else if (params.premiseId) {
      setEntityType(entityEnum.PremiseModel);
      setEntity(premise);
    } else if (params.sectionId) {
      setEntityType(entityEnum.SectionModel);
      setEntity(section);
    }
  }, [params, premise, section, room, floor]);

  useEffect(() => {
    if (floorplanMap) setMap(floorplanMap);
  }, [floorplanMap]);

  return {
    loadingEntity,
    entity,
    entityType,
    floorPlan,
    loadingFloorPlan:
      fetchState === "loading" ||
      fetchState === "default" ||
      outerMostEntity === entityEnum.nullEntity,
    friendlyEntityType,
    saveStatus,
    saveFloorPlan,
    map,
    setMap,
  };
};
